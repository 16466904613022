import { useForm } from "react-hook-form";
import { User } from "../models/user";
import { getQuestionReports, SignUpCredentials } from "../network/user_api";
import * as UserApi from "../network/user_api";
import {Modal, ModalContent, ModalFooter, ModalHeader, Button, ModalBody, Input, useDisclosure, Listbox, ListboxItem, CardBody, CardHeader, Card, Divider, Textarea, Checkbox, CardFooter, select} from "@nextui-org/react"
import styles from "../styles/utils.module.css";
import { useEffect, useState } from "react";
import MailIcon from "./Icons/MailIcon";
import UsernameIcon from "./Icons/UsernameIcon";
import PasswordIcon from "./Icons/PasswordIcon";
import OrganizationIcon from "./Icons/OrganizationIcon";
import { grabCompetitiveIdentifier } from "../util/categoryIconHelper";

interface ReportsLoggedInViewProps {
    loggedInUser: User
}


const ReportsLoggedInView = (props : ReportsLoggedInViewProps) => {
    const [reports, setReports] = useState<any[]>([]);
    const [questions, setQuestions] = useState<any[]>([]);
    const [page, setPage] = useState(0);

    const [selectedReport, setSelectedReport] = useState(-1);

    const [questionText, setQuestionText] = useState("");
    const [answerChoiceA, setAnswerChoiceA] = useState("");
    const [answerChoiceB, setAnswerChoiceB] = useState("");
    const [answerChoiceC, setAnswerChoiceC] = useState("");
    const [answerChoiceD, setAnswerChoiceD] = useState("");
    const [answerChoiceE, setAnswerChoiceE] = useState("");

    const [justification, setJustification] = useState("");
    const [section, setSection] = useState(0);
    const [pageNumberStart, setPageNumberStart] = useState(0);
    const [pageNumberEnd, setPageNumberEnd] = useState(0);

    const [correctAnswer, setCorrectAnswer] = useState(0);

    const [editUiLoading, setEditUiLoading] = useState(false);

    async function throwOut(reportId: string) {
        setEditUiLoading(true);

        try {
            UserApi.throwOutReport({
                reportId: reportId
            })
        } catch (error) {
            
        } finally {
            setEditUiLoading(false);
        }
    }

    async function getReports() {
        const r = await getQuestionReports({
            page: 0
        });

        setReports(r.reports);
        setQuestions(r.questions)
    }

    useEffect(() => {
        getReports();
    }, [])

    return (
        <>
            <div style={{display:'flex', justifyContent:'center'}}>
                <Listbox emptyContent={<p style={{textAlign:'center'}}>No question reports, chill out for a little</p>} style={{width:'15%', justifyContent:'center', margin:'auto'}}>
                    {reports.map((v:any, i:number) => {
                        return (
                            <ListboxItem key={i} onClick={() => {
                                setQuestionText(questions[i].question)
                                setCorrectAnswer(questions[i].correctAnswer)

                                setAnswerChoiceA(questions[i].answerChoiceA)
                                setAnswerChoiceB(questions[i].answerChoiceB)
                                setAnswerChoiceC(questions[i].answerChoiceC)
                                setAnswerChoiceD(questions[i].answerChoiceD)
                                setAnswerChoiceE(questions[i].answerChoiceE)
                                setJustification(questions[i].justification)
                                setSection(questions[i].section)
                                setPageNumberStart(questions[i].pageNumberStart)
                                setPageNumberEnd(questions[i].pageNumberEnd)

                                setSelectedReport(i)
                            }}>
                                <Card className="max-w-[200px]" style={{marginBottom:'0.3rem'}}>
                                        <CardHeader className="flex gap-3">
                                        
                                            <div className="flex flex-col">
                                                <p className="text-md">{grabCompetitiveIdentifier(questions[i].category)} Section {questions[i].section}. Page {questions[i].pageNumberStart}</p>
                                                <p className="text-small text-default-500">User ID: {v.user}</p>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <p style={{textOverflow:'ellipsis', overflow:'hidden'}}> Reported For: {v.reason === 0 ? "Incorrect Answer" : v.reason === 1 ? "Incorrect Page Number" : v.reason === 2 ? "Innacurate Justification" : "Bad Wording"}<br/>{v.explanation || ""}</p>
                                        </CardBody>
                                    </Card>
                            </ListboxItem>
                        )
                    })}
                    
                </Listbox>

                {selectedReport !== -1 && <Card style={{justifyContent:'center', margin:'auto', width:'25%'}}>
                    <CardHeader>
                            <Textarea onValueChange={setQuestionText} value={questionText} label="Question Text"/>
                        </CardHeader>
                    <CardBody>
                        <div style={{margin:'5px', display:'inline-flex'}}>
                            <Textarea style={{height:'50%'}} onValueChange={setAnswerChoiceA} value={answerChoiceA} label="Answer Choice A"/>
                            <Checkbox isSelected={correctAnswer === 0} onValueChange={() => {
                                setCorrectAnswer(0)
                            }} color="success" style={{margin:'2px'}}/>
                        </div>
                        <div style={{margin:'5px', display:'inline-flex'}}>
                            <Textarea style={{height:'50%'}} onValueChange={setAnswerChoiceB} value={answerChoiceB} label="Answer Choice B"/>
                            <Checkbox onValueChange={() => {
                                setCorrectAnswer(1)
                            }} isSelected={correctAnswer === 1} color="success" style={{margin:'2px'}}/>
                        </div>
                        <div style={{margin:'5px', display:'inline-flex'}}>
                            <Textarea style={{height:'50%'}} onValueChange={setAnswerChoiceC} value={answerChoiceC} label="Answer Choice C"/>
                            <Checkbox onValueChange={() => {
                                setCorrectAnswer(2)
                            }} isSelected={correctAnswer === 2} color="success" style={{margin:'2px'}}/>
                        </div>
                        <div style={{margin:'5px', display:'inline-flex'}}>
                            <Textarea style={{height:'50%'}} onValueChange={setAnswerChoiceD} value={answerChoiceD} label="Answer Choice D"/>
                            <Checkbox onValueChange={() => {
                                setCorrectAnswer(3)
                            }} isSelected={correctAnswer === 3} color="success" style={{margin:'2px'}}/>
                        </div>
                        <div style={{margin:'5px', display:'inline-flex'}}>
                            <Textarea style={{height:'50%'}} onValueChange={setAnswerChoiceE} value={answerChoiceE} label="Answer Choice E"/>
                            <Checkbox onValueChange={() => {
                                setCorrectAnswer(4)
                            }} isSelected={correctAnswer === 4} color="success" style={{margin:'2px'}}/>
                        </div>
                    </CardBody>
                    <CardFooter style={{display:'flex'}}>
                    <Textarea onValueChange={setJustification} value={justification} label="Justification"/>
                        

                    </CardFooter>
                    <CardFooter>
                    <div style={{margin:'2px'}}>
                                <Input type="number" value={pageNumberStart.toString()} onValueChange={(v:any) => {
                                    setPageNumberStart(v as number)
                                }} label="Pg. # Start"/>
                            </div>
                            <div style={{margin:'2px'}}>
                            <Input type="number" value={pageNumberEnd.toString()} onValueChange={(v:any) => {
                                    setPageNumberEnd(v as number)
                                }} label="Pg. # End"/>
                            </div>
                            <Input type="number" value={section.toString()} onValueChange={(v:any) => {
                                    setSection(v as number)
                                }} label="Section"/>
                    </CardFooter>
                    <CardFooter style={{display:'flex', justifyContent:'center'}}>
                        <Button onClick={async () => {
                            setEditUiLoading(true);

                            try {
                                await UserApi.updateQuestionAndReport({
                                    question: questionText,
                                    answerChoiceA: answerChoiceA,
                                    answerChoiceB: answerChoiceB,
                                    answerChoiceC: answerChoiceC,
                                    answerChoiceD: answerChoiceD,
                                    answerChoiceE: answerChoiceE,
                                    section: section,
                                    justification: justification,
                                    pageNumberEnd: pageNumberEnd,
                                    pageNumberStart: pageNumberStart,
                                    reportId: reports[selectedReport]._id,
                                    questionId: questions[selectedReport]._id
                                })

                                setReports(reports.filter((v) => {
                                    return v !== reports[selectedReport]
                                }))
                            } catch (error) {
                                
                            } finally {
                                setEditUiLoading(false);
                            }
                        }} isLoading={editUiLoading} color="success" style={{color:'white'}}>Save</Button>
                        <Button isLoading={editUiLoading} onClick={() => {
                            throwOut(reports[selectedReport]._id)
                            setReports(reports.filter((v) => {
                                return v !== reports[selectedReport]
                            }))
                            setSelectedReport(-1);
                        }} color="danger" style={{color:'white', marginLeft:'90px'}}>Throw Out Report</Button>
                    </CardFooter>
                </Card>}
            </div>
        </>

        

    );
} 

export default ReportsLoggedInView;