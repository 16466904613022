import { Divider, Modal, ModalBody, ModalContent, ModalHeader, Button, ModalFooter, Chip } from "@nextui-org/react"
import { useState } from "react";
import MailIcon from "./Icons/MailIcon";
import { User } from "../models/user";

interface UserChipProps {
    user: User
}

const UserChip = ({user} : UserChipProps) => {
    return (
        <Chip style={{color:'white', padding:'10px', marginRight:'10px', marginTop:'15px', marginBottom:'15px'}} startContent={<img src={user.profilePicture} style={{width: '20px', height: '20px', zIndex:1, borderRadius:'50px'}}></img>}>
            {user.username}
        </Chip>
    );
}

export default UserChip;