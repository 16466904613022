import { Card, Skeleton, Spinner } from "@nextui-org/react";

const QuestionPageLoggedOutView = () => {
    return ( 
        <>
            <div style={{display:"flex", justifyContent:"center", alignContent:"center", marginTop:"6rem"}}>
                
                
            </div>

            <Spinner color="secondary" style={{marginLeft:"auto", marginRight:"auto", justifyContent:"center", display:"flex"}}/>
        </>
     );
}
 
export default QuestionPageLoggedOutView;