import { useForm } from "react-hook-form";
import { User } from "../models/user";
import { LogInCredentials, resendVerificationEmail, verifyAccount } from "../network/user_api";
import * as UserApi from "../network/user_api";
import {Modal, ModalContent, ModalFooter, ModalHeader, Button, ModalBody, Input, useDisclosure, Tooltip} from "@nextui-org/react"
import { useState } from "react";
import UsernameIcon from "./Icons/UsernameIcon";
import PasswordIcon from "./Icons/PasswordIcon";
import { useNavigate } from "react-router-dom";

interface LoginModalProps {
    onDismiss: () => void;
    onLoginSuccessful: (user: User) => void;
}

const LoginModal = ({onDismiss, onLoginSuccessful} : LoginModalProps) => {
    const {register, handleSubmit, formState: {errors, isSubmitting}} = useForm<LogInCredentials>();

    async function onSubmit(credentials : LogInCredentials) {
        setSubmitting(true);

        try {
            const user = await UserApi.login(credentials);
            onLoginSuccessful(user);
        } catch (error: any) { 
            if ((error?.message as string).match("Unexpected token")){
                setGeneralError("Server is down, please try again later");
            }else if ((error?.message as string).match("Invalid credentials")){
                setGeneralError("Incorrect Login Details");
            }else if ((error.message as string).match("Please verify account before logging in")){
                setVerificationStep(true);
            }else{
                setGeneralError("An error occurred");
            }
            
            console.log(error?.message || "An error occurred");
        } finally {
            setSubmitting(false);
        }
    }

    const [forgotPassword, setForgotPassword] = useState(false); 

    const [verificationCodeInput, setVerificationCodeInput] = useState("");
    const [verificationErrorMessage, setVerificationErrorMessage] = useState("");

    const [resendEmailLocked, setResendEmailLocked] = useState(false);

    const [verificationStep, setVerificationStep] = useState(false);
    const [verificationSubmitting, setVerificationSubmitting] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    async function emailCooldown() {
        setResendEmailLocked(true);
        await new Promise(f => setTimeout(f, 5000));
        setResendEmailLocked(false);
    }

    const verificationUi = 
    <>
        <ModalHeader className="flex flex-col gap-1" style={{color:"white"}}>Verify Your Email</ModalHeader>
              <ModalBody>
                <p style={{color:'white'}}>We've sent an email with a verification code to the email address associated with this account</p>
                <p style={{color:'white'}}>If you don't see an email, wait a few moments, check your junk mail, or resend it below</p>
              <Input isRequired={true} isInvalid={(verificationErrorMessage !== "")} endContent={<PasswordIcon style={{color:'white'}}/>} type="text" minLength={5} maxLength={5} label="Verification Code" placeholder="Enter the verification code sent to you" required onChange={(e) => {setVerificationCodeInput(e.target.value); setVerificationErrorMessage("")}} />
              <Button onClick={() => {
                resendVerificationEmail({email: "", username:usernameInput.trim()});
                emailCooldown();
              }} isDisabled={resendEmailLocked} style={{maxWidth:'35%'}} size="sm">Resend Email</Button>

              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onDismiss}>
                  Close
                </Button>
                <Button isDisabled={verificationCodeInput.trim().length !== 5} isLoading={verificationSubmitting} style={{color:'white'}} color="success" onPress={async () => {
                    try {
                        setSubmitting(true);
                        const res = await verifyAccount({email: "", username: usernameInput.trim(), verificationCode: verificationCodeInput});

                        if (forgotPassword){
                            setShowForgotPassword(true);
                        } else {
                            onLoginSuccessful(res as unknown as User);
                        }
                        
                    } catch (error:any) {
                        setVerificationErrorMessage(error?.message);
                    } finally {
                        setSubmitting(false);
                    }
                }}>
                  Verify
                </Button>
              </ModalFooter>
    </>

    const [newPasswordInput, setNewPasswordInput] = useState("");

const changePasswordUi = 
<>
    <ModalHeader className="flex flex-col gap-1" style={{color:"white"}}>Verify Your Email</ModalHeader>
          <ModalBody>
            <p style={{color:'white'}}>Input your new password below</p>
          <Input isRequired={true} isInvalid={(verificationErrorMessage !== "")} endContent={<PasswordIcon style={{color:'white'}}/>} type="text" minLength={3} maxLength={256} label="New Password" placeholder="Enter your new password" required onChange={(e) => {setNewPasswordInput(e.target.value); setVerificationErrorMessage("")}} />

          </ModalBody>
          <ModalFooter>
            <Button isDisabled={verificationCodeInput.trim().length !== 5} isLoading={verificationSubmitting} style={{color:'white'}} color="success" onPress={async () => {
                try {
                    setVerificationSubmitting(true);
                    const res = await UserApi.changePassword({newPassword: newPasswordInput});

                    onLoginSuccessful(res as unknown as User);
                } catch (error:any) {
                    setVerificationErrorMessage(error?.message);
                } finally {
                    setVerificationSubmitting(false);
                }
            }}>
              Change Password
            </Button>
          </ModalFooter>
</>

    const [usernameInput, setusernameInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const [usernameError, setusernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [generalError, setGeneralError] = useState("");

    const loginUi = 
    <>
                <ModalHeader className="flex flex-col gap-1" style={{color:"white"}}>Log In</ModalHeader>
                <ModalBody style={{caretColor:'white'}}>
                <Input isInvalid={(usernameError != "")} endContent={<UsernameIcon style={{color:'white'}}/>} errorMessage={usernameError != "" && (usernameError)} type="username" label="Username Or Email" placeholder="Enter your username or email" required onChange={(e) => {setusernameInput(e.target.value); setusernameError("");}} />
                <Input isInvalid={(passwordError != "")} endContent={<PasswordIcon style={{color:'white'}}/>} errorMessage={passwordError != "" && (passwordError)} type="password" label="Password" placeholder="Enter your password" required onChange={(e) => {setPasswordInput(e.target.value); setPasswordError("");}} />
                <Button onClick={async () => {
                    try {
                        setSubmitting(true);

                        await UserApi.forgotPassword({email:"", username:usernameInput.trim()});
                        setVerificationStep(true);
                        setForgotPassword(true);
                    } catch (error:any) {
                        setGeneralError(error.message);
                    } finally {
                        setSubmitting(false);
                    }
                    
                }} style={{maxWidth:'35%'}} size="sm">Forgot My Password</Button>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" variant="light" onPress={onDismiss}>
                    Close
                    </Button>
                    <Button isLoading={submitting} color="secondary" onPress={() => {
                        setGeneralError("");
                        let issue = false;

                        if (usernameInput == ""){
                            setusernameError("Username cannot be empty");
                            issue = true;
                        }

                        if (passwordInput == ""){
                            setPasswordError("Password cannot be empty");
                            issue = true;
                        }

                        if (issue){
                            return;
                        }
                        
                        onSubmit({username: usernameInput, password: passwordInput });

                    }}>
                    Log In
                    </Button>
                    {
                        generalError != "" &&           <Tooltip key="danger" color="danger" className="capitalize" content={<p className="font-bold text-inherit text-balance" style={{textWrap:"pretty", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}>{generalError}</p>}>
                                                            <Button variant="flat" color="danger" className="capitalize">
                                                                Error
                                                            </Button>
                                                        </Tooltip>
                    }
                </ModalFooter>
                
                </>

    return ( 
        <>
        <Modal isOpen={true} onClose={onDismiss}>
            <ModalContent>
            {(onClose) => (
                (showForgotPassword ? changePasswordUi : ( verificationStep ? verificationUi : loginUi))
                
            )}
            </ModalContent>
        </Modal>
        </>

        

     );
}

/*
<Modal show onHide={onDismiss}>
        <Modal.Header closeButton>
            <Modal.Title>
                Log In
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <TextInputField
                    name="username"
                    label="Username"
                    type="text"
                    placeholder="Username"
                    register={register}
                    registerOptions={{ required: "Required" }}
                    error={errors.username}
                />
                <TextInputField
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="Password"
                    register={register}
                    registerOptions={{ required: "Required" }}
                    error={errors.password}
                />
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    style={{width:"100%"}}>
                    Log In
                </Button>
            </Form>
        </Modal.Body>

    </Modal>
    */
 
export default LoginModal;