import { useEffect, useState } from "react";
import {CardBody, CardHeader, Popover, Spinner, Card, Divider, Avatar, Chip, PopoverContent, PopoverTrigger, Button, SelectItem, Select, CheckboxGroup, Input, Checkbox, Tabs, Tab, Skeleton, CardFooter} from "@nextui-org/react";
import { Question as QuestionModel } from '../models/question';
import Question from "./Question";
import * as UserApi from "../network/user_api";
import { User } from "../models/user";
import DownArrowIcon from "./Icons/DownArrowIcon";
import SocialScienceIcon from "./Icons/SocialScienceIcon";
import TopCategoryIcon from "./Icons/TopCategoryIcon";
import UpArrowIcon from "./Icons/UpArrowIcon";
import ScienceIcon from "./Icons/ScienceIcon";
import LiteratureIcon from "./Icons/LiteratureIcon";
import { getCategoryIcon } from "../util/categoryIconHelper";

interface SubjectivesProps {
  user : User,
}

const SubjectiveProps = ({user} : SubjectivesProps) => {
    const [essayPrompts, setEssayPrompts] = useState<any[]>([]);
    const [loadingPrompts, setLoadingPrompts] = useState(true);

    const [interviewQuestion, setInterviewQuestion] = useState<any>();
    const [loadingQuestion, setLoadingQuestion] = useState(true);
    const [interviewHintCount, setInterviewHintCount] = useState(0);

    const [speechPrompts, setSpeechPrompts] = useState<any[]>([]);
    const [loadingSpeechPrompts, setLoadingSpeechPrompts] = useState(true);

    async function getPrompts() {
        try {
            setLoadingPrompts(true);
            const promptsRes = await UserApi.grabEssayPrompts();
            setEssayPrompts(promptsRes);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingPrompts(false);
        }
    }

    async function getInterviewQuestion() {
        try {
            setInterviewHintCount(0);

            setLoadingQuestion(true);
            const questionRes = await UserApi.grabInterviewQuestion();
            setInterviewQuestion(questionRes);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingQuestion(false);
        }
    }

    async function getSpeechPrompts() {
        try {
            setLoadingSpeechPrompts(true);
            const promptsRes = await UserApi.grabImpromptuSpeechPrompt();
            setSpeechPrompts(promptsRes);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingSpeechPrompts(false);
        }
    
    }

    useEffect(() => {
        getPrompts();
        getInterviewQuestion();
        getSpeechPrompts();
    }, []);
    

    return (
      <div style={{width:'85rem', margin:'auto', marginLeft:'15%'}}>
        <Tabs aria-label="Options">
          <Tab key="essay" title="Essay Prompts">
            <Card>
              <CardBody style={{ lineHeight: "30px" }}>
                {loadingPrompts && <Spinner />}
                {
                    !loadingPrompts && <>
                    <div style={{display:'flex'}}>
                    <p>
                        A. {essayPrompts[0].prompt}
                    </p>
                        
                    <div style={{minWidth:'1.5rem', minHeight:'1.5rem', color:'white', marginLeft:'0.7rem'}}>
                            {getCategoryIcon(essayPrompts[0].category) || null}
                        </div>
                    </div>
                    
                    <Divider
                    style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }}
                    />
                    <div style={{display:'flex'}}>
                        B. {essayPrompts[1].prompt}
                        <div style={{minWidth:'1.5rem', minHeight:'1.5rem', color:'white', marginLeft:'0.7rem'}}>
                            {getCategoryIcon(essayPrompts[1].category) || null}
                        </div>
                    </div>
                    <Divider
                    style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }}
                    />
                    <div style={{display:'flex'}}>
                        
                        C. {essayPrompts[2].prompt}
                        <div style={{minWidth:'1.5rem', minHeight:'1.5rem', color:'white', marginLeft:'0.7rem'}}>
                            {getCategoryIcon(essayPrompts[2].category) || null}
                        </div>
                    </div>
                    </>
                }
                
                  
              </CardBody>
              <CardFooter>
                <Button
                  variant="ghost"
                  style={{ margin: "1rem" }}
                  color="primary"
                  isLoading={loadingPrompts}
                  onClick={() => {
                    getPrompts();
                  }}
                >
                  Change Prompts
                </Button>
              </CardFooter>
            </Card>
          </Tab>
          <Tab key="interview" title="Interview Questions">
            <Card>
              <CardBody>
                {loadingQuestion && <Spinner />}
                {!loadingQuestion && interviewQuestion.prompt}
                {interviewHintCount > 0 && <Divider style={{marginTop:'1rem', marginBottom:'1rem', width:'90%'}}/>}
                {interviewHintCount > 0 && interviewQuestion.hints[interviewHintCount - 1]}
              </CardBody>
              <CardFooter>
              <Button
                  variant="ghost"
                  style={{ margin: "1rem" }}
                  color="primary"
                  isLoading={loadingPrompts}
                  onClick={() => {
                    getInterviewQuestion();
                  }}
                >
                  Change Question
                </Button>
                <Button
                  variant="ghost"
                  style={{ margin: "1rem" }}
                  color="warning"
                  isDisabled={loadingQuestion}
                  onClick={() => {
                    setInterviewHintCount(interviewHintCount + 1);
                  }}
                >
                  Show Hint
                </Button>
            </CardFooter>
            </Card>
          </Tab>
          <Tab key="speech" title="Impromptu Speech Prompts">
            <Card>
              <CardBody style={{lineHeight:'30px'}}>
                 {
                    loadingSpeechPrompts && <Spinner />
                 }

                 <p>A. {!loadingSpeechPrompts && speechPrompts[0].prompt}</p>
                 <Divider
                    style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }}
                    />
                 <p>B. {!loadingSpeechPrompts && speechPrompts[1].prompt}</p>
                 <Divider
                    style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }}
                    />
                 <p>C. {!loadingSpeechPrompts && speechPrompts[2].prompt}</p>
                 
              </CardBody>
              <CardFooter>
              <Button
                  variant="ghost"
                  style={{ margin: "1rem" }}
                  color="primary"
                  isLoading={loadingSpeechPrompts}
                  onClick={() => {
                    getSpeechPrompts();
                  }}
                >
                  Change Prompts
                </Button>
              </CardFooter>
            </Card>
          </Tab>
        </Tabs>
      </div>
    );
}
 
export default SubjectiveProps;