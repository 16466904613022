import DashboardLoggedInView from "../components/DashboardLoggedInView";
import QuestionPageLoggedOutView from "../components/QuestionPagedLoggedOutView";
import { User } from "../models/user";

interface DashboardPageProps {
    loggedInUser: User | null
}


const DashboardPage = (props : DashboardPageProps) => {
    return ( 
        <>
        {props.loggedInUser ? 
          <div style={{marginTop:'7rem'}}>
            <DashboardLoggedInView loggedInUser={props.loggedInUser}></DashboardLoggedInView>
          </div> : 
          <QuestionPageLoggedOutView></QuestionPageLoggedOutView>
        }
        
        </>
        
     );
}
 
export default DashboardPage;