import { Avatar, Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, Radio, RadioGroup, Tab, Tabs } from "@nextui-org/react";
import { User } from "../models/user";
import * as UserApi from "../network/user_api"
import { HugoLogo } from "./HugoLogo";

interface NavBarLoggedOutViewProps {
    onSignupClicked: () => void,
    onLoginClicked: () => void
}


const NavBarLoggedOutView = ({onSignupClicked, onLoginClicked} : NavBarLoggedOutViewProps) => {
    return ( 
        <>
            <Navbar style={{backgroundColor:"#101010", marginBottom:"1rem"}}>
                    <NavbarBrand>
                    <HugoLogo />
                    </NavbarBrand>
                    <NavbarContent className="sm:flex gap-4" justify="center" style={{marginLeft:"4.5rem"}}>
                        <NavbarItem>
                        
                        </NavbarItem>
                    </NavbarContent>
                    <NavbarContent justify="end">
                        <NavbarItem className="lg:flex">
                            <Button as={Link} color="default" style={{backgroundColor:'#373E40'}} href="#" variant="flat" onPress={onLoginClicked}>
                                Login
                            </Button>
                            </NavbarItem>
                            <NavbarItem>
                            <Button as={Link} color="default" style={{backgroundColor: '#373E40'}} href="#" variant="flat" onPress={onSignupClicked}>
                                Sign Up
                            </Button>
                        </NavbarItem>
                    </NavbarContent>
                </Navbar>

        </>

     );
}
 
export default NavBarLoggedOutView;