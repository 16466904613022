import { User } from "../models/user";
import QuestionPageLoggedOutView from "./QuestionPagedLoggedOutView";
import QuizzesLoggedInView from "./QuizzesLoggedInView";

interface QuizzesMenuProps {
    loggedInUser: User | null
}

const QuizzesMenu = ({loggedInUser} : QuizzesMenuProps) => {
    return (
        <>
        {loggedInUser ? 
            <div style={{marginTop:'7rem'}}>
                <QuizzesLoggedInView 
                    loggedInUser={loggedInUser}
                />    
            </div>
            :
            <QuestionPageLoggedOutView/>
            }
        </>
        
     );
}
 
export default QuizzesMenu;