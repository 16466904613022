import { HugoLogo } from "../components/HugoLogo";

const NotFoundPage = () => {
    return ( 
        <div style={{marginTop:'7rem'}}>
            <div style={{justifyContent:"center", display:"flex"}}>
                <HugoLogo/>
                
            </div>

            <div style={{justifyContent:"center", display:"flex"}}>
                <h2 style={{textAlign:"center"}}>Page Not Found</h2>
            </div>
            
        </div>
     );
}
 
export default NotFoundPage;