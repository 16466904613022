import {
    Avatar,
    User as UiUser,
    Button,
    Checkbox,
    CheckboxGroup,
    Divider,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Select,
    SelectItem,
    Skeleton,
    Switch,
    Textarea,
    user,
    Selection,
    Spinner,
    Tooltip,
    Tab,
    Tabs,
    Badge,
    Listbox,
    ListboxItem,
    DatePicker,
    Calendar,
    TimeInput,
    AvatarGroup,
    Chip,
    Card,
    CardHeader,
    CardBody,
    Radio,
    RadioGroup,
    Slider,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Progress,
} from "@nextui-org/react";
import { User } from "../models/user";
import {
    assignGpaCategory,
    createPracticeTest,
    getOrganizationEvents,
    getPracticeTests,
    getProfile,
    getSocialSettings,
    grabPracticeTestResponses,
    grabPublicUsersInOrganization,
    orgManagement_acceptJoinRequest,
    orgManagement_addAdmin,
    orgManagement_banUser,
    orgManagement_createOrgEvent,
    orgManagement_deleteOrgEvent,
    orgManagement_dismissJoinRequest,
    orgManagement_getBannedUsers,
    orgManagement_getJoinRequests,
    orgManagement_getStats,
    orgManagement_getUserTestRecordHistory,
    orgManagement_kickUser,
    orgManagement_removeAdmin,
    orgManagement_removeBio,
    orgManagement_removeProfilePicture,
    orgManagement_removeStatus,
    orgManagement_sendPrivateMessage,
    orgManagement_setSettings,
    orgManagement_unbanUser,
    orgManagement_updateOrgEvent,
    regenerateQuestionAtIndex,
    setSocialSettings,
} from "../network/user_api";
import { ReactElement, useEffect, useState } from "react";
import { set } from "react-hook-form";
import { useFilePicker } from "use-file-picker";
import {
    FileAmountLimitValidator,
    FileTypeValidator,
    FileSizeValidator,
    ImageDimensionsValidator,
} from "use-file-picker/validators";
import InteractiveProfile from "./InteractiveProfile";
import UserChip from "./UserChip";
import React from "react";
import LoadingStationIcon from "./Icons/LoadingIcons/LoadingStationIcon";
import { JsxElement } from "typescript";
import MountainsIcon from "./Icons/LoadingIcons/MountainsIcon";
import RefreshIcon from "./Icons/RefreshIcon";
import {
    Line,
    LineChart,
    XAxis,
    YAxis,
    Tooltip as ChartTooltip,
} from "recharts";
import { Time, CalendarDateTime, ZonedDateTime } from "@internationalized/date";
import { now, getLocalTimeZone } from "@internationalized/date";
import {
    evaluateMathQuestion,
    getCategoryIcon,
    grabCompetitiveIdentifier,
    grabIdentifier,
} from "../util/categoryIconHelper";
import LeftArrowIcon from "./Icons/LeftArrowIcon";
import RightArrowIcon from "./Icons/RightArrowIcon";
import CompetitiveModeIcon from "./Icons/CompetitiveModeIcon";
import { generateCategoricalChart } from "recharts/types/chart/generateCategoricalChart";

interface ManageOrganizationModalProps {
    loggedInUser: User;
    org: any;
    onDismiss: () => void;
}

const ManageOrganizationModal = ({
    loggedInUser,
    onDismiss,
    org,
}: ManageOrganizationModalProps) => {
    const loadingMessages = [
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                marginBottom: "4rem",
            }}>
            <LoadingStationIcon style={{ marginRight: "0.6rem" }} />
            <p style={{ color: "white", fontWeight: 600 }}>
                Loading your fully operational battle station...
            </p>
        </div>,
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                marginBottom: "4rem",
            }}>
            <p style={{ color: "green", fontWeight: 600 }}>
                Your management page, loading is...
            </p>
        </div>,
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                marginBottom: "4rem",
            }}>
            <MountainsIcon style={{ marginRight: "0.6rem" }} />
            <p style={{ color: "white", fontWeight: 600 }}>
                Loading the temple of doom...
            </p>
        </div>,
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                marginBottom: "4rem",
            }}>
            <p style={{ color: "white", fontWeight: 600 }}>
                Loading the management page...
            </p>
        </div>,
    ];

    const categoryCount = [4, 4, 0, 4, 4, 5, 4]
    const [sectionsError, setSectionsError] = useState("");
    const [sectionOneEnabled, setSectionOneEnabled] = useState(false);
    const [sectionTwoEnabled, setSectionTwoEnabled] = useState(false);
    const [sectionThreeEnabled, setSectionThreeEnabled] = useState(false);
    const [sectionFourEnabled, setSectionFourEnabled] = useState(false);
    const [sectionFiveEnabled, setSectionFiveEnabled] = useState(false);
    
    const [socialFeaturesEnabledI, setSocialFeaturesEnabledI] = useState(
        org.socialFeaturesEnabled
    );
    const [leaderboardEnabled, setLeaderboardEnabled] = useState(
        org.leaderboardEnabled
    );

    // TODO Stub
    const [gpaCategoryVisible, setGpaCategoryVisible] = useState(
        false
    );

    const [leaderboardGpaSplit, setLeaderboardGpaSplit] = useState(
        false
    );

    const [personalLeaderboardVisibility, setPersonalLeaderboardVisibility] = useState(
        false
    );

    const [varsityLeaderboardHidden, setVarsityLeaderboardHidden] = useState(
        false
    );

    const [mustRequestToJoin, setMustRequestToJoin] = useState(
        org.requestToJoinEnabled
    );
    const [leaderboardRankHidden, setLeaderboardRankHidden] = useState(
        org.leaderboardRankHidden
    );

    const [isReviewable, setIsReviewable] = useState(true);

    const [submitting, setSubmitting] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [orgUsers, setOrgUsers] = useState<any[]>([]);
    const [selectedUsers, setSelectedUsers] = React.useState<Selection>(
        new Set([])
    );

    const [areAllSelectedAdmins, setAreAllSelectedAdmins] = useState(false);
    const [areAllSelectedBanned, setAreAllSelectedBanned] = useState(false);

    const [generalError, setGeneralError] = useState("");

    const [adminActionLoading, setAdminActionLoading] = useState(false);
    const [shouldNotify, setShouldNotify] = useState(false);

    const [joinRequests, setJoinRequests] = useState<any[]>([]);
    const [bannedUsers, setBannedUsers] = useState<any[]>([]);

    const [messageContent, setMessageContent] = useState("");

    const [mode, setMode] = useState("");
    const [data, setData] = useState<any[]>([]);

    const [selectedDate, setSelectedDate] = useState<ZonedDateTime>(
        now(getLocalTimeZone())
    );
    const [eventNameError, setEventNameError] = useState("");
    const [eventName, setEventName] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [selectedUsersForEventOnly, setSelectedUsersForEventOnly] =
        useState(false);
    const [recurringEvent, setRecurringEvent] = useState(false);
    const [dateError, setDateError] = useState("");

    const [currentEvents, setCurrentEvents] = useState<any[]>([]);
    const [selectedEditEvent, setSelectedEditEvent] = useState<any>();
    const [eventUsers, setEventUsers] = useState<any[]>([]);

    const [editEventDescription, setEditEventDescription] = useState("");
    const [editEventName, setEditEventName] = useState("");
    const [editEventIsExclusive, setEditEventIsExclusive] = useState(false);
    const [updateWithSelectedUsers, setUpdateWithSelectedUsers] = useState(false);

    const [generatePracticeTestLoading, setGeneratePracticeTestLoading] = useState(false);

    const [testHistoryViewPage, setTestHistoryViewPage] = useState(0);
    const [testRecords, setTestRecords] = useState<any[]>([]);
    const [testRecordQuestions, setTestRecordQuestions] = useState<any[]>([]);
    const [testRecordAmount, setTestRecordAmount] = useState(0);

    const [reviewQuestion, setReviewQuestion] = useState<any>();
    const [selectedRecord, setSelectedRecord] = useState<any>();
    const [selectedRecordQuestions, setSelectedRecordQuestions] = useState<any>();

    const [graphCategory, setGraphCategory] = useState(-1);
    const [graphType, setGraphType] = useState(0);
    const [graphTimeFrame, setGraphTimeFrame] = useState(2);
    const [graphLoading, setGraphLoading] = useState(true);
    const [selectedUsersForGraphOnly, setSelectedUsersForGraphOnly] =
        useState(false);

    const [selectedPracticeTest, setSelectedPracticeTest] = useState<any>()
    const [practiceTestResponses, setPracticeTestResponses] = useState<any[]>()
    const [practiceTestResponsesLoading, setPracticeTestResponsesLoading] = useState(false);

    const [liveCorrections, setliveCorrections] = useState(false);
    const [showPageNum, setShowPageNum] = useState(false);
    const [questionCount, setQuestionCount] = useState(25);
    const [category, setCategory] = useState(5);
    const [categoryError, setCategoryError] = useState("");
    const [questionCountError, setQuestionCountError] = useState("");
    const [competitiveMode, setCompetitiveMode] = useState(false);
    const [oneResponsePerUserOnly, setOneResponsePerUserOnly] = useState(false);

    const [practiceTests, setPracticeTests] = useState<any[]>([]);
    const [practiceQuestionsPerTest, setPracticeQuestionsPerTest] = useState<any[]>([]);

    const categories = [
        "socialScience",
        "science",
        "math",
        "literature",
        "economics",
        "art",
        "music",
    ];

    const types = ["Answered", "Correct", "Average"];
    const timeFrames = ["Month", "Week", "Day"];

    async function grabOrganizationUsers() {
        try {
            setIsLoading(true);

            const r = await orgManagement_getJoinRequests();

            setJoinRequests(r.requests);

            let z: any[] = [];

            const b: any = await orgManagement_getBannedUsers();
            let orgMembers: any[] = await grabPublicUsersInOrganization({
                clientOfOriginUsername: loggedInUser.username,
                orgCode: org.code,
                page: -1,
            });

            let q = 0;
            for (let i = 0; i < b.bannedUsers.length; i++) {
                if (b.bannedUsers[i] !== "") {
                    z[q] = await getProfile({
                        userId: b.bannedUsers[i].toString(),
                        self: false,
                    });
                    q++;
                }
            }

            setBannedUsers(z);

            if (z.length > 0) {
                orgMembers = orgMembers.concat(...z);
            }

            setOrgUsers(orgMembers);

            const f = await getOrganizationEvents();

            setCurrentEvents(f.events);

            const h = await getPracticeTests();
            console.log(h.tests); 
            

            setPracticeTests(h.tests.reverse());
            setPracticeQuestionsPerTest(h.questionsPerTest.reverse());
        } catch {
            console.error("Error getting settings, try again later.");
        } finally {
            setIsLoading(false);
        }
    }

    async function getStats() {
        try {
            if (orgUsers.length == 0) return;
            setGraphLoading(true);

            let reqUsers: any[] = [];

            if (selectedUsersForGraphOnly) {
                const g = Array.from(selectedUsers);
                reqUsers = g;
            }

            console.log(reqUsers);

            const r = await orgManagement_getStats({
                users: reqUsers,
                timeFrame: graphTimeFrame,
                category: graphCategory,
                type: graphType,
            });

            setData(r.data);
        } catch (error) {
            
        } finally {
            setGraphLoading(false);
        }
    }

    async function grabTestHistory(userId: string) {
        try {
            const res = await orgManagement_getUserTestRecordHistory({
                page: testHistoryViewPage,
                userId: userId,
            });

            console.log(res);

            setTestRecords(res.records);
            setTestRecordQuestions(res.questions);
            setTestRecordAmount(res.totalRecordAmount);
        } catch (error) { }
    }

    useEffect(() => {
        grabOrganizationUsers();
    }, []);

    const questionViewingUi = (
        <Card
            className="mt-4 pb-3"
            style={{ width: "60rem", justifyContent: "center", margin: "auto" }}>
            <CardHeader className="flex justify-center items-center mb-2 h-3/6" style={{textAlign:'center'}}>
                <Button
                    color="danger"
                    style={{ left: "-40px" }}
                    onClick={() => {
                        setSelectedRecord(null);
                        setReviewQuestion(0);
                    }}
                    isLoading={isLoading}>
                    Back
                </Button>
                <div className="flex justify-center items-center mb-1 h-3/6">
                    <p
                        style={{
                            justifyContent: "center",
                            margin: "auto",
                            fontWeight: 600,
                        }}>
                        {selectedRecord && selectedRecord.code ? "Review Premade Test Questions [" + selectedRecord.code + "]" : "Review Questions"}
                    </p>
                </div>
                {
                    selectedRecord && selectedRecord.code && <Button
                    color="secondary"
                    style={{ left: "40px" }}
                    isLoading={isLoading}
                    onClick={async () => {
                        setIsLoading(true);

                        try {
                            const u = await regenerateQuestionAtIndex({
                                code: selectedRecord.code,
                                questionIndex: reviewQuestion
                            });

                            const w = structuredClone(selectedRecordQuestions);
                            w[reviewQuestion] = u.newQuestion;

                            setSelectedRecordQuestions(w)
                        } catch (error) {
                            
                        } finally {
                            setIsLoading(false);
                        }
                        
                    }}>
                    Regenerate
                </Button>
                }
            </CardHeader>
            <Divider
                style={{ width: "90%", justifyContent: "center", margin: "auto" }}
            />
            <CardBody>
                {selectedRecord && selectedRecordQuestions && selectedRecordQuestions.length > 1 && (
                    <Slider
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            justifyContent: "center",
                            display: "flex",
                        }}
                        size="sm"
                        step={1}
                        color="foreground"
                        showSteps={true}
                        value={reviewQuestion + 1}
                        onChange={(e) => {
                            setReviewQuestion((e as number) - 1);
                        }}
                        maxValue={selectedPracticeTest ? selectedPracticeTest.questionCount : selectedRecord ? selectedRecordQuestions.length : 0}
                        minValue={1}
                        defaultValue={1}
                        startContent={
                            <Button
                                onClick={() => {
                                    setReviewQuestion(
                                        reviewQuestion - 1 >= 0
                                            ? reviewQuestion - 1
                                            : reviewQuestion
                                    );
                                }}
                                isIconOnly>
                                <LeftArrowIcon />
                            </Button>
                        }
                        endContent={
                            <Button
                                onClick={() => {
                                    setReviewQuestion(
                                        reviewQuestion + 1 <
                                            (selectedPracticeTest ? selectedPracticeTest.questionCount : selectedRecord ? selectedRecordQuestions.length : 0)
                                            ? reviewQuestion + 1
                                            : reviewQuestion
                                    );
                                }}
                                isIconOnly>
                                <RightArrowIcon />
                            </Button>
                        }
                        className="max-w-md"
                    />
                )}

                {
                    selectedRecord && selectedRecordQuestions[reviewQuestion] === null &&
                    <div className="flex w-full flex-col">
                            <p style={{textAlign:'center'}}>This question has been deleted or otherwise no longer exists likely due to innacuracy or low quality. It has been replaced, apologies for any inconvenience.</p>
                                <Tab key="questionLabel" title="Question" isDisabled={true}>
                              
                              </Tab>
                              <Tab isDisabled={true} key="pageAndSection" title="Page & Section">
    
                              </Tab>
                              <Tab isDisabled={true} key="justification" title="Justification">
    
                              </Tab>
                    </div>
                }

                {selectedRecord && selectedRecordQuestions && selectedRecordQuestions[reviewQuestion] && selectedRecordQuestions[reviewQuestion] !== null && reviewQuestion < selectedRecordQuestions.length && (
                    
                    
                    
                        <>
                        <div className="flex w-full flex-col">
                            {(selectedRecord.categories[0] === 'math' && selectedRecord.freeAnswers && reviewQuestion >= selectedRecord.freeAnswers.length) || (selectedRecord.categories[0] !== 'math' && selectedRecord.answers && reviewQuestion >= selectedRecord.answers.length) ? <p style={{textAlign:'center', margin:'10px'}}>Question Not Answered During Test</p> : <></>
                            }
                            <Tabs
                                aria-label="Options"
                                style={{ marginLeft: "auto", marginRight: "auto" }}>
                                <Tab key="questionLabel" title="Question">
                                    <Card
                                        style={{
                                            justifyContent: "center",
                                            width: "40rem",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}>
                                        <CardBody>
                                            <p
                                                className="font-bold text-inherit text-balance"
                                                style={{
                                                    textWrap: "pretty",
                                                    marginRight: "auto",
                                                    marginLeft: "auto",
                                                    textAlign: "center",
                                                }}>
                                                {reviewQuestion + 1}.{" "}
                                                {selectedRecordQuestions[reviewQuestion]?.question}
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Tab>
                                {
                                    <Tab key="pageAndSection" title="Page & Section">
                                        <Card
                                            style={{
                                                justifyContent: "center",
                                                width: "19rem",
                                                height: "auto",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                            }}>
                                            <CardBody>
                                                <p
                                                    style={{
                                                        justifyContent: "center",
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                        fontWeight: 550,
                                                        fontSize: 27,
                                                        marginTop: "auto",
                                                        marginBottom: "0.6rem",
                                                    }}>
                                                    Section{" "}
                                                    {selectedRecordQuestions[reviewQuestion].section}.
                                                    Page{" "}
                                                    {selectedRecordQuestions[reviewQuestion]
                                                        .pageNumberEnd ==
                                                        selectedRecordQuestions[reviewQuestion]
                                                            .pageNumberStart
                                                        ? selectedRecordQuestions[
                                                            reviewQuestion
                                                        ].pageNumberStart?.toString()
                                                        : selectedRecordQuestions[
                                                            reviewQuestion
                                                        ].pageNumberStart?.toString() +
                                                        " - " +
                                                        selectedRecordQuestions[
                                                            reviewQuestion
                                                        ].pageNumberEnd?.toString()}
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Tab>
                                }
                                <Tab key="justification" title="Justification">
                                    <Card
                                        style={{
                                            justifyContent: "center",
                                            width: "40rem",
                                            height: "auto",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}>
                                        <CardBody>
                                            <p
                                                className="font-bold text-inherit text-balance"
                                                style={{
                                                    textWrap: "pretty",
                                                    marginRight: "auto",
                                                    marginLeft: "auto",
                                                    textAlign: "center",
                                                }}>
                                                {selectedRecordQuestions[reviewQuestion].justification}
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Tab>
                            </Tabs>
                        </div>

                        {
                        selectedRecord && selectedRecord.categories[0] === "math" ?
                        !selectedRecord.freeAnswers || (reviewQuestion >= selectedRecord.freeAnswers.length || evaluateMathQuestion(selectedRecordQuestions[reviewQuestion], selectedRecord.freeAnswers[reviewQuestion]).correct) ? 
                            <div className="flex justify-center items-center w-1/3 h-3/6" style={{marginBottom:"auto", width:'40%', justifyContent:'center', textAlign:'center', margin:'auto'}}>
                                <Input label="Correct Answer" style={{color:'#5BDD68'}} isReadOnly value={evaluateMathQuestion(selectedRecordQuestions[reviewQuestion], "").correctAnswer}/>
                            </div>
                            :
                            <Tabs>
                                <Tab title="Their Answer (Incorrect)">
                                    <div className="flex justify-center items-center w-1/3 h-3/6" style={{marginBottom:"auto", width:'40%', justifyContent:'center', textAlign:'center', margin:'auto'}}>
                                        <Input label="My Answer (Correct)" style={{color:'#5BDD68'}} isReadOnly value={selectedRecord.freeAnswers[reviewQuestion]}/>
                                    </div>
                                </Tab>
                                <Tab title="Correct Answer">
                                    <div className="flex justify-center items-center w-1/3 h-3/6" style={{marginBottom:"auto", width:'40%', justifyContent:'center', textAlign:'center', margin:'auto'}}>
                                        <Input label="My Answer (Correct)" style={{color:'#5BDD68'}} isReadOnly value={evaluateMathQuestion(selectedRecordQuestions[reviewQuestion], "").correctAnswer}/>
                                    </div>
                                </Tab>
                            </Tabs>
                            


                        :
                        
                        
                        !selectedRecord.answers || reviewQuestion >= selectedRecord.answers.length || (selectedRecord.answers && selectedRecordQuestions[reviewQuestion].correctAnswer !==
                            selectedRecord.answers[reviewQuestion]) ? (
                            <Tabs style={{ justifyContent: "center", margin: "auto" }}>
                                <Tab title="Correct Answer">
                                    <div
                                        className="flex justify-center items-center h-3/6"
                                        style={{ marginBottom: "auto" }}>
                                        <RadioGroup
                                            id="radioGroup"
                                            value={selectedRecordQuestions[
                                                reviewQuestion
                                            ].correctAnswer.toString()}>
                                            <Radio
                                                value="0"
                                                color={
                                                    selectedRecordQuestions[reviewQuestion]
                                                        .correctAnswer === 0
                                                        ? "success"
                                                        : "default"
                                                }>
                                                {selectedRecordQuestions[reviewQuestion].answerChoiceA}
                                            </Radio>
                                            <Radio
                                                value="1"
                                                color={
                                                    selectedRecordQuestions[reviewQuestion]
                                                        .correctAnswer == 1
                                                        ? "success"
                                                        : "default"
                                                }>
                                                {selectedRecordQuestions[reviewQuestion].answerChoiceB}
                                            </Radio>
                                            <Radio
                                                value="2"
                                                color={
                                                    selectedRecordQuestions[reviewQuestion]
                                                        .correctAnswer == 2
                                                        ? "success"
                                                        : "default"
                                                }>
                                                {selectedRecordQuestions[reviewQuestion].answerChoiceC}
                                            </Radio>
                                            <Radio
                                                value="3"
                                                color={
                                                    selectedRecordQuestions[reviewQuestion]
                                                        .correctAnswer == 3
                                                        ? "success"
                                                        : "default"
                                                }>
                                                {selectedRecordQuestions[reviewQuestion].answerChoiceD}
                                            </Radio>
                                            <Radio
                                                value="4"
                                                color={
                                                    selectedRecordQuestions[reviewQuestion]
                                                        .correctAnswer == 4
                                                        ? "success"
                                                        : "default"
                                                }>
                                                {selectedRecordQuestions[reviewQuestion].answerChoiceE}
                                            </Radio>
                                        </RadioGroup>
                                    </div>
                                </Tab>
                                {selectedRecord.answers && reviewQuestion < selectedRecord.answers.length && <Tab isDisabled={selectedRecord.answers === undefined} title="My Answer (Incorrect)">
                                    <div
                                        className="flex justify-center items-center h-3/6"
                                        style={{ marginBottom: "auto" }}>
                                        <RadioGroup
                                            id="radioGroup"
                                            value={
                                                selectedRecord.answers &&
                                                    selectedRecord.answers.length > 0
                                                    ? selectedRecord.answers[reviewQuestion].toString()
                                                    : "-1"
                                            }>
                                            <Radio
                                                value="0"
                                                color={
                                                    selectedRecordQuestions[reviewQuestion]
                                                        .correctAnswer == 0
                                                        ? "success"
                                                        : "default"
                                                }>
                                                {selectedRecordQuestions[reviewQuestion].answerChoiceA}
                                            </Radio>
                                            <Radio
                                                value="1"
                                                color={
                                                    selectedRecordQuestions[reviewQuestion]
                                                        .correctAnswer == 1
                                                        ? "success"
                                                        : "default"
                                                }>
                                                {selectedRecordQuestions[reviewQuestion].answerChoiceB}
                                            </Radio>
                                            <Radio
                                                value="2"
                                                color={
                                                    selectedRecordQuestions[reviewQuestion]
                                                        .correctAnswer == 2
                                                        ? "success"
                                                        : "default"
                                                }>
                                                {selectedRecordQuestions[reviewQuestion].answerChoiceC}
                                            </Radio>
                                            <Radio
                                                value="3"
                                                color={
                                                    selectedRecordQuestions[reviewQuestion]
                                                        .correctAnswer == 3
                                                        ? "success"
                                                        : "default"
                                                }>
                                                {selectedRecordQuestions[reviewQuestion].answerChoiceD}
                                            </Radio>
                                            <Radio
                                                value="4"
                                                color={
                                                    selectedRecordQuestions[reviewQuestion]
                                                        .correctAnswer == 4
                                                        ? "success"
                                                        : "default"
                                                }>
                                                {selectedRecordQuestions[reviewQuestion].answerChoiceE}
                                            </Radio>
                                        </RadioGroup>
                                    </div>
                                </Tab>}
                            </Tabs>
                        ) : (
                            <div
                                className="flex justify-center items-center h-3/6"
                                style={{ marginBottom: "auto" }}>
                                <RadioGroup
                                    id="radioGroup"
                                    value={selectedRecordQuestions[
                                        reviewQuestion
                                    ].correctAnswer.toString()}>
                                    <Radio
                                        value="0"
                                        color={
                                            selectedRecordQuestions[reviewQuestion].correctAnswer == 0
                                                ? "success"
                                                : "default"
                                        }>
                                        {selectedRecordQuestions[reviewQuestion].answerChoiceA}
                                    </Radio>
                                    <Radio
                                        value="1"
                                        color={
                                            selectedRecordQuestions[reviewQuestion].correctAnswer == 1
                                                ? "success"
                                                : "default"
                                        }>
                                        {selectedRecordQuestions[reviewQuestion].answerChoiceB}
                                    </Radio>
                                    <Radio
                                        value="2"
                                        color={
                                            selectedRecordQuestions[reviewQuestion].correctAnswer == 2
                                                ? "success"
                                                : "default"
                                        }>
                                        {selectedRecordQuestions[reviewQuestion].answerChoiceC}
                                    </Radio>
                                    <Radio
                                        value="3"
                                        color={
                                            selectedRecordQuestions[reviewQuestion].correctAnswer == 3
                                                ? "success"
                                                : "default"
                                        }>
                                        {selectedRecordQuestions[reviewQuestion].answerChoiceD}
                                    </Radio>
                                    <Radio
                                        value="4"
                                        color={
                                            selectedRecordQuestions[reviewQuestion].correctAnswer == 4
                                                ? "success"
                                                : "default"
                                        }>
                                        {selectedRecordQuestions[reviewQuestion].answerChoiceE}
                                    </Radio>
                                </RadioGroup>
                            </div>
                        )}
                    </>
                )}
            </CardBody>
        </Card>
    );

    const practiceTestResultsViewingUi = (
        <Card
            className="mt-4 pb-3"
            style={{ width: "60rem", justifyContent: "center", margin: "auto" }}>
            <CardHeader className="flex justify-center items-center mb-2 h-3/6" style={{textAlign:'center'}}>
                <Button
                    color="danger"
                    style={{ left: "-40px" }}
                    onClick={() => {
                        setSelectedPracticeTest(null);
                        setPracticeTestResponses([]);
                    }}
                    isLoading={isLoading}>
                    Back
                </Button>
                <div className="flex justify-center items-center mb-1 h-3/6">
                    <p
                        style={{
                            justifyContent: "center",
                            margin: "auto",
                            fontWeight: 600,
                        }}>
                        Review Test Responses
                    </p>
                </div>
            </CardHeader>
            <Divider
                style={{ width: "90%", justifyContent: "center", margin: "auto" }}
            />
            <CardBody>
                
            {practiceTestResponsesLoading || !practiceTestResponses ? 
            <Spinner/>
            :
            <Listbox emptyContent={<p style={{textAlign:'center'}}>No Practice Test Responses</p>} style={{maxHeight:'300px'}}>
                {
                    practiceTestResponses.sort((a:any, b:any) => {
                        return (b.correctAmt - a.correctAmt);
                    }).map((v:any) => {
                        return (
                            <ListboxItem style={{width:'50%', justifyContent:'center', margin:'auto'}} startContent={ <UserChip
                                user={orgUsers.find((t) => {
                                    
                                    if (t._id === v.user) {
                                        return t;
                                    }
                                })}
                            />} key={v.id} onClick={() => {
                                setSelectedRecord(v);
                                setReviewQuestion(0);
                                setSelectedRecordQuestions(practiceQuestionsPerTest[practiceTests.indexOf(selectedPracticeTest)])
                            }}>
                                <Progress
                                aria-label="Performance"
                                size="sm"
                                color="secondary"
                                maxValue={selectedPracticeTest.questionCount as number}
                                value={v.correctAmt}
                                label="Performance"
                                showValueLabel={true}
                                valueLabel={v.correctAmt + "/" + selectedPracticeTest.questionCount + " (Answered " + v.answered + ")"}
                                className="max-w-md"
                        />
                            </ListboxItem>
                        )
                    })
                }
            </Listbox>}
                
            </CardBody>
        </Card>
    )

    return (
        <>
            <Modal isOpen={true} onClose={onDismiss} backdrop="blur">
                <ModalContent style={{ maxWidth: "60rem" }}>
                    {(onClose) =>
                        !selectedRecord ? (
                            selectedPracticeTest ?
                            practiceTestResultsViewingUi
                            :
                            <div>
                                {isLoading && (
                                    <>
                                        <Spinner
                                            color="secondary"
                                            style={{
                                                justifyContent: "center",
                                                margin: "auto",
                                                left: "47.5%",
                                                marginTop: "10rem",
                                                marginBottom: "3rem",
                                            }}
                                        />
                                        {
                                            loadingMessages[
                                            Math.floor(Math.random() * loadingMessages.length)
                                            ]
                                        }
                                    </>
                                )}
                                {!isLoading && (
                                    <>
                                        <ModalHeader
                                            className="flex flex-col gap-1"
                                            style={{ color: "white" }}>
                                            Manage {org.name}
                                        </ModalHeader>
                                        <ModalBody>
                                            <p style={{ color: "white" }}>
                                                Your Organization's Join Code: {org.code}
                                            </p>
                                            <Divider />

                                            <Divider />

                                            <Select
                                                label="Manage Users"
                                                placeholder="Select users to manage"
                                                className="max-w-lg gap-2"
                                                style={{
                                                    width:'55rem'
                                                }}
                                                selectionMode="multiple"
                                                labelPlacement="outside"
                                                isInvalid={generalError != ""}
                                                errorMessage={generalError}
                                                size="md"
                                                scrollShadowProps={{
                                                    offset: 100,
                                                }}
                                                isMultiline={true}
                                                selectedKeys={selectedUsers}
                                                onSelectionChange={(e) => {
                                                    setSelectedUsers(e);
                                                    setAreAllSelectedAdmins(
                                                        Array.from(e).every((v) => {
                                                            return org.admins.includes(v.toString());
                                                        })
                                                    );
                                                    setAreAllSelectedBanned(
                                                        Array.from(e).every((v) => {
                                                            return bannedUsers.includes(
                                                                orgUsers[orgUsers.indexOf(v.toString())]
                                                            );
                                                        })
                                                    );

                                                    let sel = true;

                                                    Array.from(e).forEach((element) => {
                                                        let selB = false;

                                                        bannedUsers.forEach((u) => {
                                                            if (u._id === element) {
                                                                selB = true;
                                                            }
                                                        });

                                                        if (!selB) {
                                                            sel = false;
                                                            return;
                                                        }
                                                    });

                                                    if (!sel) {
                                                        setAreAllSelectedBanned(false);
                                                    } else {
                                                        setAreAllSelectedBanned(true);
                                                    }

                                                    setGeneralError("");
                                                }}
                                                renderValue={(items) => {
                                                    return (
                                                        <div className="gap-2">
                                                            {items.slice(0, 7).map((item: any) => (
                                                                <UserChip
                                                                    user={orgUsers.find((v) => {
                                                                        if (v._id === item.key) {
                                                                            return v;
                                                                        }
                                                                    })}
                                                                />
                                                            ))}
                                                            {items.length > 8 && (
                                                                <p
                                                                    style={{
                                                                        color: "white",
                                                                        marginLeft: "0.5rem",
                                                                        marginBottom: "7px",
                                                                    }}>
                                                                    +{items.length - 8} more
                                                                </p>
                                                            )}
                                                        </div>
                                                    );
                                                }}>
                                                {orgUsers.map((u) => {
                                                    return (
                                                        <SelectItem
                                                            isReadOnly={u._id == loggedInUser._id}
                                                            key={u._id}>
                                                            <Badge
                                                                placement="bottom-left"
                                                                style={{
                                                                    top: "15px",
                                                                    left: "10px",
                                                                }}
                                                                isOneChar={true}
                                                                content="A"
                                                                hidden={!org.admins.includes(u._id)}
                                                                color="primary">
                                                                <div>
                                                                    {u.profilePicture !== "" && (
                                                                        <img
                                                                            src={u.profilePicture}
                                                                            style={{
                                                                                width: "40px",
                                                                                height: "40px",
                                                                                position: "absolute",
                                                                                zIndex: 1,
                                                                                borderRadius: "50px",
                                                                            }}
                                                                            alt=""
                                                                        />
                                                                    )}
                                                                    <UiUser
                                                                        style={{
                                                                            color:
                                                                                bannedUsers.length > 0 &&
                                                                                    bannedUsers.includes(u)
                                                                                    ? "#ce124e"
                                                                                    : "white",
                                                                        }}
                                                                        name={
                                                                            u.username +
                                                                            (loggedInUser._id == u._id
                                                                                ? " (You, Not Selectable)"
                                                                                : "") +
                                                                            (bannedUsers.length > 0
                                                                                ? bannedUsers.includes(u)
                                                                                    ? " (Banned)"
                                                                                    : ""
                                                                                : "")
                                                                            + " - " + (u.email !== undefined ? u.email : "No Access")
                                                                        }
                                                                        description={u.status}
                                                                        avatarProps={{
                                                                            alt: u.username,
                                                                            showFallback: u.profilePicture === "",
                                                                            ignoreFallback: false,
                                                                            getInitials: (name) => {
                                                                                return name.split(" ")[0][0];
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Badge>
                                                        </SelectItem>
                                                    );
                                                })}
                                            </Select>

                                            <Tabs
                                                isVertical={true}
                                                onSelectionChange={(e) => {
                                                    if (e.toString() == "Statistics") getStats();
                                                }}>
                                                <Tab key={"Settings"} title="Settings">
                                                    <CheckboxGroup
                                                        label="Organization Settings"
                                                        orientation="vertical"
                                                        color="secondary">
                                                        <Switch
                                                            size="md"
                                                            isDisabled={isLoading}
                                                            color="secondary"
                                                            isSelected={socialFeaturesEnabledI}
                                                            onValueChange={setSocialFeaturesEnabledI}>
                                                            Social Features Enabled
                                                        </Switch>
                                                        <Switch
                                                            size="md"
                                                            isDisabled={isLoading}
                                                            color="secondary"
                                                            isSelected={mustRequestToJoin}
                                                            onValueChange={setMustRequestToJoin}>
                                                            Members Must Request To Join
                                                        </Switch>
                                                        <Switch
                                                            size="md"
                                                            isDisabled={isLoading}
                                                            color="secondary"
                                                            isSelected={leaderboardEnabled}
                                                            onValueChange={setLeaderboardEnabled}>
                                                            Leaderboard Enabled
                                                        </Switch>
                                                        <Switch
                                                            size="md"
                                                            isDisabled={isLoading}
                                                            color="secondary"
                                                            isSelected={leaderboardRankHidden}
                                                            onValueChange={setLeaderboardRankHidden}>
                                                            Hide Leaderboard Ranks On Profiles
                                                        </Switch>
                                                        
                                                        {
                                                        //TODO Finish implementation
                                                        /*<Switch
                                                            size="md"
                                                            isDisabled={isLoading}
                                                            color="secondary"
                                                            isSelected={gpaCategoryVisible}
                                                            onValueChange={setGpaCategoryVisible}>
                                                            GPA Categories Visible On All Profiles/Leaderboard
                                                        </Switch>
                                                        <Switch
                                                            size="sm"
                                                            isDisabled={isLoading || !gpaCategoryVisible}
                                                            color="secondary"
                                                            isSelected={leaderboardGpaSplit && gpaCategoryVisible}
                                                            onValueChange={setLeaderboardGpaSplit}>
                                                            Leaderboard Split Based on GPA Category
                                                        </Switch>
                                                        <Switch
                                                            size="sm"
                                                            isDisabled={isLoading || !leaderboardGpaSplit || !gpaCategoryVisible}
                                                            color="secondary"
                                                            isSelected={personalLeaderboardVisibility && leaderboardGpaSplit && gpaCategoryVisible}
                                                            onValueChange={setPersonalLeaderboardVisibility}>
                                                            Users can only see their GPA Category Leaderboard
                                                        </Switch>
                                                        <Switch
                                                            size="sm"
                                                            isDisabled={isLoading || (!leaderboardGpaSplit || !gpaCategoryVisible)}
                                                            color="secondary"
                                                            isSelected={varsityLeaderboardHidden && leaderboardGpaSplit && gpaCategoryVisible}
                                                            onValueChange={setVarsityLeaderboardHidden}>
                                                            Varsity leaderboard hidden
                                                        </Switch>*/}
                                                        
                                                        
                                                    </CheckboxGroup>
                                                </Tab>
                                                <Tab key={"Utility"} title="Utility">
                                                    <div
                                                        className="flex-grid"
                                                        style={{
                                                            maxWidth: "100%",
                                                            marginTop: "1rem",
                                                        }}>
                                                        <Tooltip
                                                            style={{
                                                                color: "white",
                                                                maxWidth: "16rem",
                                                            }}
                                                            placement="bottom"
                                                            content="Send a private message to the selected users">
                                                            <Button
                                                                color="secondary"
                                                                style={{
                                                                    color: "white",
                                                                    marginRight: "10px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                onClick={() => {
                                                                    if (mode !== "sendMessage") {
                                                                        setMode("sendMessage");
                                                                    } else {
                                                                        setMode("");
                                                                    }
                                                                }}>
                                                                Send Message
                                                            </Button>
                                                        </Tooltip>

                                                        {areAllSelectedAdmins ? (
                                                            <Tooltip
                                                                style={{
                                                                    color: "white",
                                                                    maxWidth: "16rem",
                                                                }}
                                                                placement="bottom"
                                                                content="Add selected users as administrators, they will be able to access this menu">
                                                                <Button
                                                                    color="secondary"
                                                                    style={{
                                                                        color: "white",
                                                                        marginRight: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                    onClick={async () => {
                                                                        try {
                                                                            Array.from(selectedUsers).forEach(
                                                                                async (e) => {
                                                                                    try {
                                                                                        const res =
                                                                                            await orgManagement_removeAdmin({
                                                                                                newAdminUserId: e.toString(),
                                                                                            });
                                                                                        if (
                                                                                            res.message ==
                                                                                            "Admin removed successfully"
                                                                                        ) {
                                                                                            org.admins = org.admins.filter(
                                                                                                (v: string) => {
                                                                                                    return v != e.toString();
                                                                                                }
                                                                                            );
                                                                                        }
                                                                                    } catch (error: any) {
                                                                                        setGeneralError(error.message);
                                                                                    }
                                                                                }
                                                                            );
                                                                        } catch (error) {
                                                                            alert(error);
                                                                        }

                                                                        await grabOrganizationUsers();
                                                                    }}>
                                                                    Remove Administrator Status
                                                                </Button>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip
                                                                style={{
                                                                    color: "white",
                                                                    maxWidth: "16rem",
                                                                }}
                                                                placement="bottom"
                                                                content="Add selected users as administrators, they will be able to access this menu">
                                                                <Button
                                                                    color="secondary"
                                                                    style={{
                                                                        color: "white",
                                                                        marginRight: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                    onClick={async () => {
                                                                        Array.from(selectedUsers).forEach(
                                                                            async (e) => {
                                                                                const res =
                                                                                    await orgManagement_addAdmin({
                                                                                        newAdminUserId: e.toString(),
                                                                                    });
                                                                                console.log(res);

                                                                                if (
                                                                                    res.message ==
                                                                                    "Admin added successfully"
                                                                                ) {
                                                                                    org.admins = org.admins.concat(
                                                                                        e.toString()
                                                                                    );
                                                                                }
                                                                            }
                                                                        );

                                                                        await grabOrganizationUsers();
                                                                    }}>
                                                                    Add As Administrator
                                                                </Button>
                                                            </Tooltip>

                                                            
                                                        )}

                                                            <Tooltip
                                                            style={{
                                                                color: "white",
                                                                maxWidth: "16rem",
                                                            }}
                                                            placement="bottom"
                                                            content="Assign Varsity">
                                                            <Button
                                                                color="secondary"
                                                                style={{
                                                                    color: "white",
                                                                    marginRight: "10px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                onClick={async () => {
                                                                    try {
                                                                        setSubmitting(true);

                                                                        const a = Array.from(
                                                                            selectedUsers
                                                                        );

                                                                        for (let i = 0; i < a.length; i++) {
                                                                            const u = a[i];

                                                                            await assignGpaCategory({
                                                                                editUserId: u.toString() as string,
                                                                                gpaCategory: "varsity"
                                                                            })
                                                                            
                                                                        }
                                                                    } catch (error: any) {
                                                                        setGeneralError(error.message);
                                                                    } finally {
                                                                        setMode("");
                                                                        setSubmitting(false);
                                                                    }
                                                                }}>
                                                                Assign Varsity
                                                            </Button>
                                                        </Tooltip>

                                                        <Tooltip
                                                            style={{
                                                                color: "white",
                                                                maxWidth: "16rem",
                                                            }}
                                                            placement="bottom"
                                                            content="Assign Scholastic">
                                                            <Button
                                                                color="secondary"
                                                                style={{
                                                                    color: "white",
                                                                    marginRight: "10px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                onClick={async () => {
                                                                    try {
                                                                        setSubmitting(true);

                                                                        const a = Array.from(
                                                                            selectedUsers
                                                                        );

                                                                        for (let i = 0; i < a.length; i++) {
                                                                            const u = a[i];

                                                                            await assignGpaCategory({
                                                                                editUserId: u.toString() as string,
                                                                                gpaCategory: "scholastic"
                                                                            })
                                                                            
                                                                        }
                                                                    } catch (error: any) {
                                                                        setGeneralError(error.message);
                                                                    } finally {
                                                                        setMode("");
                                                                        setSubmitting(false);
                                                                    }
                                                                }}>
                                                                Assign Scholastic
                                                            </Button>
                                                        </Tooltip>

                                                        <Tooltip
                                                            style={{
                                                                color: "white",
                                                                maxWidth: "16rem",
                                                            }}
                                                            placement="bottom"
                                                            content="Assign Honors">
                                                            <Button
                                                                color="secondary"
                                                                style={{
                                                                    color: "white",
                                                                    marginRight: "10px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                onClick={async () => {
                                                                    try {
                                                                        setSubmitting(true);

                                                                        const a = Array.from(
                                                                            selectedUsers
                                                                        );

                                                                        for (let i = 0; i < a.length; i++) {
                                                                            const u = a[i];

                                                                            await assignGpaCategory({
                                                                                editUserId: u.toString() as string,
                                                                                gpaCategory: "honors"
                                                                            })
                                                                            
                                                                        }
                                                                    } catch (error: any) {
                                                                        setGeneralError(error.message);
                                                                    } finally {
                                                                        setMode("");
                                                                        setSubmitting(false);
                                                                    }
                                                                }}>
                                                                Assign Honors
                                                            </Button>
                                                        </Tooltip>
                                                    </div>

                                                    {mode == "sendMessage" && (
                                                        <div>
                                                            <Textarea
                                                                isDisabled={submitting}
                                                                onChange={(e) => {
                                                                    setMessageContent(e.target.value);
                                                                }}
                                                                maxRows={3}
                                                                maxLength={2500}
                                                                style={{ marginTop: "1rem" }}
                                                                placeholder="Enter your message here"
                                                            />
                                                            <Button
                                                                isDisabled={submitting}
                                                                color="success"
                                                                style={{
                                                                    color: "white",
                                                                    marginTop: "1rem",
                                                                }}
                                                                onClick={async () => {
                                                                    try {
                                                                        setSubmitting(true);
                                                                        await orgManagement_sendPrivateMessage({
                                                                            message: messageContent,
                                                                            recipientIds: Array.from(
                                                                                selectedUsers
                                                                            ).map((v) => {
                                                                                return v.toString();
                                                                            }),
                                                                        });
                                                                    } catch (error: any) {
                                                                        setGeneralError(error.message);
                                                                    } finally {
                                                                        setMode("");
                                                                        setSubmitting(false);
                                                                    }
                                                                }}>
                                                                Send
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Tab>
                                                <Tab key={"Disciplinary"} title="Disciplinary">
                                                    <Switch onValueChange={setShouldNotify}>
                                                        <p style={{ color: "white" }}>
                                                            Notify users of administrative action
                                                        </p>
                                                    </Switch>

                                                    <div
                                                        className="flex-grid"
                                                        style={{
                                                            maxWidth: "100%",
                                                            marginTop: "1rem",
                                                        }}>
                                                        <Tooltip
                                                            style={{
                                                                color: "white",
                                                                maxWidth: "16rem",
                                                            }}
                                                            placement="bottom"
                                                            content="Kick a user from your organization (they can join back with your code at any time)">
                                                            <Button
                                                                variant="bordered"
                                                                isLoading={adminActionLoading}
                                                                color="danger"
                                                                style={{
                                                                    color: "white",
                                                                    marginRight: "10px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                onClick={() => {
                                                                    try {
                                                                        setAdminActionLoading(true);

                                                                        Array.from(selectedUsers).forEach(
                                                                            async (e) => {
                                                                                try {
                                                                                    await orgManagement_kickUser({
                                                                                        kickUserId: e.toString(),
                                                                                        notify: shouldNotify,
                                                                                    });
                                                                                    orgUsers.filter((v) => {
                                                                                        return v._id != e.toString();
                                                                                    });
                                                                                    setSelectedUsers(new Set([]));
                                                                                } catch (error: any) {
                                                                                    setGeneralError(error.message);
                                                                                }
                                                                            }
                                                                        );
                                                                    } catch (error: any) {
                                                                        setGeneralError(error.message);
                                                                    } finally {
                                                                        setAdminActionLoading(false);
                                                                    }
                                                                }}>
                                                                Kick
                                                            </Button>
                                                        </Tooltip>

                                                        {!areAllSelectedBanned ? (
                                                            <Tooltip
                                                                style={{
                                                                    color: "white",
                                                                    maxWidth: "16rem",
                                                                }}
                                                                placement="bottom"
                                                                content="Ban a user from your organization (they will be unable to join back as long as they are on the 'ban list')">
                                                                <Button
                                                                    variant="bordered"
                                                                    isLoading={adminActionLoading}
                                                                    color="danger"
                                                                    style={{
                                                                        color: "white",
                                                                        marginRight: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                    onClick={() => {
                                                                        try {
                                                                            setAdminActionLoading(true);

                                                                            Array.from(selectedUsers).forEach(
                                                                                async (e) => {
                                                                                    try {
                                                                                        if (
                                                                                            bannedUsers.filter((v) => {
                                                                                                return v._id != e.toString();
                                                                                            }).length > 0
                                                                                        ) {
                                                                                            return;
                                                                                        }

                                                                                        await orgManagement_banUser({
                                                                                            banUserId: e.toString(),
                                                                                            notify: shouldNotify,
                                                                                        });
                                                                                        setBannedUsers(
                                                                                            bannedUsers.concat(
                                                                                                orgUsers.find((v) => {
                                                                                                    return v._id == e.toString();
                                                                                                })
                                                                                            )
                                                                                        );
                                                                                        setSelectedUsers(new Set([]));
                                                                                    } catch (error: any) {
                                                                                        setGeneralError(error.message);
                                                                                    }
                                                                                }
                                                                            );
                                                                        } catch (error: any) {
                                                                            setGeneralError(error.message);
                                                                        } finally {
                                                                            setAdminActionLoading(false);
                                                                        }
                                                                    }}>
                                                                    Ban
                                                                </Button>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip
                                                                style={{
                                                                    color: "white",
                                                                    maxWidth: "16rem",
                                                                }}
                                                                placement="bottom"
                                                                content="Ban a user from your organization (they will be unable to join back as long as they are on the 'ban list')">
                                                                <Button
                                                                    variant="bordered"
                                                                    isLoading={adminActionLoading}
                                                                    color="success"
                                                                    style={{
                                                                        color: "white",
                                                                        marginRight: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                    onClick={() => {
                                                                        try {
                                                                            setAdminActionLoading(true);

                                                                            Array.from(selectedUsers).forEach(
                                                                                async (e) => {
                                                                                    try {
                                                                                        await orgManagement_unbanUser({
                                                                                            targetUserId: e.toString(),
                                                                                            notify: shouldNotify,
                                                                                        });
                                                                                        setBannedUsers(
                                                                                            bannedUsers.filter((v) => {
                                                                                                return v._id != e.toString();
                                                                                            })
                                                                                        );
                                                                                        setSelectedUsers(selectedUsers);
                                                                                        setAreAllSelectedBanned(false);
                                                                                        setOrgUsers(
                                                                                            orgUsers.filter((v) => {
                                                                                                return v._id != e.toString();
                                                                                            })
                                                                                        );
                                                                                    } catch (error: any) {
                                                                                        setGeneralError(error.message);
                                                                                    }
                                                                                }
                                                                            );
                                                                        } catch (error: any) {
                                                                            setGeneralError(error.message);
                                                                        } finally {
                                                                            setAdminActionLoading(false);
                                                                        }
                                                                    }}>
                                                                    Unban
                                                                </Button>
                                                            </Tooltip>
                                                        )}

                                                        <Tooltip
                                                            style={{
                                                                color: "white",
                                                                maxWidth: "16rem",
                                                            }}
                                                            placement="bottom"
                                                            content="Erase a user-set profile picture and set it back to default (they will still be able to change it at any time)">
                                                            <Button
                                                                isLoading={adminActionLoading}
                                                                color="danger"
                                                                style={{
                                                                    color: "white",
                                                                    marginRight: "10px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                onClick={() => {
                                                                    try {
                                                                        setAdminActionLoading(true);

                                                                        Array.from(selectedUsers).forEach(
                                                                            async (e) => {
                                                                                try {
                                                                                    const res =
                                                                                        await orgManagement_removeProfilePicture(
                                                                                            {
                                                                                                targetUserId: e.toString(),
                                                                                                notify: shouldNotify,
                                                                                            }
                                                                                        );
                                                                                    orgUsers.find((v) => {
                                                                                        return v._id == e.toString();
                                                                                    }).profilePicture =
                                                                                        res.userProfilePicture;
                                                                                } catch (error: any) {
                                                                                    setGeneralError(error.message);
                                                                                }
                                                                            }
                                                                        );
                                                                    } catch (error: any) {
                                                                        setGeneralError(error.message);
                                                                    } finally {
                                                                        setAdminActionLoading(false);
                                                                    }
                                                                }}>
                                                                Remove Profile Picture
                                                            </Button>
                                                        </Tooltip>

                                                        <Tooltip
                                                            style={{
                                                                color: "white",
                                                                maxWidth: "16rem",
                                                            }}
                                                            placement="bottom"
                                                            content="Erase a user's status (they will still be able to change it at any time)">
                                                            <Button
                                                                isLoading={adminActionLoading}
                                                                color="danger"
                                                                style={{
                                                                    color: "white",
                                                                    marginRight: "10px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                onClick={() => {
                                                                    try {
                                                                        setAdminActionLoading(true);

                                                                        Array.from(selectedUsers).forEach(
                                                                            async (e) => {
                                                                                try {
                                                                                    await orgManagement_removeStatus({
                                                                                        targetUserId: e.toString(),
                                                                                        notify: shouldNotify,
                                                                                    });
                                                                                } catch (error: any) {
                                                                                    setGeneralError(error.message);
                                                                                }
                                                                            }
                                                                        );
                                                                    } catch (error: any) {
                                                                        setGeneralError(error.message);
                                                                    } finally {
                                                                        setAdminActionLoading(false);
                                                                    }
                                                                }}>
                                                                Remove Status
                                                            </Button>
                                                        </Tooltip>

                                                        <Tooltip
                                                            style={{
                                                                color: "white",
                                                                maxWidth: "16rem",
                                                            }}
                                                            placement="bottom"
                                                            delay={1000}
                                                            content="Erase a user's bio from their profile (they will still be able to change it at any time)">
                                                            <Button
                                                                isLoading={adminActionLoading}
                                                                color="danger"
                                                                style={{
                                                                    color: "white",
                                                                    marginRight: "10px",
                                                                    marginBottom: "10px",
                                                                }}
                                                                onClick={() => {
                                                                    try {
                                                                        setAdminActionLoading(true);

                                                                        Array.from(selectedUsers).forEach(
                                                                            async (e) => {
                                                                                try {
                                                                                    await orgManagement_removeBio({
                                                                                        targetUserId: e.toString(),
                                                                                        notify: shouldNotify,
                                                                                    });
                                                                                } catch (error: any) {
                                                                                    setGeneralError(error.message);
                                                                                }
                                                                            }
                                                                        );
                                                                    } catch (error: any) {
                                                                        setGeneralError(error.message);
                                                                    } finally {
                                                                        setAdminActionLoading(false);
                                                                    }
                                                                }}>
                                                                Remove Bio
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                </Tab>
                                                <Tab key={"Join Requests"} title="Join Requests">
                                                    <Listbox
                                                        style={{
                                                            maxHeight: "170px",
                                                            overflow: "auto",
                                                        }}
                                                        emptyContent={
                                                            "No Join Requests Yet! If you are expecting any, try refreshing."
                                                        }>
                                                        {joinRequests &&
                                                            joinRequests.map((u) => {
                                                                return (
                                                                    <ListboxItem key={u._id}>
                                                                        <InteractiveProfile
                                                                            content={
                                                                                <div>
                                                                                    {u.profilePicture !== "" && (
                                                                                        <img
                                                                                            src={u.profilePicture}
                                                                                            style={{
                                                                                                width: "40px",
                                                                                                height: "40px",
                                                                                                position: "absolute",
                                                                                                zIndex: 1,
                                                                                                borderRadius: "50px",
                                                                                            }}
                                                                                            alt=""
                                                                                        />
                                                                                    )}
                                                                                    <UiUser
                                                                                        style={{
                                                                                            color: "white",
                                                                                        }}
                                                                                        name={u.username}
                                                                                        description={u.status}
                                                                                        avatarProps={{
                                                                                            alt: u.username,
                                                                                            showFallback:
                                                                                                u.profilePicture === "",
                                                                                            ignoreFallback: false,
                                                                                            getInitials: (name) => {
                                                                                                return name.split(" ")[0][0];
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                    <div
                                                                                        style={{
                                                                                            bottom: "7px",
                                                                                        }}>
                                                                                        <Button
                                                                                            style={{
                                                                                                color: "white",
                                                                                                marginRight: "10px",
                                                                                            }}
                                                                                            color="success"
                                                                                            size="sm"
                                                                                            onClick={async () => {
                                                                                                try {
                                                                                                    await orgManagement_acceptJoinRequest(
                                                                                                        {
                                                                                                            targetUserId: u._id,
                                                                                                            notify: shouldNotify,
                                                                                                        }
                                                                                                    );

                                                                                                    setJoinRequests(
                                                                                                        joinRequests.filter((v) => {
                                                                                                            return v._id != u._id;
                                                                                                        })
                                                                                                    );
                                                                                                } catch (error: any) {
                                                                                                    setGeneralError(
                                                                                                        error.message
                                                                                                    );
                                                                                                }
                                                                                            }}>
                                                                                            Accept
                                                                                        </Button>
                                                                                        <Button
                                                                                            color="danger"
                                                                                            size="sm"
                                                                                            onClick={async () => {
                                                                                                try {
                                                                                                    await orgManagement_dismissJoinRequest(
                                                                                                        {
                                                                                                            targetUserId: u._id,
                                                                                                            notify: shouldNotify,
                                                                                                        }
                                                                                                    );

                                                                                                    setJoinRequests(
                                                                                                        joinRequests.filter((v) => {
                                                                                                            return v._id != u._id;
                                                                                                        })
                                                                                                    );
                                                                                                } catch (error: any) {
                                                                                                    setGeneralError(
                                                                                                        error.message
                                                                                                    );
                                                                                                }
                                                                                            }}>
                                                                                            Decline
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            loggedInUser={loggedInUser}
                                                                            user={u}
                                                                            contextContent={
                                                                                <div
                                                                                    style={{
                                                                                        bottom: "7px",
                                                                                    }}>
                                                                                    <Button
                                                                                        style={{
                                                                                            color: "white",
                                                                                            marginRight: "10px",
                                                                                        }}
                                                                                        color="success"
                                                                                        size="sm"
                                                                                        onClick={async () => {
                                                                                            try {
                                                                                                await orgManagement_acceptJoinRequest(
                                                                                                    {
                                                                                                        targetUserId: u._id,
                                                                                                        notify: shouldNotify,
                                                                                                    }
                                                                                                );

                                                                                                setJoinRequests(
                                                                                                    joinRequests.filter((v) => {
                                                                                                        return v._id != u._id;
                                                                                                    })
                                                                                                );
                                                                                            } catch (error: any) {
                                                                                                setGeneralError(error.message);
                                                                                            }
                                                                                        }}>
                                                                                        Accept
                                                                                    </Button>
                                                                                    <Button
                                                                                        color="danger"
                                                                                        size="sm"
                                                                                        onClick={async () => {
                                                                                            try {
                                                                                                await orgManagement_dismissJoinRequest(
                                                                                                    {
                                                                                                        targetUserId: u._id,
                                                                                                        notify: shouldNotify,
                                                                                                    }
                                                                                                );

                                                                                                setJoinRequests(
                                                                                                    joinRequests.filter((v) => {
                                                                                                        return v._id != u._id;
                                                                                                    })
                                                                                                );
                                                                                            } catch (error: any) {
                                                                                                setGeneralError(error.message);
                                                                                            }
                                                                                        }}>
                                                                                        Decline
                                                                                    </Button>
                                                                                </div>
                                                                            }
                                                                            friendIndicatorPosition={undefined}
                                                                            popoverPosition={"right"}
                                                                            withStats={false}
                                                                            isSelf={false}
                                                                        />
                                                                    </ListboxItem>
                                                                );
                                                            })}
                                                    </Listbox>
                                                </Tab>
                                                <Tab key={"Statistics"} title="Statistics">
                                                    <div style={{color:'white'}}>
                                                        <LineChart width={430} height={150} data={data}>
                                                            <Line
                                                                type="monotone"
                                                                dataKey="value"
                                                                stroke="#8884d8"
                                                            />
                                                            <XAxis dataKey="date" direction={-1} />
                                                            <YAxis />
                                                            <ChartTooltip
                                                                contentStyle={{
                                                                    backgroundColor: "#141414",
                                                                    borderRadius: "15px",
                                                                }}
                                                            />
                                                        </LineChart>
                                                    </div>
                                                    <CheckboxGroup
                                                        style={{
                                                            marginTop: "1rem",
                                                            marginBottom: "1rem",
                                                        }}>
                                                        <Checkbox
                                                            onValueChange={setSelectedUsersForGraphOnly}>
                                                            Only Selected Users
                                                        </Checkbox>
                                                    </CheckboxGroup>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            maxWidth: "14rem",
                                                        }}>
                                                        <Select
                                                            defaultSelectedKeys={["All"]}
                                                            isDisabled={graphLoading}
                                                            isLoading={graphLoading}
                                                            size="sm"
                                                            onChange={(v) => {
                                                                if (v.target.value === "All"){
                                                                    setGraphCategory(-1);
                                                                }else{
                                                                    setGraphCategory(
                                                                        categories.indexOf(v.target.value)
                                                                    );
                                                                }
                                                                
                                                            }}
                                                            placeholder="Category"
                                                            style={{
                                                                color: "white",
                                                                width: "10rem",
                                                                marginRight: "1rem",
                                                            }}>
                                                            <SelectItem
                                                                value={"All"}
                                                                style={{ color: "white" }}
                                                                key={"All"}>
                                                                All
                                                            </SelectItem>
                                                            <SelectItem
                                                                value={"Social Science"}
                                                                style={{ color: "white" }}
                                                                key={"Social Science"}>
                                                                Social Science
                                                            </SelectItem>
                                                            <SelectItem
                                                                value={"Science"}
                                                                style={{ color: "white" }}
                                                                key={"Science"}>
                                                                Science
                                                            </SelectItem>
                                                            <SelectItem
                                                                value={"Mathematics"}
                                                                style={{ color: "white" }}
                                                                key={"Mathematics"}>
                                                                Mathematics
                                                            </SelectItem>
                                                            <SelectItem
                                                                value={"Literature"}
                                                                style={{ color: "white" }}
                                                                key={"Literature"}>
                                                                Literature
                                                            </SelectItem>
                                                            <SelectItem
                                                                value={"Economics"}
                                                                style={{ color: "white" }}
                                                                key={"Economics"}>
                                                                Economics
                                                            </SelectItem>
                                                            <SelectItem
                                                                value={"Art"}
                                                                style={{ color: "white" }}
                                                                key={"Art"}>
                                                                Art
                                                            </SelectItem>
                                                            <SelectItem
                                                                value={"Music"}
                                                                style={{ color: "white" }}
                                                                key={"Music"}>
                                                                Music
                                                            </SelectItem>
                                                        </Select>
                                                        <Select
                                                            defaultSelectedKeys={["Answered"]}
                                                            isDisabled={graphLoading}
                                                            isLoading={graphLoading}
                                                            size="sm"
                                                            onChange={(v) => {
                                                                setGraphType(types.indexOf(v.target.value));
                                                            }}
                                                            placeholder="Metric"
                                                            style={{
                                                                color: "white",
                                                                width: "10rem",
                                                                marginRight: "1rem",
                                                            }}>
                                                            <SelectItem
                                                                value={"Answered"}
                                                                style={{ color: "white" }}
                                                                key={"Answered"}>
                                                                Answered
                                                            </SelectItem>
                                                            <SelectItem
                                                                value={"Correct"}
                                                                style={{ color: "white" }}
                                                                key={"Correct"}>
                                                                Correct
                                                            </SelectItem>
                                                        </Select>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            maxWidth: "14rem",
                                                        }}>
                                                        <Select
                                                            defaultSelectedKeys={["Day"]}
                                                            isDisabled={graphLoading}
                                                            isLoading={graphLoading}
                                                            size="sm"
                                                            onChange={(v) => {
                                                                setGraphTimeFrame(
                                                                    timeFrames.indexOf(v.target.value)
                                                                );
                                                            }}
                                                            placeholder="Time Frame"
                                                            style={{
                                                                color: "white",
                                                                width: "10rem",
                                                                marginRight: "1rem",
                                                            }}>
                                                            <SelectItem
                                                                value={"Day"}
                                                                style={{ color: "white" }}
                                                                key={"Day"}>
                                                                Over Days
                                                            </SelectItem>
                                                            <SelectItem
                                                                value={"Week"}
                                                                style={{ color: "white" }}
                                                                key={"Week"}>
                                                                Over Weeks
                                                            </SelectItem>
                                                            <SelectItem
                                                                value={"Month"}
                                                                style={{ color: "white" }}
                                                                key={"Month"}>
                                                                Over Months
                                                            </SelectItem>
                                                        </Select>
                                                        <Button
                                                            isDisabled={graphLoading}
                                                            isLoading={graphLoading}
                                                            onClick={getStats}
                                                            isIconOnly
                                                            style={{
                                                                marginRight: "1rem",
                                                                marginTop: "4px",
                                                            }}>
                                                            <RefreshIcon />
                                                        </Button>
                                                    </div>
                                                </Tab>

                                                <Tab key={"Calendar"} title="Calendar">
                                                    <Tabs>
                                                        <Tab key={"Calendar"} title="Calendar">
                                                            <Calendar
                                                                defaultValue={
                                                                    new CalendarDateTime(
                                                                        new Date().getFullYear(),
                                                                        new Date().getMonth(),
                                                                        new Date().getDate()
                                                                    )
                                                                }
                                                                value={
                                                                    new CalendarDateTime(
                                                                        selectedDate.year,
                                                                        selectedDate.month,
                                                                        selectedDate.day
                                                                    )
                                                                }
                                                                onChange={(d) => {
                                                                    if (selectedDate) {
                                                                        setSelectedDate(
                                                                            selectedDate.set({
                                                                                day: d.day,
                                                                                month: d.month,
                                                                                year: d.year,
                                                                            })
                                                                        );
                                                                    }
                                                                }}
                                                                color="foreground"
                                                                calendarWidth={"20rem"}
                                                            />
                                                        </Tab>
                                                        <Tab key={"Events"} title="Events">
                                                            <DatePicker
                                                                defaultValue={now(getLocalTimeZone())}
                                                                isInvalid={dateError !== ""}
                                                                errorMessage={dateError}
                                                                value={selectedDate}
                                                                hideTimeZone
                                                                showMonthAndYearPickers
                                                                onChange={(d) => {
                                                                    setSelectedDate(d);
                                                                    setDateError("");
                                                                }}
                                                                calendarProps={{
                                                                    color: "foreground",
                                                                }}
                                                                style={{ marginBottom: "10px" }}
                                                                isRequired
                                                            />
                                                            <div
                                                                className="flex-grid"
                                                                style={{
                                                                    maxWidth: "100%",
                                                                    marginTop: "1rem",
                                                                }}>
                                                                <Tooltip
                                                                    style={{
                                                                        color: "white",
                                                                        maxWidth: "16rem",
                                                                    }}
                                                                    placement="bottom"
                                                                    content="Create a new event on the selected date and time">
                                                                    <Button
                                                                        color="secondary"
                                                                        style={{
                                                                            color: "white",
                                                                            marginRight: "10px",
                                                                            marginBottom: "10px",
                                                                        }}
                                                                        onClick={() => {
                                                                            if (mode !== "createEvent") {
                                                                                setMode("createEvent");
                                                                            } else {
                                                                                setMode("");
                                                                            }
                                                                        }}>
                                                                        Create Event
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip
                                                                    style={{
                                                                        color: "white",
                                                                        maxWidth: "16rem",
                                                                    }}
                                                                    placement="bottom"
                                                                    content="Edit events on this date">
                                                                    <Button
                                                                        color="secondary"
                                                                        style={{
                                                                            color: "white",
                                                                            marginRight: "10px",
                                                                            marginBottom: "10px",
                                                                        }}
                                                                        onClick={() => {
                                                                            if (mode !== "editEvents") {
                                                                                setMode("editEvents");
                                                                            } else {
                                                                                setMode("");
                                                                            }
                                                                        }}>
                                                                        Edit Events
                                                                    </Button>
                                                                </Tooltip>
                                                            </div>

                                                            {mode == "createEvent" && (
                                                                <>
                                                                    <CheckboxGroup
                                                                        orientation="horizontal"
                                                                        style={{
                                                                            marginBottom: "10px",
                                                                            marginTop: "10px",
                                                                        }}>
                                                                        <Input
                                                                            size="sm"
                                                                            minLength={1}
                                                                            maxLength={45}
                                                                            isInvalid={eventNameError !== ""}
                                                                            errorMessage={eventNameError}
                                                                            onValueChange={(s) => {
                                                                                setEventName(s);
                                                                                setEventNameError("");
                                                                            }}
                                                                            label="Event Name"
                                                                            required={true}
                                                                        />
                                                                        <Textarea
                                                                            maxLength={4000}
                                                                            onValueChange={setEventDescription}
                                                                            label="Event Description"
                                                                            maxRows={2}
                                                                        />
                                                                        <Switch
                                                                            onValueChange={
                                                                                setSelectedUsersForEventOnly
                                                                            }
                                                                            size="sm"
                                                                            color="secondary">
                                                                            Only For Selected Users
                                                                        </Switch>

                                                                        <Tooltip
                                                                            style={{
                                                                                color: "white",
                                                                                maxWidth: "16rem",
                                                                            }}
                                                                            placement="bottom"
                                                                            content="This event will be automatically entered into the schedule on the same day at the same time for the proceeding 3 weeks">
                                                                            <div>
                                                                                <Switch
                                                                                    onValueChange={setRecurringEvent}
                                                                                    size="sm"
                                                                                    color="secondary">
                                                                                    Recurring Event
                                                                                </Switch>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </CheckboxGroup>
                                                                    <Button
                                                                        color="success"
                                                                        style={{ color: "white" }}
                                                                        onClick={async () => {
                                                                            try {
                                                                                if (!selectedDate) {
                                                                                    setDateError(
                                                                                        "Please select valid date"
                                                                                    );
                                                                                    return;
                                                                                } else {
                                                                                    setSubmitting(true);
                                                                                    if (eventName.trim().length == 0) {
                                                                                        setEventNameError(
                                                                                            "Name must not be empty"
                                                                                        );
                                                                                        return;
                                                                                    } else if (
                                                                                        selectedUsersForEventOnly &&
                                                                                        Array.from(selectedUsers).length ==
                                                                                        0
                                                                                    ) {
                                                                                        setGeneralError(
                                                                                            "Must select users for event to be created exclusively for them"
                                                                                        );
                                                                                        return;
                                                                                    }

                                                                                    await orgManagement_createOrgEvent({
                                                                                        dateTime: selectedDate.toDate(),
                                                                                        eventDescription: eventDescription,
                                                                                        eventName: eventName.trim(),
                                                                                        users: selectedUsersForEventOnly
                                                                                            ? Array.from(selectedUsers).map(
                                                                                                (v: any) => {
                                                                                                    return v as string;
                                                                                                }
                                                                                            )
                                                                                            : [],
                                                                                    });

                                                                                    setCurrentEvents(
                                                                                        await getOrganizationEvents()
                                                                                    );

                                                                                    setMode("");
                                                                                }
                                                                            } catch (error: any) {
                                                                                setGeneralError(error.message);
                                                                            } finally {
                                                                                setSubmitting(false);
                                                                            }
                                                                        }}>
                                                                        Create
                                                                    </Button>
                                                                </>
                                                            )}
                                                            {mode == "editEvents" && (
                                                                <>
                                                                    <Listbox
                                                                        style={{
                                                                            maxHeight: "100px",
                                                                            overflow: "auto",
                                                                        }}>
                                                                        {currentEvents.length > 0 ? (
                                                                            currentEvents.map((v) => {
                                                                                return new Date(
                                                                                    v.dateTime
                                                                                ).getUTCDate() ===
                                                                                    selectedDate.toDate().getUTCDate() &&
                                                                                    new Date(v.dateTime).getUTCMonth() ===
                                                                                    selectedDate
                                                                                        .toDate()
                                                                                        .getUTCMonth() &&
                                                                                    new Date(
                                                                                        v.dateTime
                                                                                    ).getUTCFullYear() ===
                                                                                    selectedDate
                                                                                        .toDate()
                                                                                        .getUTCFullYear() ? (
                                                                                    <ListboxItem
                                                                                        key={v._id}
                                                                                        onClick={() => {
                                                                                            setSelectedEditEvent(v);
                                                                                            setEditEventDescription(
                                                                                                v.eventDescription
                                                                                            );
                                                                                            setEditEventName(v.eventName);
                                                                                            setEditEventIsExclusive(
                                                                                                v.users.filter((r: any) => {
                                                                                                    return r != null;
                                                                                                }).length > 0
                                                                                            );
                                                                                            setUpdateWithSelectedUsers(false);
                                                                                            setSelectedUsers(
                                                                                                new Set(v.users)
                                                                                            );
                                                                                        }}>
                                                                                        <p
                                                                                            style={{
                                                                                                color: "white",
                                                                                                fontSize: 20,
                                                                                                fontWeight: 600,
                                                                                            }}>
                                                                                            {v.eventName} -{" "}
                                                                                            {new Date(
                                                                                                v.dateTime
                                                                                            ).toLocaleTimeString()}
                                                                                        </p>
                                                                                        <p
                                                                                            style={{
                                                                                                color: "white",
                                                                                                fontSize: 13,
                                                                                            }}>
                                                                                            {v.eventDescription}
                                                                                        </p>
                                                                                        {v.users.length > 0 && (
                                                                                            <AvatarGroup>
                                                                                                {v.users.map(
                                                                                                    (c: any, i: number) => {
                                                                                                        const f = orgUsers.find(
                                                                                                            (d) => {
                                                                                                                return d._id == c;
                                                                                                            }
                                                                                                        );

                                                                                                        if (i >= 3) {
                                                                                                            if (i == 3) {
                                                                                                                return (
                                                                                                                    <p
                                                                                                                        style={{
                                                                                                                            color: "white",
                                                                                                                            marginLeft:
                                                                                                                                "10px",
                                                                                                                        }}>
                                                                                                                        +
                                                                                                                        {v.users.length - 3}{" "}
                                                                                                                        More
                                                                                                                    </p>
                                                                                                                );
                                                                                                            }
                                                                                                        }

                                                                                                        if (c && i < 3) {
                                                                                                            return (
                                                                                                                f.profilePicture !==
                                                                                                                "" && (
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            f.profilePicture
                                                                                                                        }
                                                                                                                        style={{
                                                                                                                            left: i * 30,
                                                                                                                            width: "40px",
                                                                                                                            height: "40px",
                                                                                                                            zIndex: 1,
                                                                                                                            borderRadius:
                                                                                                                                "50px",
                                                                                                                        }}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                )
                                                                                                            );
                                                                                                        }
                                                                                                    }
                                                                                                )}
                                                                                            </AvatarGroup>
                                                                                        )}
                                                                                        <Divider className="mt-2 mb-2" />
                                                                                    </ListboxItem>
                                                                                ) : (
                                                                                    <ListboxItem
                                                                                        hidden={true}
                                                                                        key={v._id}
                                                                                    />
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <ListboxItem hidden={true} key={"N"} />
                                                                        )}
                                                                    </Listbox>
                                                                    <CheckboxGroup
                                                                        orientation="horizontal"
                                                                        style={{
                                                                            marginBottom: "10px",
                                                                            marginTop: "10px",
                                                                        }}>
                                                                        {selectedEditEvent && (
                                                                            <>
                                                                                <Input
                                                                                    size="sm"
                                                                                    minLength={1}
                                                                                    maxLength={45}
                                                                                    value={editEventName}
                                                                                    isInvalid={eventNameError !== ""}
                                                                                    errorMessage={eventNameError}
                                                                                    onValueChange={(s) => {
                                                                                        setEditEventName(s);
                                                                                        setEventNameError("");
                                                                                    }}
                                                                                    label="Event Name"
                                                                                    required={true}
                                                                                />
                                                                                <Textarea
                                                                                    maxLength={4000}
                                                                                    value={editEventDescription}
                                                                                    onValueChange={
                                                                                        setEditEventDescription
                                                                                    }
                                                                                    label="Event Description"
                                                                                    maxRows={2}
                                                                                />
                                                                                <Switch
                                                                                    isSelected={editEventIsExclusive}
                                                                                    onValueChange={
                                                                                        setEditEventIsExclusive
                                                                                    }
                                                                                    size="sm"
                                                                                    color="secondary">
                                                                                    Only For Selected Users
                                                                                </Switch>
                                                                                <Switch
                                                                                    isSelected={updateWithSelectedUsers}
                                                                                    onValueChange={
                                                                                        setUpdateWithSelectedUsers
                                                                                    }
                                                                                    size="sm"
                                                                                    color="secondary">
                                                                                    Update With Selected Users
                                                                                </Switch>
                                                                            </>
                                                                        )}
                                                                    </CheckboxGroup>
                                                                    {selectedEditEvent && (
                                                                        <>
                                                                            <Button
                                                                                isLoading={submitting}
                                                                                color="success"
                                                                                style={{
                                                                                    color: "white",
                                                                                }}
                                                                                onClick={async () => {
                                                                                    try {
                                                                                        if (!selectedDate) {
                                                                                            setDateError(
                                                                                                "Please select valid date"
                                                                                            );
                                                                                            return;
                                                                                        } else {
                                                                                            setSubmitting(true);
                                                                                            if (
                                                                                                editEventName.trim().length == 0
                                                                                            ) {
                                                                                                setEventNameError(
                                                                                                    "Name must not be empty"
                                                                                                );
                                                                                                return;
                                                                                            } else if (
                                                                                                editEventIsExclusive &&
                                                                                                updateWithSelectedUsers &&
                                                                                                Array.from(selectedUsers)
                                                                                                    .length == 0
                                                                                            ) {
                                                                                                setGeneralError(
                                                                                                    "Must select users for event to be created exclusively for them"
                                                                                                );
                                                                                                return;
                                                                                            }

                                                                                            await orgManagement_updateOrgEvent(
                                                                                                {
                                                                                                    dateTime:
                                                                                                        selectedDate.toDate(),
                                                                                                    eventId:
                                                                                                        selectedEditEvent._id,
                                                                                                    eventDescription:
                                                                                                        editEventDescription,
                                                                                                    eventName:
                                                                                                        editEventName.trim(),
                                                                                                    users: editEventIsExclusive
                                                                                                        ? updateWithSelectedUsers
                                                                                                            ? Array.from(
                                                                                                                selectedUsers
                                                                                                            ).map((v: any) => {
                                                                                                                return v;
                                                                                                            })
                                                                                                            : selectedEditEvent.users
                                                                                                        : [],
                                                                                                }
                                                                                            );

                                                                                            const fIndex =
                                                                                                currentEvents.findIndex((f) => {
                                                                                                    return (
                                                                                                        f._id ==
                                                                                                        selectedEditEvent._id
                                                                                                    );
                                                                                                });

                                                                                            currentEvents[fIndex].dateTime =
                                                                                                selectedDate.toDate();
                                                                                            currentEvents[
                                                                                                fIndex
                                                                                            ].eventDescription =
                                                                                                editEventDescription;
                                                                                            currentEvents[fIndex].eventName =
                                                                                                editEventName.trim();
                                                                                            currentEvents[fIndex].users =
                                                                                                editEventIsExclusive
                                                                                                    ? updateWithSelectedUsers
                                                                                                        ? Array.from(
                                                                                                            selectedUsers
                                                                                                        ).map((v: any) => {
                                                                                                            return v;
                                                                                                        })
                                                                                                        : selectedEditEvent.users
                                                                                                    : [];

                                                                                            setSubmitting(false);
                                                                                            setMode("");
                                                                                        }
                                                                                    } catch (error: any) {
                                                                                        setGeneralError(error.message);
                                                                                    } finally {
                                                                                        setSubmitting(false);
                                                                                    }
                                                                                }}>
                                                                                Save Changes
                                                                            </Button>

                                                                            <Button
                                                                                isLoading={submitting}
                                                                                color="danger"
                                                                                style={{
                                                                                    color: "white",
                                                                                    right: "-100px",
                                                                                }}
                                                                                onClick={async () => {
                                                                                    try {
                                                                                        if (!selectedDate) {
                                                                                            setDateError(
                                                                                                "Please select valid date"
                                                                                            );
                                                                                            return;
                                                                                        } else {
                                                                                            setSubmitting(true);

                                                                                            await orgManagement_deleteOrgEvent(
                                                                                                {
                                                                                                    eventId:
                                                                                                        selectedEditEvent._id,
                                                                                                }
                                                                                            );

                                                                                            setCurrentEvents(
                                                                                                currentEvents.filter((v) => {
                                                                                                    return (
                                                                                                        v._id !==
                                                                                                        selectedEditEvent._id
                                                                                                    );
                                                                                                })
                                                                                            );
                                                                                            setSelectedEditEvent(undefined);
                                                                                            setSelectedUsers(new Set([]));

                                                                                            setMode("");
                                                                                        }
                                                                                    } catch (error: any) {
                                                                                        setGeneralError(error.message);
                                                                                    } finally {
                                                                                        setSubmitting(false);
                                                                                    }
                                                                                }}>
                                                                                Delete Event
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Tab>
                                                    </Tabs>
                                                </Tab>
                                                <Tab key={"Inspect User"} title="Inspect User">
                                                    <Chip
                                                        className="mb-2"
                                                        style={{
                                                            backgroundColor: "#9000B3",
                                                        }}>
                                                        Experimental
                                                    </Chip>
                                                    {Array.from(selectedUsers).length === 0 && <p style={{color:'lightgray'}}><i>No users selected, select users to inspect</i></p>}
                                                    <div style={{ display: "flex" }}>
                                                        <div
                                                            style={{
                                                                display: "grid",
                                                                padding: "10px",
                                                                width: "300px",
                                                                marginRight: "5px",
                                                            }}>
                                                            
                                                            {Array.from(selectedUsers).length > 0 &&
                                                                Array.from(selectedUsers)
                                                                    .slice(0, 6)
                                                                    .map((g) => {
                                                                        const u = orgUsers.find((x) => {
                                                                            return x._id === g;
                                                                        });
                                                                        return (
                                                                            <InteractiveProfile
                                                                                loggedInUser={loggedInUser}
                                                                                content={
                                                                                    <div>
                                                                                        {u.profilePicture !== "" && (
                                                                                            <img
                                                                                                src={u.profilePicture}
                                                                                                style={{
                                                                                                    width: "40px",
                                                                                                    height: "40px",
                                                                                                    position: "absolute",
                                                                                                    zIndex: 1,
                                                                                                    borderRadius: "50px",
                                                                                                }}
                                                                                                alt=""
                                                                                            />
                                                                                        )}
                                                                                        <UiUser
                                                                                            style={{
                                                                                                color: "white",
                                                                                            }}
                                                                                            name={u.username}
                                                                                            description={u.status}
                                                                                            avatarProps={{
                                                                                                alt: u.username,
                                                                                                showFallback:
                                                                                                    u.profilePicture === "",
                                                                                                ignoreFallback: false,
                                                                                                getInitials: (name) => {
                                                                                                    return name.split(" ")[0][0];
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                                friendIndicatorPosition={
                                                                                    loggedInUser &&
                                                                                        loggedInUser?.friends.includes(u._id)
                                                                                        ? "top-right"
                                                                                        : undefined
                                                                                }
                                                                                withStats={true}
                                                                                contextContent={
                                                                                    <Button
                                                                                        onClick={() => {
                                                                                            grabTestHistory(u._id);
                                                                                        }}>
                                                                                        View Previous Tests
                                                                                    </Button>
                                                                                }
                                                                                popoverPosition={"right"}
                                                                                isSelf={false}
                                                                                user={u}
                                                                            />
                                                                        );
                                                                    })}
                                                        </div>
                                                        <Listbox>
                                                            {testRecords.length > 0 ? (
                                                                testRecords.map((v, i) => {
                                                                    return (
                                                                        <ListboxItem
                                                                            key={v._id}
                                                                            style={{
                                                                                width: "11rem",
                                                                                marginLeft: "-2rem",
                                                                            }}
                                                                            onClick={() => {
                                                                                setReviewQuestion(0);
                                                                                setSelectedRecord(testRecords[i]);
                                                                                setSelectedRecordQuestions(
                                                                                    testRecordQuestions[i]
                                                                                );
                                                                            }}>
                                                                            <Card
                                                                                style={{
                                                                                    width: "10rem",
                                                                                    height: "6rem",
                                                                                }}>
                                                                                <CardHeader
                                                                                    style={{
                                                                                        display: "block",
                                                                                    }}>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                        }}>
                                                                                        <p
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                fontWeight: 600,
                                                                                                marginBottom: "0.7rem",
                                                                                                marginRight: "0.7rem",
                                                                                            }}>
                                                                                            {new Date(
                                                                                                v.dateCreated
                                                                                            ).getMonth() + 1}{" "}
                                                                                            /{" "}
                                                                                            {new Date(
                                                                                                v.dateCreated
                                                                                            ).getDate()}{" "}
                                                                                            {grabCompetitiveIdentifier(
                                                                                                v.categories[0]
                                                                                            ).replace(
                                                                                                "SocialScience",
                                                                                                "Social Science"
                                                                                            )}{" "}
                                                                                            Test
                                                                                        </p>
                                                                                        {getCategoryIcon(v.categories[0])}
                                                                                    </div>
                                                                                    <p>
                                                                                        Scored {v.correctAmt}/{v.answered}
                                                                                    </p>
                                                                                </CardHeader>
                                                                                <CardBody>
                                                                                    {(v.liveCorrections ||
                                                                                        v.competitiveMode ||
                                                                                        v.pageNumbers) && (
                                                                                            <>
                                                                                                <p>Modifiers:</p>
                                                                                                {v.liveCorrections && (
                                                                                                    <p>Live Corrections</p>
                                                                                                )}
                                                                                                {v.pageNumbers && (
                                                                                                    <p>Page Numbers Visible</p>
                                                                                                )}
                                                                                                {v.competitiveMode && (
                                                                                                    <p>Competitive Mode</p>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                </CardBody>
                                                                            </Card>
                                                                        </ListboxItem>
                                                                    );
                                                                })
                                                            ) : (
                                                                <ListboxItem key={"A"}></ListboxItem>
                                                            )}
                                                        </Listbox>
                                                    </div>
                                                </Tab>
                                                <Tab isDisabled={false} key={"Practice Tests"} title="Practice Tests">
                                                    <Popover>
                                                        <PopoverTrigger>
                                                            <Chip
                                                                className="mb-2"
                                                                style={{
                                                                    backgroundColor: "#9000B3",
                                                                }}>
                                                                Experimental
                                                            </Chip>
                                                        </PopoverTrigger>
                                                        <PopoverContent>
                                                            <p style={{color:'white', maxWidth:'150px'}}>Feature is in experimental state, unexpected issues may occur.</p>
                                                        </PopoverContent>
                                                    </Popover>
                                                    <p style={{ color: "white" }}>
                                                        Allow your entire organization to take the same test
                                                        and compare results
                                                    </p>
                                                    <Divider className="mb-2 mt-2" />
                                                    <div className="gap-2" style={{ display: "flex" }}>
                                                        <Button
                                                            onClick={() => {
                                                                setMode(mode === "generatePracticeTest" ? "" : "generatePracticeTest");
                                                            }}>
                                                            Generate Test
                                                        </Button>
                                                        <Button onClick={() => {
                                                                setMode(mode === "viewPracticeTests" ? "" : "viewPracticeTests");
                                                            }}>View Tests</Button>
                                                        <Button onClick={() => {
                                                                setMode(mode === "viewPracticeTestResults" ? "" : "viewPracticeTestResults");
                                                            }}>View Results</Button>
                                                    </div>
                                                    {mode === "generatePracticeTest" && (
                                                        <>
                                                            <div
                                                                className="flex w-full flex-wrap md:flex-nowrap gap-4"
                                                                style={{
                                                                    marginTop:'10px',
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}>
                                                                <CheckboxGroup
                                                                    label="Practice Settings"
                                                                    defaultValue={[""]}>
                                                                    <Checkbox
                                                                        value="live-corrections"
                                                                        isReadOnly={competitiveMode}
                                                                        isDisabled={competitiveMode}
                                                                        onValueChange={(selected) => {
                                                                            setliveCorrections(selected);
                                                                        }}>
                                                                        Live Corrections
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        value="page-numbers"
                                                                        isReadOnly={competitiveMode}
                                                                        isDisabled={competitiveMode}
                                                                        onValueChange={(selected) => {
                                                                            setShowPageNum(selected);
                                                                        }}>
                                                                        Page Numbers
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        value="takeable-only-once"
                                                                        isReadOnly={competitiveMode}
                                                                        isDisabled={competitiveMode}
                                                                        onValueChange={(selected) => {
                                                                            setOneResponsePerUserOnly(selected);
                                                                        }}>
                                                                        One response Per User
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        value="reviewable-after-taken"
                                                                        isReadOnly={competitiveMode}
                                                                        isDisabled={competitiveMode}
                                                                        onValueChange={(selected) => {
                                                                            setIsReviewable(selected);
                                                                        }}>
                                                                        Reviewable
                                                                    </Checkbox>
                                                                </CheckboxGroup>

                                                                <Input
                                                                    type="number"
                                                                    errorMessage={
                                                                        questionCountError !== "" &&
                                                                        questionCountError
                                                                    }
                                                                    label="Question Count"
                                                                    isReadOnly={competitiveMode}
                                                                    isDisabled={competitiveMode}
                                                                    className="w-1/6 text-white"
                                                                    onValueChange={(v) => {
                                                                        setQuestionCount(parseInt(v));
                                                                    }}
                                                                />

                                                                <Select
                                                                    label="Testing Category"
                                                                    placeholder="Select a Category"
                                                                    className="max-w-xs"
                                                                    errorMessage={
                                                                        generalError !== "" && generalError
                                                                    }
                                                                    selectedKeys={
                                                                        categories[category] !== ""
                                                                            ? [categories[category]]
                                                                            : []
                                                                    }
                                                                    disabledKeys={[
                                                                
                                                                    ]}
                                                                    defaultSelectedKeys={["science"]}
                                                                    onChange={(e) => {

                                                                        setCategory(
                                                                            categories.indexOf(e.target.value)
                                                                        );
                                                                        setGeneralError("");
                                                                    }}>
                                                                    <SelectItem
                                                                        value={0}
                                                                        key={"socialScience"}
                                                                        style={{
                                                                            color: "white",
                                                                        }}>
                                                                        Social Science
                                                                    </SelectItem>
                                                                    <SelectItem
                                                                        value={1}
                                                                        key={"science"}
                                                                        style={{
                                                                            color: "white",
                                                                        }}>
                                                                        Science
                                                                    </SelectItem>
                                                                    <SelectItem
                                                                        value={2}
                                                                        key={"math"}
                                                                        style={{
                                                                            color: "white",
                                                                        }}>
                                                                        Math
                                                                    </SelectItem>
                                                                    <SelectItem
                                                                        value={3}
                                                                        key={"literature"}
                                                                        style={{
                                                                            color: "white",
                                                                        }}>
                                                                        Literature
                                                                    </SelectItem>
                                                                    <SelectItem
                                                                        value={4}
                                                                        key={"economics"}
                                                                        style={{
                                                                            color: "white",
                                                                        }}>
                                                                        Economics
                                                                    </SelectItem>
                                                                    <SelectItem
                                                                        value={5}
                                                                        key={"art"}
                                                                        style={{
                                                                            color: "white",
                                                                        }}>
                                                                        Art
                                                                    </SelectItem>
                                                                    <SelectItem
                                                                        value={6}
                                                                        key={"music"}
                                                                        style={{
                                                                            color: "white",
                                                                        }}>
                                                                        Music
                                                                    </SelectItem>
                                                                </Select>
                                                                <div style={{display:'block'}}>
                                                                <p style={{color:'white', margin:'0px'}}>Sections Enabled</p>
                                                                {sectionsError !== "" && <p color="danger">{sectionsError}</p>}
                                                                {categoryCount[category] > 0 && <>
                                                                { category !== 4 ?
                                                                    <Checkbox color="secondary" isDisabled={competitiveMode || category === 3} isSelected={sectionOneEnabled} onValueChange={setSectionOneEnabled} style={{color:'white', margin:'2px'}}>{1}</Checkbox>
                                                                :
                                                                    <Checkbox color="secondary" isDisabled={competitiveMode} isSelected={sectionOneEnabled} onValueChange={setSectionOneEnabled} style={{color:'white', margin:'2px'}}>1 & 2</Checkbox>
                                                                
                                                                }
                                                                {category !== 4 && <Checkbox color="secondary" isDisabled={competitiveMode} onValueChange={setSectionTwoEnabled} isSelected={sectionTwoEnabled} style={{color:'white', margin:'2px'}}>{2}</Checkbox>}
                                                                    <Checkbox color="secondary" isDisabled={competitiveMode} onValueChange={setSectionThreeEnabled} isSelected={sectionThreeEnabled} style={{color:'white', margin:'2px'}}>{3}</Checkbox>
                                                                    <Checkbox color="secondary" isDisabled={competitiveMode} onValueChange={setSectionFourEnabled} isSelected={sectionFourEnabled} style={{color:'white', margin:'2px'}}>{4}</Checkbox>
                                                                    {categoryCount[category] === 5 && <Checkbox color="secondary" isDisabled={competitiveMode} isSelected={sectionFiveEnabled} onValueChange={setSectionFiveEnabled} style={{color:'white', margin:'2px'}}>{5}</Checkbox>}
                                                                    </>}
                                                            </div>

                                                                <div
                                                                    className="flex w-full flex-wrap md:flex-nowrap gap-4"
                                                                    style={{
                                                                        justifyContent: "center",
                                                                        display: "flex",
                                                                    }}>
                                                                    <Button
                                                                        isLoading={generatePracticeTestLoading}
                                                                        variant="shadow"
                                                                        color="primary"
                                                                        onClick={async () => {
                                                                            try {
                                                                                console.log(category);
                                                                                console.log(categories[category]);

                                                                                setGeneratePracticeTestLoading(true)

                                                                                if (
                                                                                    categories[category] === "" ||
                                                                                    categories[category] === undefined ||
                                                                                    category === -1
                                                                                ) {
                                                                                    setCategoryError(
                                                                                        "Please select a category"
                                                                                    );
                                                                                    return;
                                                                                }

                                                                                if (
                                                                                    questionCount < 1 ||
                                                                                    questionCount > 50 ||
                                                                                    questionCount === undefined
                                                                                ) {
                                                                                    setQuestionCountError(
                                                                                        "Question count must be at least 1 and not greater than 50"
                                                                                    );
                                                                                    return;
                                                                                } else {
                                                                                    setQuestionCountError("");
                                                                                }



                                                                                        if (category !== 2 && !sectionOneEnabled && !sectionTwoEnabled && !sectionThreeEnabled && !sectionFourEnabled && !sectionFiveEnabled){
                                                                                            setSectionsError("Select one or more sections");
                                                                                            return;
                                                                                        }

                                                                                        let s = [];

                                                                                        if (category === 2){
                                                                                            s.push(1);
                                                                                        } else if (category === 4){
                                                                                            if (sectionOneEnabled) s.push(2);
                                                                                            if (sectionThreeEnabled) s.push(3);
                                                                                            if (sectionFourEnabled) s.push(4);
                                                                                            if (sectionFiveEnabled && categoryCount[category] >= 5) s.push(5);
                                                                                        }else{
                                                                                            if (sectionOneEnabled && category !== 3) s.push(1);
                                                                                            if (sectionTwoEnabled) s.push(2);
                                                                                            if (sectionThreeEnabled) s.push(3);
                                                                                            if (sectionFourEnabled) s.push(4);
                                                                                            if (sectionFiveEnabled && categoryCount[category] >= 5) s.push(5);
                                                                                        }

                                                                                const r = await createPracticeTest({
                                                                                    categories: [categories[category]],
                                                                                    competitiveMode: competitiveMode,
                                                                                    hasPageNumbers: showPageNum,
                                                                                    liveCorrections: liveCorrections,
                                                                                    questionCount: questionCount,
                                                                                    oneResponsePerUser: oneResponsePerUserOnly,
                                                                                    sections: s,
                                                                                    reviewable: isReviewable
                                                                                });

                                                                                const h = await getPracticeTests();
                                                                                

                                                                                setPracticeTests(h.tests.reverse());
                                                                                setPracticeQuestionsPerTest(h.questionsPerTest.reverse());

                                                                            } catch (error) {
                                                                                
                                                                            } finally {
                                                                                setGeneratePracticeTestLoading(false)
                                                                                setMode("viewPracticeTests")
                                                                            }
                                                                            

                                                                        }}>
                                                                        Generate Quiz
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {mode === "viewPracticeTests" && (
                                                        <>
                                                        <p style={{color:'white', margin:'7px'}}>View practice tests</p>
                                                            {practiceTests.length > 0 && <div style={{height:'250px', overflow:'scroll', overflowX:'hidden'}}><Listbox>
                                                                {practiceTests.map((v:any, i) => {
                                                                    return (
                                                                        <ListboxItem key={v.code} onClick={() => {
                                                                            console.log(v);
                                                                            
                                                                            setReviewQuestion(0);
                                                                            
                                                                            
                                                                            setSelectedRecord(v); 
                                                                            setSelectedRecordQuestions(practiceQuestionsPerTest[i])}}>
                                                                            <p style={{color:'white'}}>{v.code} - {/*grabCompetitiveIdentifier(v.testRecordPreset.categories[0])*/} [{v.questionCount} Questions]</p>
                                                                            <p style={{color:'white'}}>{v.reviewable ? "Self-Reviewable" : ""}</p>
                                                                            <p style={{color:'white'}}>{v.oneResponsePerUser ? "One Response Per User" : ""}</p>
                                                                            <p style={{color:'white'}}>{v.liveCorrections ? "Live Corrections" : ""}</p>
                                                                            <p style={{color:'white'}}>{v.pageNumbers ? "Page Numbers Visible" : ""}</p>
                                                                            <p style={{color:'white'}}>{v.competitiveMode ? "Competitive Mode" : ""}</p>
                                                                        </ListboxItem>
                                                                    )
                                                                })}
                                                            </Listbox></div>}
                                                        </>
                                                    )}
                                                    {mode === "viewPracticeTestResults" && (
                                                        <>
                                                            <p style={{color:'white', margin:'7px'}}>View practice test results</p>
                                                            {practiceTests.length > 0 && <div style={{height:'250px', overflow:'scroll', overflowX:'hidden'}}><Listbox>
                                                                {practiceTests.map((v:any, i) => {
                                                                    return (
                                                                        <ListboxItem key={v.code} onClick={async () => {                                        
                                                                            setSelectedPracticeTest(v);
                                                                            setPracticeTestResponsesLoading(true)

                                                                            const o = await grabPracticeTestResponses({
                                                                                code:v.code
                                                                            });

                                                                            setPracticeTestResponses(o.records)
                                                                            setPracticeTestResponsesLoading(false)

                                                                            }}>
                                                                            <p style={{color:'white'}}>{v.code} - {/*grabCompetitiveIdentifier(v.testRecordPreset.categories[0])*/} [{v.questionCount} Questions]</p>
                                                                            <p style={{color:'white'}}>{v.reviewable ? "Self-Reviewable" : ""}</p>
                                                                            <p style={{color:'white'}}>{v.oneResponsePerUser ? "One Response Per User" : ""}</p>
                                                                            <p style={{color:'white'}}>{v.liveCorrections ? "Live Corrections" : ""}</p>
                                                                            <p style={{color:'white'}}>{v.pageNumbers ? "Page Numbers Visible" : ""}</p>
                                                                            <p style={{color:'white'}}>{v.competitiveMode ? "Competitive Mode" : ""}</p>
                                                                        </ListboxItem>
                                                                    )
                                                                })}
                                                            </Listbox></div>}
                                                        </>
                                                    )}
                                                </Tab>
                                                <Tab isDisabled={true} key={"Custom Questions"} title="Custom Questions">
                                                    <Chip
                                                        className="mb-2"
                                                        style={{
                                                            backgroundColor: "#9000B3",
                                                        }}>
                                                        Experimental
                                                    </Chip>
                                                    <p style={{ color: "white" }}>
                                                        Add your own questions to the question pool of your
                                                        organization (Maximum: 300)
                                                    </p>
                                                </Tab>
                                            </Tabs>

                                            <Divider style={{ width: "90%", color: "red" }} />
                                        </ModalBody>
                                        <ModalFooter style={{ justifyContent: "left" }}>
                                            <Button
                                                color="success"
                                                isLoading={submitting}
                                                style={{ color: "white" }}
                                                onClick={async () => {
                                                    setSubmitting(true);

                                                    try {
                                                        await orgManagement_setSettings({
                                                            socialSettingsEnabled: socialFeaturesEnabledI,
                                                            leaderboardEnabled: leaderboardEnabled,
                                                            requestToJoinEnabled: mustRequestToJoin,
                                                            leaderboardRankHidden: leaderboardRankHidden,
                                                        });
                                                        org.socialFeaturesEnabled = socialFeaturesEnabledI;
                                                        org.leaderboardEnabled = leaderboardEnabled;
                                                        org.requestToJoinEnabled = mustRequestToJoin;
                                                    } catch (error) {
                                                    } finally {
                                                        setSubmitting(false);
                                                    }
                                                }}>
                                                Save Settings
                                            </Button>
                                            <Button
                                                color="danger"
                                                style={{ color: "white" }}
                                                onClick={onDismiss}>
                                                Close
                                            </Button>
                                        </ModalFooter>
                                    </>
                                )}
                            </div>
                        ) : (
                            questionViewingUi
                        )
                    }
                </ModalContent>
            </Modal>
        </>
    );
};

export default ManageOrganizationModal;
