import { Avatar, Badge, Button, Card, CardBody, CardFooter, CardHeader, Chip, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, Popover, PopoverContent, PopoverTrigger, Radio, RadioGroup, Select, SelectItem, Skeleton, Spinner, Tab, Tabs } from "@nextui-org/react";
import { User } from "../models/user";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import * as UserApi from "../network/user_api"
import { useEffect, useState } from "react";
import TopCategoryIcon from "./Icons/TopCategoryIcon";
import SocialScienceIcon from "./Icons/SocialScienceIcon";
import Sidebar from "./Sidebar";
import SidebarBackFilledIcon from "./Icons/SidebarBackFilledIcon";
import UpArrowIcon from "./Icons/UpArrowIcon";
import DownArrowIcon from "./Icons/DownArrowIcon";
import DownGraphIcon from "./Icons/DownGraphIcon";
import StatsTrackerIcon from "./Icons/StatsTrackerIcon";
import EconIcon from "./Icons/EconIcon";
import { getCategoryChip, getCategoryIcon, grabCompetitiveIdentifier, grabIdentifier } from "../util/categoryIconHelper";
import RefreshIcon from "./Icons/RefreshIcon";
import NoChangeIcon from "./Icons/NoChangeIcon";

interface DashboardLoggedInViewProps {
    loggedInUser: User | null
}

const DashboardLoggedInView = (props: DashboardLoggedInViewProps) => {
    const [dashboardLoading, setDashboardLoading] = useState(true);
    const [user, setUser] = useState<any>({
        _id:"None",
        username: "None",
        email: "None",
        bestCategory: "None",
        worstCategory:"None",
        correctness: 0.0,

        badges: [],
        friends: [],
        
        bestCategoryPrivate: false,
        worstCategoryPrivate: false,
        correctnessPrivate: false,
        totalQuestionsPrivate: false,
        isLeaderboardAnonymous: false,
        status: "",
        bio: "",

        competitiveArtQuestionsAnswered: 0,
        competitiveArtQuestionsCorrect: 0,
        competitiveEconQuestionsAnswered: 0,
        competitiveEconQuestionsCorrect: 0,
        competitiveLitQuestionsAnswered: 0,
        competitiveLitQuestionsCorrect: 0,
        competitiveMathQuestionsAnswered: 0,
        competitiveMathQuestionsCorrect: 0,
        competitiveMusicQuestionsAnswered: 0,
        competitiveMusicQuestionsCorrect: 0,
        competitiveScienceQuestionsAnswered: 0,
        competitiveScienceQuestionsCorrect: 0,
        competitiveSocialScienceQuestionsAnswered: 0,
        competitiveSocialScienceQuestionsCorrect: 0,
        focusedCategory: 0,
        organization: "",
        profilePicture: "",
        totalQuestionsAnswered: 0,
        totalQuestionsCorrect: 0,

        socialScienceQuestionsAnswered: 0,
        socialScienceQuestionsCorrect: 0,
        scienceQuestionsAnswered: 0,
        scienceQuestionsCorrect: 0,
        artQuestionsAnswered: 0,
        artQuestionsCorrect: 0,
        litQuestionsAnswered: 0,
        litQuestionsCorrect: 0,
        econQuestionsAnswered: 0,
        econQuestionsCorrect: 0,
        mathQuestionsAnswered: 0,
        mathQuestionsCorrect: 0,
        musicQuestionsAnswered: 0,
        musicQuestionsCorrect: 0,

    });

    const [graphCategory, setGraphCategory] = useState(0);
    const [graphType, setGraphType] = useState(0);
    const [graphTimeFrame, setGraphTimeFrame] = useState(2);
    const [graphLoading, setGraphLoading] = useState(true);

    const [bestCatPastAverage, setBestCatPastAverage] = useState(0);
    const [worstCatPastAverage, setWorstCatPastAverage] = useState(0);
    const [bestCatCurrentAverage, setBestCatCurrentAverage] = useState(0);
    const [worstCatCurrentAverage, setWorstCatCurrentAverage] = useState(0);
    const [lastWeekWorstCatScore, setLastWeekWorstCatScore] = useState(0);

    const [bestCategoryPercentageChange, setBestCategoryPercentageChange] = useState("");

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const [lastWeekBestCatScore, setLastWeekBestCatScore] = useState(0);

    const [lastCompetitiveTest, setLastCompetitiveTest] = useState<any>()

    const [data, setData] = useState<any>([]);

    const [compSelected, setCompSelected] = useState("all");

    const [dashboardData, setDashboardData] = useState<any>();

    const [competitiveCooldownInfo, setCompetitiveCooldownInfo] = useState<any>();
    

    function clamp (num: number, min: number, max: number) {
        return Math.min(Math.max(num, min), max);
    }

    async function loadUser(){
        try {
            setDashboardLoading(true);

            const e = await UserApi.getCompetitiveCooldown();

            setCompetitiveCooldownInfo(e);

            const r = (await UserApi.getDashboardInfo({category:0, timeFrame:2, type:0}));
  
            setDashboardData(r);

            setData(r.data);
            setUser(r.user);
        } catch (error) {
          console.log(error);
        } finally {
            setGraphLoading(false);
            setDashboardLoading(false);
        }
        
      }

      async function loadStats(){
        try {
            setGraphLoading(true);
            
            const r = (await UserApi.getDashboardInfo({category:graphCategory, timeFrame:graphTimeFrame, type:graphType}));

            setDashboardData(r);

            console.log(r);
            

            setData(r.data);
        } catch (error) {
            console.log(error);
        } finally {
            setGraphLoading(false);
        }
      }

      const categories = ["All", "Social Science", "Science", "Math", "Literature", "Economics", "Art", "Music"];
      const types = ["Answered", "Correct", "Average"];
      const timeFrames = ["Month", "Week", "Day"];
        
    useEffect(() => {
        loadUser();

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    return ( 
        
        
            <div>
                
            
            {dashboardLoading && <Spinner color="secondary" style={{marginLeft:"auto", marginRight:"auto", justifyContent:"center", display:"flex"}}/>}
            { !dashboardLoading && <>
            
            <div style={{display:'inline-grid', margin:'2rem', marginLeft:'21%'}}>
                <p style={{fontWeight:'600', fontSize:20, marginBottom:'0.1rem', color:'#686868'}}>Welcome back, {user.username}</p>
                <p style={{fontWeight:'600', fontSize:38, marginBottom:'3rem'}}>Dashboard</p>
                <div style={{display:'inline-flex'}}>
                    <div style={{borderRadius:'1rem', backgroundColor:'#141414', marginBottom:'1rem', marginRight:'1rem', width:'22rem', height:'14rem', justifyContent:'left', padding:'1rem'}}>
                        <div style={{display:'flex', marginBottom:'1rem'}}>
                            <p style={{fontWeight:400, color:'white'}}>Top Category</p>
                            <TopCategoryIcon style={{marginLeft:'0.5rem'}}/>
                        </div>
                        <Divider style={{marginBottom:'1rem'}}/>
                        <div style={{display:'flex', marginBottom:'1rem'}}>
                            {grabCompetitiveIdentifier(dashboardData.topCategoryIdentifier) !== undefined && <p style={{fontWeight:400, color:'white'}}>{grabCompetitiveIdentifier(dashboardData.topCategoryIdentifier).replace("SocialScience", "Social Science")}</p>}
                            {<div style={{marginLeft:'0.5rem'}}>{getCategoryIcon(dashboardData.topCategoryIdentifier)}</div>}
                        </div>
                        <div style={{display:'flex', marginBottom:'1rem'}}>
                            <p style={{fontWeight:400, color:'white'}}>{dashboardData.topCategoryTotalQuestionsAnswered} Questions Answered</p>
                            <Popover placement="right">
                                <PopoverTrigger>
                                    <Chip color={dashboardData.topCategoryQuestionsAnsweredSinceLastWeek === 0 ? "secondary" : dashboardData.topCategoryQuestionsAnsweredSinceLastWeek > 0 ? "success" : "danger"} endContent={dashboardData.topCategoryQuestionsAnsweredSinceLastWeek === 0 ? <NoChangeIcon/> : dashboardData.topCategoryQuestionsAnsweredSinceLastWeek > 0 ? <UpArrowIcon/> : <DownArrowIcon/>} style={{marginLeft:'0.5rem', color:'white'}}><p style={{fontWeight:700}}>{dashboardData.topCategoryQuestionsAnsweredSinceLastWeek}</p></Chip>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <p style={{color:'white', padding:'5px', fontWeight:600}}>{dashboardData.topCategoryQuestionsAnsweredSinceLastWeek > 0 ? "+" : ""}{dashboardData.topCategoryQuestionsAnsweredSinceLastWeek} Questions Answered Since: Last Week</p>
                                </PopoverContent>
                            </Popover>
                        </div>
                        <div style={{display:'flex', marginBottom:'0rem'}}>
                            <p style={{fontWeight:400, color:'white'}}>
                            {Math.round(dashboardData.topCategoryAverage * 100)}% Average
                                </p>
                            <Popover placement="right">
                                <PopoverTrigger>
                                <Chip color={Math.round(dashboardData.topCategoryAverageChangeSinceLastWeek) === 0 ? "secondary" : dashboardData.topCategoryAverageChangeSinceLastWeek > 0  ? "success" : "danger"} endContent={Math.round(dashboardData.topCategoryAverageChangeSinceLastWeek) === 0 ? <NoChangeIcon/> : dashboardData.topCategoryAverageChangeSinceLastWeek > 0 ? <UpArrowIcon/> : <DownArrowIcon/>} style={{marginLeft:'0.5rem', color:'white'}}><p style={{fontWeight:700}}>{Math.ceil(dashboardData.topCategoryAverageChangeSinceLastWeek * 100)}%</p></Chip>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <p style={{color:'white', padding:'5px', fontWeight:600}}>{dashboardData.topCategoryAverageChangeSinceLastWeek > 0 ? "+" : "-"}{Math.ceil(dashboardData.topCategoryAverageChangeSinceLastWeek * 100)}% Average Since: Last Week</p>
                                </PopoverContent>
                            </Popover>
                            
                        </div>
                    </div>

                    <div style={{borderRadius:'1rem', backgroundColor:'#141414', width:'22rem', marginRight:'1rem', height:'14rem', justifyContent:'left', padding:'1rem'}}>
                        <div style={{display:'flex', marginBottom:'1rem'}}>
                            <p style={{fontWeight:400, color:'white'}}>Worst Category</p>
                            <DownGraphIcon style={{marginLeft:'0.5rem'}}/>
                        </div>
                        <Divider style={{marginBottom:'1rem'}}/>
                        <div style={{display:'flex', marginBottom:'1rem'}}>
                            <p style={{fontWeight:400, color:'white'}}>{grabCompetitiveIdentifier(dashboardData.worstCategoryIdentifier)}</p>
                            {<div style={{marginLeft:'0.5rem'}}>{getCategoryIcon(dashboardData.worstCategoryIdentifier)}</div>}
                        </div>
                        <div style={{display:'flex', marginBottom:'1rem'}}>
                        <p style={{fontWeight:400, color:'white'}}>{dashboardData.worstCategoryTotalQuestionsAnswered} Questions Answered</p>
                            <Chip color={dashboardData.worstCategoryQuestionsAnsweredSinceLastWeek === 0 ? "secondary" : dashboardData.worstCategoryQuestionsAnsweredSinceLastWeek > 0 ? "success" : "danger"} endContent={dashboardData.worstCategoryQuestionsAnsweredSinceLastWeek === 0 ? <NoChangeIcon/> : dashboardData.worstCategoryQuestionsAnsweredSinceLastWeek ? <UpArrowIcon/> : <DownArrowIcon/>} style={{marginLeft:'0.5rem', color:'white'}}><p style={{fontWeight:700}}>{dashboardData.worstCategoryQuestionsAnsweredSinceLastWeek}</p></Chip>
                        </div>
                        <div style={{display:'flex', marginBottom:'0rem'}}>
                            <p style={{fontWeight:400, color:'white'}}>{Math.round(dashboardData.worstCategoryAverage * 100) + "%"} Average</p>
                            <Popover placement="right">
                                <PopoverTrigger>
                                    <Chip color={dashboardData.worstCategoryAverageChangeSinceLastWeek === 0 ? "secondary" : dashboardData.worstCategoryAverageChangeSinceLastWeek > 0 ? "success" : "danger"} endContent={dashboardData.worstCategoryAverageChangeSinceLastWeek === 0 ? <NoChangeIcon/> : dashboardData.worstCategoryAverageChangeSinceLastWeek > 0 ? <UpArrowIcon/> : <DownArrowIcon/>} style={{marginLeft:'0.5rem', color:'white'}}><p style={{fontWeight:700}}>{Math.round(dashboardData.worstCategoryAverageChangeSinceLastWeek * 100)}%</p></Chip>
                                </PopoverTrigger>

                                <PopoverContent>
                                    <p style={{color:'white', padding:'5px', fontWeight:600}}>-{Math.round(dashboardData.worstCategoryAverageChangeSinceLastWeek * 100)}% Average Since: Last Week</p>
                                </PopoverContent>
                            </Popover>
                        </div>
                    </div>

                    
                    <div>
                <div style={{borderRadius:'1rem', backgroundColor:'#141414', width: '25rem', marginBottom:'4rem', marginRight:'1rem', height:'31rem', justifyContent:'left', padding:'1rem'}}>
                        <div style={{display:'flex', marginBottom:'1rem'}}>
                            <p style={{fontWeight:400, color:'white'}}>Competitive Stats</p>
                            <TopCategoryIcon style={{marginLeft:'0.5rem'}}/>
                        </div>
                        <Divider style={{marginBottom:'1rem'}}/>
                        <Chip
                            className="mb-2"
                            style={{
                                backgroundColor: "#9000B3",
                            }}>
                            Experimental
                        </Chip>
                        
                        <div>   
                           {competitiveCooldownInfo && <p style={{marginBottom:'2rem', color:'gray'}}>Competitive Test Cooldown: {competitiveCooldownInfo.cooldownExpired ? 0 : Math.round(48 * (competitiveCooldownInfo.cooldownAmount / (2 * 24 * 60 * 60 * 1000)))} Hour(s)</p>}
                        </div>
                        <div style={{display:'flex', marginBottom:'1rem'}}>
                            <p style={{fontWeight:400, color:'white'}}>Badges Acquired:</p>
                        </div>
                        <div className="gap-1 mb-2">
                            {user !== undefined && user.badges.map((badge:any) => {
                            if (getCategoryChip(badge) === undefined){
                            <Chip style={{color:'white', backgroundColor: '#175676', marginRight:'0.4rem'}}>
                                {badge}
                            </Chip>
                            }else{
                            return getCategoryChip(badge);
                            }
                        })}
                        </div>

                        <Divider className="mt-2 mb-2"/>
                        
                       
                    </div>
                </div>
                </div>

                <div style={{display:'inline-flex'}}>
                    <div style={{borderRadius:'1rem', backgroundColor:'#141414', marginTop:'-20rem', marginBottom:'1rem', marginRight:'1rem', width:'45rem', height:'16rem', justifyContent:'left', padding:'1rem'}}>
                        <div style={{display:'flex', marginBottom:'1rem'}}>
                            <p style={{fontWeight:400, color:'white'}}>Growth</p>
                            <StatsTrackerIcon style={{marginLeft:'0.5rem', marginRight:'4rem'}}/> 
                            <Select defaultSelectedKeys={["All"]} isDisabled={graphLoading} isLoading={graphLoading} size="sm" onChange={(v) => {setGraphCategory(categories.indexOf(v.target.value))}} placeholder="Category" style={{color:'white', width:'10rem', marginRight:'1rem'}}>
                                <SelectItem value={"All"} style={{ color: 'white' }} key={"All"}>All</SelectItem>
                                <SelectItem value={"Social Science"} style={{ color: 'white' }} key={"Social Science"}>Social Science</SelectItem>
                                <SelectItem value={"Science"} style={{ color: 'white' }} key={"Science"}>Science</SelectItem>
                                <SelectItem value={"Mathematics"} style={{ color: 'white' }} key={"Mathematics"}>Mathematics</SelectItem>
                                <SelectItem value={"Literature"} style={{ color: 'white' }} key={"Literature"}>Literature</SelectItem>
                                <SelectItem value={"Economics"} style={{ color: 'white' }} key={"Economics"}>Economics</SelectItem>
                                <SelectItem value={"Art"} style={{ color: 'white' }} key={"Art"}>Art</SelectItem>
                                <SelectItem value={"Music"} style={{ color: 'white' }} key={"Music"}>Music</SelectItem>
                            </Select>
                            <Select defaultSelectedKeys={["Answered"]} isDisabled={graphLoading} isLoading={graphLoading} size="sm" onChange={(v) => {setGraphType(types.indexOf(v.target.value))}} placeholder="Metric" style={{color:'white', width:'10rem', marginRight:'1rem'}}>
                                <SelectItem value={"Answered"} style={{ color: 'white' }} key={"Answered"}>Answered</SelectItem>
                                <SelectItem value={"Correct"} style={{ color: 'white' }} key={"Correct"}>Correct</SelectItem>
                            </Select>
                            <Select defaultSelectedKeys={["Day"]} isDisabled={graphLoading} isLoading={graphLoading} size="sm" onChange={(v) => {setGraphTimeFrame(timeFrames.indexOf(v.target.value))}} placeholder="Time Frame" style={{color:'white', width:'10rem', marginRight:'1rem'}}>
                                <SelectItem value={"Day"} style={{ color: 'white' }} key={"Day"}>Over Days</SelectItem>
                                <SelectItem value={"Week"} style={{ color: 'white' }} key={"Week"}>Over Weeks</SelectItem>
                                <SelectItem value={"Month"} style={{ color: 'white' }} key={"Month"}>Over Months</SelectItem>
                            </Select>
                            <Button isDisabled={graphLoading} isLoading={graphLoading} onClick={loadStats} isIconOnly style={{marginRight:'1rem', marginTop:'4px'}}>
                                <RefreshIcon/>
                            </Button>
                        </div>
                        <Divider style={{marginBottom:'1rem'}}/>
                        {data.length === 0 && <p style={{color:'white', fontWeight:400}}>No Data Available</p>}
                        <LineChart width={650} height={150} data={data}>
                            <Line type="monotone" dataKey="value" stroke="#8884d8" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip contentStyle={{backgroundColor:"#141414", borderRadius:'15px'}} />
                        </LineChart>
                    </div>
                </div>

                
            </div>
             </>}
            
            
                    
        </div>
     );
}

/*
<Container>

            <Row xs={1} md={2} lg={3} className="g-4">
            <h2 style={{marginTop:"9rem", inlineSize:"40rem", fontSize:36}}>Hi {props.loggedInUser?.username}!</h2>
                <Col>
                    <Card style={{ width: '26rem', height:"9rem", marginTop: "5rem", backgroundColor: "#373E40", color: "#E0FBFC" }}>
                        <Card.Body>
                            <Card.Title>Highest Performance Category</Card.Title>
                            <Card.Header><p style={{fontFamily:"Baron", color:"#2DD881", fontSize:25, textAlign:"center"}}>{highestPerformanceCategory}</p></Card.Header>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={3} className="g-4">
                <Col>
                    <Card style={{ width: '39rem', height:"19rem", marginTop: "2rem", backgroundColor: "#373E40", color: "#E0FBFC" }}>
                        <Card.Body>
                            <Card.Title>Questions Answered Correctly</Card.Title>
                            <Card.Header></Card.Header>
                            <Card.Text><p style={{fontFamily:"Baron", color:"#2DD881", fontSize:130, textAlign:"center"}}>{user.socialScienceQuestionsCorrect + ""}</p></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card style={{ width: '26rem', height:"9rem", marginLeft:"13rem", marginTop: "2rem", backgroundColor: "#373E40", color: "#E0FBFC" }}>
                        <Card.Body>
                            <Card.Title>Lowest Performance Category</Card.Title>
                            <Card.Header><p style={{fontFamily:"Baron", color:"#CD533B", fontSize:25, textAlign:"center"}}>{lowestPerformanceCategory}</p></Card.Header>
                            <Card.Text></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col>
                    <Card style={{ width: '26rem', height:"9rem", marginLeft:"-14rem", marginTop: "12rem", backgroundColor: "#373E40", color: "#E0FBFC" }}>
                        <Card.Body>
                            <Card.Title>Category Of The Day</Card.Title>
                            <Card.Header><p style={{fontFamily:"Baron", color:"#E3B505", fontSize:25, textAlign:"center"}}>Economics</p></Card.Header>
                            <Card.Text></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                
            </Row>
            <Row xs={1} md={2} lg={3} className="g-4">
                <Col>
                    <Card style={{ width: '66rem', height:"10rem", marginTop: "1rem", backgroundColor: "#373E40", color: "#E0FBFC" }}>
                        <Card.Body>
                            <Card.Title>Motivational Quote (more features coming soon!)</Card.Title>
                            <Card.Header></Card.Header>
                            <Card.Text><p style={{fontFamily:"Baron", color:"#2DD881", fontSize:50, textAlign:"center"}}>I Believe In You</p></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>}
        */
 
export default DashboardLoggedInView;