import { Avatar, Button, Checkbox, CheckboxGroup, Divider, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Skeleton, Spinner, Switch, Textarea } from "@nextui-org/react"
import { User } from "../models/user"
import { changeUserOrganization, getSocialSettings, setSocialSettings } from "../network/user_api";
import { useEffect, useState } from "react";
import { set } from "react-hook-form";
import { useFilePicker } from 'use-file-picker';
import {
    FileAmountLimitValidator,
    FileTypeValidator,
    FileSizeValidator,
    ImageDimensionsValidator,
  } from 'use-file-picker/validators';

interface SettingsModalProps {
    loggedInUser : User;
    onDismiss: () => void;

}

const SettingsModal = ({loggedInUser, onDismiss} : SettingsModalProps) => {
    const imageUrlToBase64 = async (url:any) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          };
          reader.onerror = reject;
        });
      };
      
      

    const { openFilePicker, filesContent, loading, errors } = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/jpeg',
        multiple: false,
        onFilesSelected: ({ plainFiles, filesContent, errors }) => {
          // this callback is always called, even if there are errors
        },
        onFilesRejected: ({ errors }) => {
          // this callback is called when there were validation errors
          if ((errors[0] as any).reasons){
                setProfilePictureErrorMessage((errors[0] as any).reasons.toString()
            .replace("IMAGE_HEIGHT_TOO_BIG", " Image height too large (max height 1000px)")
            .replace("IMAGE_WIDTH_TOO_BIG", " Image width too large (max width 1000px)")
            .replace("FILE_SIZE_TOO_LARGE", " File too large (max size 2 MB)")); 
          }else{
            setProfilePictureErrorMessage((errors[0] as any).reason
            .replace("IMAGE_HEIGHT_TOO_BIG", " Image height too large (max height 1000px)")
            .replace("IMAGE_WIDTH_TOO_BIG", " Image width too large (max width 1000px)")
            .replace("FILE_SIZE_TOO_LARGE", " File too large (max size 2 MB)"))
          }
          
        },
        onFilesSuccessfullySelected: async ({ plainFiles, filesContent }) => {
          // this callback is called when there were no validation errors
          setProfilePictureErrorMessage("");

          // update with api route
          loggedInUser.profilePicture = await imageUrlToBase64(filesContent[0].content) as string;
          setRefreshImg(true);
        },
        validators: [
            new FileAmountLimitValidator({ max: 1 }),
            new FileTypeValidator(['jpg', 'jpeg']),
            new FileSizeValidator({ maxFileSize: 2 * 1024 * 1024 }),
            new ImageDimensionsValidator({
              maxHeight: 1000, // in pixels
              maxWidth: 1000,
              minHeight: 50,
              minWidth: 50,
            }),
        ]
      });

    const [submitting, setSubmitting] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [leaderboardRankPrivateI, setLeaderboardRankPrivateI] = useState(false);
    const [bestCategoryPrivateI, setBestCategoryPrivateI] = useState(false);
    const [worstCategoryPrivateI, setWorstCategoryPrivateI] = useState(false);
    const [correctnessPrivateI, setCorrectnessPrivateI] = useState(false);
    const [totalQuestionsPrivateI, setTotalQuestionsPrivateI] = useState(false);
    const [statusI, setstatusI] = useState("");
    const [bioI, setbioI] = useState("");
    const [originalOrg, setOriginalOrg] = useState("");
    const [orgI, setOrgI] = useState("");

    const [focusedCategoryI, setfocusedCategoryI] = useState<Number>(0);
    const [submissionError, setSubmissionError] = useState("");

    const [imgContent, setImgContent] = useState<any>();
    const [refreshImg, setRefreshImg] = useState(false);

    const [profilePictureErrorMessage, setProfilePictureErrorMessage] = useState("");

    const categories = ["socialScience", "science", "math", "literature", "economics", "art", "music"];

    function refreshPage(){ 
        window.location.reload(); 
    }

    async function updateSettings(){
        try {
            setIsLoading(true);
            await getSocialSettings().then((res) => {
                setLeaderboardRankPrivateI(res.isLeaderboardAnonymous);
                setBestCategoryPrivateI(res.bestCategoryPrivate);
                setWorstCategoryPrivateI(res.worstCategoryPrivate);
                setCorrectnessPrivateI(res.correctnessPrivate);
                setTotalQuestionsPrivateI(res.totalQuestionsPrivate);
                setstatusI(res.status);
                setbioI(res.bio);
                setfocusedCategoryI(res.focusedCategory);
                setOriginalOrg(res.organization)
                setOrgI(res.organization);
            });

        } catch {

            console.error("Error getting settings, try again later.");

        } finally {

            setIsLoading(false);

        }
      }

    useEffect(() => {
        updateSettings();
      }, []);


    return (
        <>
      <Modal isOpen={true} onClose={onDismiss}>
      <ModalContent>
            {(onClose) => (
                
                <>
                {isLoading && <Spinner className="mb-2 mt-2" color="secondary"/>}
                {!isLoading && <>
                <ModalHeader className="flex flex-col gap-1" style={{color:"white"}}>Settings</ModalHeader>
                <ModalBody>
                <div style={{display:'flex'}} onClick={() => {setRefreshImg(false); openFilePicker()}}>
                    {
                        loggedInUser.profilePicture ? 
                        <img src={loggedInUser.profilePicture || refreshImg ? loggedInUser.profilePicture : ""} alt="profile" style={{width:'75px', height:'75px', borderRadius:'50%'}}/>
                        :
                        <Avatar
                        style={{width:'75px', height:'75px', borderRadius:'50%'}}
                        fallback={loggedInUser.username}
                        
                        />
                    }
                    
                </div>
                <p style={{color:'white'}}>Change your profile picture.</p>
                <p aria-label="error-text" className="text-danger" style={{fontSize:14}}>{profilePictureErrorMessage}</p>
                <Divider/>
                
                <CheckboxGroup
                    label="Privacy Settings (check all you would like to be private)"
                    orientation="vertical"
                    color="secondary"
                    >
                        <Switch size="sm" isDisabled={isLoading} color="secondary" isSelected={leaderboardRankPrivateI} onValueChange={setLeaderboardRankPrivateI}>
                            Leaderboard Rank
                        </Switch>  
                        <Switch size="sm" isDisabled={isLoading} color="secondary" isSelected={bestCategoryPrivateI} onValueChange={setBestCategoryPrivateI}>
                            Best Category
                        </Switch>  
                        <Switch size="sm" isDisabled={isLoading} color="secondary" isSelected={worstCategoryPrivateI} onValueChange={setWorstCategoryPrivateI}>
                            Worst Category
                        </Switch>  
                        <Switch size="sm" isDisabled={isLoading} color="secondary" isSelected={correctnessPrivateI} onValueChange={setCorrectnessPrivateI}>
                            Correctness Average
                        </Switch>  
                        <Switch size="sm" isDisabled={isLoading} color="secondary" isSelected={totalQuestionsPrivateI} onValueChange={setTotalQuestionsPrivateI}>
                            Total Questions Answered
                        </Switch>  
                </CheckboxGroup>
                <Divider/>
                    <Input isDisabled={isLoading} type="text" label="Status" maxLength={15} value={statusI} onValueChange={setstatusI}/>
                    <Input isDisabled={isLoading} type="text" label="Bio" maxLength={256} value={bioI} onValueChange={setbioI}/>

                    <Select
                        label="Focused Subject"
                        placeholder="Let everyone know what you're studying!"
                        className="max-w-md"
                        selectedKeys={[categories[focusedCategoryI as number]]}
                        isDisabled={isLoading}
                        onChange={(e) => {setfocusedCategoryI(categories.indexOf(e.target.value))}}
                        >
                        <SelectItem value={0} key={"socialScience"} style={{color:'white'}}>Social Science</SelectItem>
                        <SelectItem value={1} key={"science"} style={{color:'white'}}>Science</SelectItem>
                        <SelectItem value={2} key={"math"} style={{color:'white'}}>Math</SelectItem>
                        <SelectItem value={3} key={"literature"} style={{color:'white'}}>Literature</SelectItem>
                        <SelectItem value={4} key={"economics"} style={{color:'white'}}>Economics</SelectItem>
                        <SelectItem value={5} key={"art"} style={{color:'white'}}>Art</SelectItem>
                        <SelectItem value={6} key={"music"} style={{color:'white'}}>Music</SelectItem>


                    </Select>
                    <Divider style={{width:'90%', color:'red'}}/>
                    <Input isDisabled={isLoading} type="text" label="Organization Code" minLength={5} maxLength={5} value={orgI == "NULLL" ? "" : orgI} onValueChange={setOrgI}/>
                </ModalBody>
                <ModalFooter style={{justifyContent:'left'}}>
                    <Button color={submissionError !== "" ? "danger" : "success"} style={{color:'white'}}
                    isLoading={submitting}

                    onClick={async () => {
                        try {
                            setSubmitting(true);

                            let o = orgI;

                            if (orgI.length > 5){
                                o = orgI.substring(0, 5);
                            }

                            if (orgI.trim().length == 0 || orgI.length == 0){
                                o = "NULLL";
                            }

                            if (o !== originalOrg){
                                await changeUserOrganization({newOrganization: o});
                            }
                            
                            
                            setSocialSettings({
                                isLeaderboardAnonymous: leaderboardRankPrivateI,
                                bestCategoryPrivate: bestCategoryPrivateI,
                                worstCategoryPrivate: worstCategoryPrivateI,
                                correctnessPrivate: correctnessPrivateI,
                                totalQuestionsPrivate: totalQuestionsPrivateI,
                                status: statusI.trim(),
                                bio: bioI.trim(),
                                focusedCategory: focusedCategoryI as number,
                                profilePicture: loggedInUser.profilePicture,
                                organization: o
                                
                            }).then((res) => {if (res.status !== 200) {
                                setSubmissionError("Error submitting changes");
                            }}).catch(() => {
                                setSubmissionError("Error submitting changes");
                            }).finally(() => {
                                setSubmitting(false);
                                onDismiss();
                                refreshPage();
                            });
                        } catch (error:any) {
                            setSubmissionError(error.message)
                        }
                        
                    }}
                    
                    >
                        Save Changes
                    </Button>  
                    <p style={{color:'white'}}>{submissionError !== "" ? submissionError : ""}</p>
                    <Button color="danger" style={{color:'white'}} onClick={onDismiss}>
                        Cancel
                    </Button> 
                </ModalFooter>
                </>}
                </>
            )}
            </ModalContent>
        </Modal>
    </>
    )
}

export default SettingsModal