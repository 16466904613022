import { useEffect, useState } from "react";
import { User } from "../models/user";
import QuestionPageLoggedInView from "./QuestionPageLoggedInView";
import { Button, ButtonGroup, Card, CardBody, CardHeader, Checkbox, CheckboxGroup, Divider, Input, Listbox, ListboxItem, Modal, ModalContent, Pagination, Radio, RadioGroup, ScrollShadow, Select, SelectItem, SelectSection, Slider, Spinner, Switch, Tab, Tabs, Tooltip } from "@nextui-org/react";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import CompetitiveModeIcon from "./Icons/CompetitiveModeIcon";
import { checkPracticeTestCodeValid, generateTest, getCompetitiveCooldown, grabTestHistory, grabTestHistoryManifest } from "../network/user_api";
import LeftArrowIcon from "./Icons/LeftArrowIcon";
import RightArrowIcon from "./Icons/RightArrowIcon";
import { evaluateMathQuestion, getCategoryIcon, grabCompetitiveIdentifier } from "../util/categoryIconHelper";
import PasswordIcon from "./Icons/PasswordIcon";
import { jsPDF } from "jspdf";
import "../styles/fonts/TimesNewRoman.ttf"

interface QuizzesLoggedInProps {
    loggedInUser: User
}

const QuizzesLoggedInView = (props : QuizzesLoggedInProps) => {
    const [liveCorrections, setliveCorrections] = useState(false);
    const [showPageNum, setShowPageNum] = useState(false);
    const [questionCount, setQuestionCount] = useState(25);
    const [category, setCategory] = useState(1);
    const [categoryError, setCategoryError] = useState("");
    const [questionCountError, setQuestionCountError] = useState("");
    const [sectionsError, setSectionsError] = useState("");
    const [competitiveMode, setCompetitiveMode] = useState(false);
    const [sections, setSections] = useState<Number[]>([]);

    const [testRecordsLoading, setTestRecordsLoading] = useState(false);
    const [testRecords, setTestRecords] = useState<any[]>([]);
    const [recordQuestions, setRecordQuestions] = useState<any[]>([]);

    const [testRecordsManifestAmount, setTestRecordsManifestAmount] = useState(0);
    const [testRecordsPage, setTestRecordsPage] = useState(0);
    const [selectedRecord, setSelectedRecord] = useState<any>();
    const [selectedRecordQuestions, setSelectedRecordQuestions] = useState<any[]>()

    const [reviewQuestion, setReviewQuestion] = useState(0);
    const [practiceTestCode, setPracticeTestCode] = useState("");
    const [practiceTestCodeFinal, setPracticeTestCodeFinal] = useState("");
    const [practiceTestError, setPracticeTestError] = useState("");

    const [sectionOneEnabled, setSectionOneEnabled] = useState(false);
    const [sectionTwoEnabled, setSectionTwoEnabled] = useState(false);
    const [sectionThreeEnabled, setSectionThreeEnabled] = useState(false);
    const [sectionFourEnabled, setSectionFourEnabled] = useState(false);
    const [sectionFiveEnabled, setSectionFiveEnabled] = useState(false);

    const [loadingPdfs, setLoadingPdfs] = useState(false);

    const [propSections, setPropSections] = useState<any[]>([]);

    const [compInfoLoading, setCompInfoLoading] = useState(true);
     

    const categories = ["socialScience", "science", "math", "literature", "economics", "art", "music"];
    const categoryCount = [4, 4, 3, 4, 4, 5, 4]

    const [competitiveCooldownInfo, setCompetitiveCooldownInfo] = useState<any>();


    async function loadTestRecords(pageNumber: number) {
        try {

            setTestRecordsLoading(true);
            const f = await grabTestHistoryManifest();

            setTestRecordsManifestAmount(f.amount);

            const r = await grabTestHistory({page: pageNumber});
            setRecordQuestions(r.questions);

            setTestRecords(r.records);
            
        } catch (error) {
            console.log(error);
            
        } finally {
            setTestRecordsLoading(false);
        }
        
    }

    async function loadCooldownInfo() {
        try {
            const e = await getCompetitiveCooldown();
            setCompetitiveCooldownInfo(e);
            
        } catch (error) {
            
        } finally {
            setCompInfoLoading(false);
        }
        
    }

    let questionUi = <div>
        <QuestionPageLoggedInView 
            user={props.loggedInUser}
            category={categories[category]}
            questionCount={questionCount}
            liveCorrections={liveCorrections}
            showPageNum={showPageNum}
            competitiveMode={competitiveMode}
            practiceTestCode={practiceTestCodeFinal}
            sections={propSections} 
            isPdf={false}        />
    </div>

    const [setupActive, setSetupActive] = useState(true);
  
    const setupUi = <>    
    <div className="flex w-full flex-wrap md:flex-nowrap gap-4" style={{display:"flex", justifyContent:"center", marginTop:'2rem'}}>
  <CheckboxGroup
    label="Practice Settings"
    defaultValue={[""]}
  >
    
    <Checkbox value="live-corrections" isReadOnly={competitiveMode} color="secondary" isDisabled={competitiveMode} onValueChange={(selected) => {setliveCorrections(selected)}}>Live Corrections</Checkbox>
    <Checkbox value="page-numbers" isReadOnly={competitiveMode} color="secondary" isDisabled={competitiveMode || category === 2} onValueChange={(selected) => {setShowPageNum(selected)}}>Page Numbers</Checkbox>
    <Checkbox value="competitive-mode" isDisabled={(!compInfoLoading && !competitiveCooldownInfo.cooldownExpired) || compInfoLoading} color="secondary" icon={<CompetitiveModeIcon/>} onValueChange={(selected) => {
        if (!compInfoLoading && competitiveCooldownInfo.cooldownExpired && selected){
            setQuestionCount(category === 2 ? 35 : 50);
            setliveCorrections(false);
            setShowPageNum(false);
            
        }

        setCompetitiveMode(selected);
     }}>Competitive Mode</Checkbox>

    {!compInfoLoading && !competitiveCooldownInfo.cooldownExpired && <p style={{width:'170px'}}>(Cooldown: {Math.ceil(competitiveCooldownInfo.cooldownAmount / (60 * 60 * 1000))} Hours)</p>}

  </CheckboxGroup>

      <Input type="number" errorMessage={questionCountError !== "" && questionCountError} label="Question Count" value={questionCount.toString()} isReadOnly={competitiveMode} isDisabled={competitiveMode} className="w-1/6 text-white" onValueChange={(v) => {setQuestionCount(parseInt(v))}} />
      
      <Select
        label="Testing Category"
        placeholder="Select a Category"
        className="max-w-xs"
        isInvalid={categoryError !== ""}
        errorMessage={categoryError !== "" && categoryError}
        selectedKeys={categories[category] !== "" ? [categories[category]] : []}
        disabledKeys={[]}
        onChange={(e) => {
            setCategory(categories.indexOf(e.target.value)); 

            if ((categories.indexOf(e.target.value) === 3 || categories.indexOf(e.target.value) === 4)  && sectionOneEnabled) setSectionOneEnabled(false);

            setCategoryError("");
        }}
        >
        <SelectItem value={0} key={"socialScience"} style={{color:'white'}}>Social Science</SelectItem>
        <SelectItem value={1} key={"science"} style={{color:'white'}}>Science</SelectItem>
        <SelectItem value={2} key={"math"} style={{color:'white'}}>Math</SelectItem>
        <SelectItem value={3} key={"literature"} style={{color:'white'}}>Literature</SelectItem>
        <SelectItem value={4} key={"economics"} style={{color:'white'}}>Economics</SelectItem>
        <SelectItem value={5} key={"art"} style={{color:'white'}}>Art</SelectItem>
        <SelectItem value={6} key={"music"} style={{color:'white'}}>Music</SelectItem>


    </Select>
    <div style={{display:'block'}}>
        <p style={{color:'white', margin:'0px'}}>Sections Enabled</p>
        {sectionsError !== "" && <p color="danger">{sectionsError}</p>}
        {category === 2 && <p style={{color:'grey'}} color="danger"><i>Temporarily Disabled For Math</i></p>}
        {categoryCount[category] > 0 && <>
               { category !== 4 ?
                <Checkbox color="secondary" isDisabled={competitiveMode || category === 3 || category === 2} isSelected={sectionOneEnabled} onValueChange={setSectionOneEnabled} style={{color:'white', margin:'2px'}}>{1}</Checkbox>
            :
                <Checkbox color="secondary" isDisabled={true || competitiveMode } isSelected={sectionOneEnabled} onValueChange={setSectionOneEnabled} style={{color:'white', margin:'2px'}}>1 & 2</Checkbox>
            
            }
               {category !== 4 && <Checkbox color="secondary" isDisabled={competitiveMode || category === 2} onValueChange={setSectionTwoEnabled} isSelected={sectionTwoEnabled} style={{color:'white', margin:'2px'}}>{2}</Checkbox>}
                <Checkbox color="secondary" isDisabled={competitiveMode || category === 2} onValueChange={setSectionThreeEnabled} isSelected={sectionThreeEnabled} style={{color:'white', margin:'2px'}}>{3}</Checkbox>
                {categoryCount[category] >= 4 && <Checkbox color="secondary" isDisabled={competitiveMode || category === 2} onValueChange={setSectionFourEnabled} isSelected={sectionFourEnabled} style={{color:'white', margin:'2px'}}>{4}</Checkbox>}
                {categoryCount[category] === 5 && <Checkbox color="secondary" isDisabled={competitiveMode || category === 2} isSelected={sectionFiveEnabled} onValueChange={setSectionFiveEnabled} style={{color:'white', margin:'2px'}}>{5}</Checkbox>}
                </>}
    </div>
    
      
      

      
      <div className="flex w-full flex-wrap md:flex-nowrap gap-4" style={{justifyContent:"center", display:"flex"}}>
        <Tabs placement="bottom" style={{justifySelf:'center', margin:'auto'}}>
            <Tab title="Take Quiz">
            <Button variant="shadow" style={{backgroundColor:'#9000B3', justifyContent:'center', margin:'auto'}} onClick={() => {
        
        if (categories[category] === "" || categories[category] === undefined || category === -1){
            setCategoryError("Please select a category");
            return;
        }

        if(questionCount < 1 || questionCount > 50 || questionCount === undefined){
            setQuestionCountError("Question count must be at least 1 and not greater than 50");
            return
        }else{
            setQuestionCountError("");
        }

        if (category !== 2){
            if (!competitiveMode && !sectionOneEnabled && !sectionTwoEnabled && !sectionThreeEnabled && !sectionFourEnabled && !sectionFiveEnabled){
                setSectionsError("Select one or more sections");
                return;
            }
        }
        

        let s = [];

        if (competitiveMode){
            setQuestionCount(category !== 2 ? 50 : 35);
            setliveCorrections(false);
            setShowPageNum(false); 

            if (category !== 2){
                if (category !== 3 && category !== 4) s.push(1);

                if (categoryCount[category] >= 2){
                    s.push(2);
                }
    
                if (categoryCount[category] >= 3){
                    s.push(3);
                }
                
                if (categoryCount[category] >= 4){
                    s.push(4);
                }
    
                if (categoryCount[category] >= 5){
                    s.push(5);
                }
            }else{
                s.push(1);
            }
            

        }else{
            if (category === 2){
                s.push(1);
            } else if (category === 4){
                if (sectionOneEnabled) s.push(2);
                if (sectionThreeEnabled) s.push(3);
                if (sectionFourEnabled) s.push(4);
                if (sectionFiveEnabled && categoryCount[category] >= 5) s.push(5);
            }else{
                if (sectionOneEnabled && category !== 3) s.push(1);
                if (sectionTwoEnabled) s.push(2);
                if (sectionThreeEnabled) s.push(3);
                if (sectionFourEnabled) s.push(4);
                if (sectionFiveEnabled && categoryCount[category] >= 5) s.push(5);
            }
            
        }

        setPropSections(s);
        
        setPracticeTestCodeFinal("");
        setSetupActive(false);
        }}>
        Start Quiz
      </Button>  
            </Tab>
            {<Tab title="Download Quiz" isDisabled={false}>
                <Button variant="shadow" onClick={async () => {
        
        if (categories[category] === "" || categories[category] === undefined || category === -1){
            setCategoryError("Please select a category");
            return;
        }

        if(questionCount < 1 || questionCount > 50 || questionCount === undefined){
            setQuestionCountError("Question count must be at least 1 and not greater than 50");
            return
        }else{
            setQuestionCountError("");
        }

        if (category !== 2){
            if (!competitiveMode && !sectionOneEnabled && !sectionTwoEnabled && !sectionThreeEnabled && !sectionFourEnabled && !sectionFiveEnabled){
                setSectionsError("Select one or more sections");
                return;
            }
        }
        

        let s = [];

        if (competitiveMode){
            setQuestionCount(category !== 2 ? 50 : 35);
            setliveCorrections(false);
            setShowPageNum(false); 

            if (category !== 2){
                if (category !== 3 && category !== 4) s.push(1);

                if (categoryCount[category] >= 2){
                    s.push(2);
                }
    
                if (categoryCount[category] >= 3){
                    s.push(3);
                }
                
                if (categoryCount[category] >= 4){
                    s.push(4);
                }
    
                if (categoryCount[category] >= 5){
                    s.push(5);
                }
            }else{
                s.push(1);
            }
            

        }else{
            if (category === 2){
                s.push(1);
            } else if (category === 4){
                if (sectionOneEnabled) s.push(2);
                if (sectionThreeEnabled) s.push(3);
                if (sectionFourEnabled) s.push(4);
                if (sectionFiveEnabled && categoryCount[category] >= 5) s.push(5);
            }else{
                if (sectionOneEnabled && category !== 3) s.push(1);
                if (sectionTwoEnabled) s.push(2);
                if (sectionThreeEnabled) s.push(3);
                if (sectionFourEnabled) s.push(4);
                if (sectionFiveEnabled && categoryCount[category] >= 5) s.push(5);
            }
            
        }

        setLoadingPdfs(true);

        const r = await generateTest({
            categories: [categories[category]],
            competitiveMode: false,
            hasPageNumbers: showPageNum,
            liveCorrections: liveCorrections,
            practiceTestCode: "",
            questionCount: questionCount,
            returnAsPdf: true,
            sections: s
        })
        

        const doc = new jsPDF();
        const key = new jsPDF();

        
          let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
          

          function addCenteredText(str: string, y: number) {
                doc.text(str, pageWidth / 2, y, {align: 'center'});
          }

        const q: any[] = r.questions;



        // add the font to jsPDF
        doc.setFont('TimesNewRoman', 'normal');
        key.setFont('TimesNewRoman', 'normal');
        key.setFontSize(25);
        doc.setFontSize(12);

        
        doc.setFontSize(25);
        addCenteredText("HUGO ACDEC", pageHeight / 4);
        key.text("HUGO ACDEC", pageWidth / 2, pageHeight / 4, {align: 'center'});

        key.setFontSize(15);
        doc.setFontSize(15);
        let subtitleString = "";

        subtitleString += new Date().toDateString() + " " + grabCompetitiveIdentifier(categories[category]).replace("SocialScience", "Social Science") + (categories[category] !== 'math' ? " Section(s) " : ' ');

        if (categories[category] !== 'math'){
            const sectionOptions = [
                sectionOneEnabled,
                sectionTwoEnabled,
                sectionThreeEnabled,
                sectionFourEnabled,
                sectionFiveEnabled
            ]
    
            let totalSections = 0;
    
            sectionOptions.forEach(element => {
                if (element === true){
                    totalSections++;
                }
            });
    
            let z = 0;
            for (let o = 0; o < sectionOptions.length; o++) {
                if (!sectionOptions[o]) continue;
    
                
                subtitleString += o + 1;
    
                if (z > 0 && o + 2 === totalSections){
                    subtitleString += ", and";
                } else if (o + 1 !== totalSections){
                    subtitleString += ",";
                } 
    
                subtitleString += " ";
                z++;
            }
        }
       
        subtitleString += "Test";

        addCenteredText(subtitleString, pageHeight / 3);
        key.text(subtitleString, pageWidth / 2, pageHeight / 3, {align: 'center'});

        addCenteredText("Developed By Hayden Karp", pageHeight / 2);
        key.text("Developed By Hayden Karp", pageWidth / 2, pageHeight / 2, {align: 'center'});

        key.setTextColor(255, 0, 0);
        key.text("ANSWER KEY - ANSWER KEY - ANSWER KEY", pageWidth / 2, pageHeight / 1.5, {align: 'center'});
        key.setTextColor(0, 0, 0);

        key.addPage();
        
        doc.addPage();
        


        // add your content to the document here, as usual
        const lineLen = 105;
        const margin = 10;
        const contentWidth = pageWidth - margin * 2;
        const questionsPerPage = 4;
        const heightPerQuestion = pageHeight * (1.0 / 5.0);

       let pageCount: number = 0;

       let p = 0;
        for (let index = 0; index < q.length; index++) {
            // Add the question number and question text
            const sp = 60;
            let a = 0;

            // Add the question number and question text
            const questionLines = doc.splitTextToSize(`Question ${index + 1}. ${q[index].question.replace(/[^\x20-\x7E]/g, '[?]')}`, contentWidth);
            doc.setFontSize(10);
            key.setFontSize(10);
            doc.text(questionLines, 10, 10 + ((index - (pageCount * questionsPerPage)) * sp));
            key.text(questionLines, 10, (p * 5) + 10 + ((index - (pageCount * questionsPerPage)) * sp));

            a = questionLines.length;

            if (questionLines.length === 1){
                a = -2.5;
            }

            if (categories[category] !== 'math'){
                doc.text(`A. ${q[index].answerChoiceA.replace(/[^\x20-\x7E]/g, '[?]')}`, 20, a + 20 + ((index - (pageCount * questionsPerPage)) * sp));
                doc.text(`B. ${q[index].answerChoiceB.replace(/[^\x20-\x7E]/g, '[?]')}`, 20, a + 30 + ((index - (pageCount * questionsPerPage)) * sp));
                doc.text(`C. ${q[index].answerChoiceC.replace(/[^\x20-\x7E]/g, '[?]')}`, 20, a + 40 + ((index - (pageCount * questionsPerPage)) * sp));
                doc.text(`D. ${q[index].answerChoiceD.replace(/[^\x20-\x7E]/g, '[?]')}`, 20, a + 50 + ((index - (pageCount * questionsPerPage)) * sp));
                doc.text(`E. ${q[index].answerChoiceE.replace(/[^\x20-\x7E]/g, '[?]')}`, 20, a + 60 + ((index - (pageCount * questionsPerPage)) * sp));
    
                
    
                if (q[index].correctAnswer === 0) key.setTextColor(255, 0, 0);
                key.text(`A. ${q[index].answerChoiceA.replace(/[^\x20-\x7E]/g, '[?]')}`, 20,(p * 5) +  a + 20 + ((index - (pageCount * questionsPerPage)) * sp));
    
                if (q[index].correctAnswer === 0) {
                    key.text(key.splitTextToSize(q[index].justification, contentWidth), 20, (p * 5) + a + 25 + ((index - (pageCount * questionsPerPage)) * sp));
                    p += key.splitTextToSize(q[index].justification, contentWidth).length;
                }
    
                key.setTextColor(0, 0, 0);
                if (q[index].correctAnswer === 1) key.setTextColor(255, 0, 0);
                key.text(`B. ${q[index].answerChoiceB.replace(/[^\x20-\x7E]/g, '[?]')}`, 20,(p * 5) + a + 30 + ((index - (pageCount * questionsPerPage)) * sp));
                if (q[index].correctAnswer === 1) {
                    key.text(key.splitTextToSize(q[index].justification, contentWidth), 20, (p * 5) + a + 35 + ((index - (pageCount * questionsPerPage)) * sp));
                    p += key.splitTextToSize(q[index].justification, contentWidth).length;
                }
                key.setTextColor(0, 0, 0);
                if (q[index].correctAnswer === 2) key.setTextColor(255, 0, 0);
                key.text(`C. ${q[index].answerChoiceC.replace(/[^\x20-\x7E]/g, '[?]')}`, 20, (p * 5) + a + 40 + ((index - (pageCount * questionsPerPage)) * sp));
                if (q[index].correctAnswer === 2) {
                    key.text(key.splitTextToSize(q[index].justification, contentWidth), 20, (p * 5) + a + 45 + ((index - (pageCount * questionsPerPage)) * sp));
                    p += key.splitTextToSize(q[index].justification, contentWidth).length;
                }
                key.setTextColor(0, 0, 0);
                if (q[index].correctAnswer === 3) key.setTextColor(255, 0, 0);
                key.text(`D. ${q[index].answerChoiceD.replace(/[^\x20-\x7E]/g, '[?]')}`, 20,(p * 5) +  a + 50 + ((index - (pageCount * questionsPerPage)) * sp));
                if (q[index].correctAnswer === 3) {
                    key.text(key.splitTextToSize(q[index].justification, contentWidth), 20, (p * 5) + a + 55 + ((index - (pageCount * questionsPerPage)) * sp));
                    p += key.splitTextToSize(q[index].justification, contentWidth).length;
                }
                key.setTextColor(0, 0, 0);
                if (q[index].correctAnswer === 4) key.setTextColor(255, 0, 0);
                key.text(`E. ${q[index].answerChoiceE.replace(/[^\x20-\x7E]/g, '[?]')}`, 20,(p * 5) + a + 60 + ((index - (pageCount * questionsPerPage)) * sp));
                if (q[index].correctAnswer === 4) {
                    key.text(key.splitTextToSize(q[index].justification, contentWidth), 20, (p * 5) + a + 65 + ((index - (pageCount * questionsPerPage)) * sp));
                    p += key.splitTextToSize(q[index].justification, contentWidth).length;
                }
                key.setTextColor(0, 0, 0);
            }else{
                key.setTextColor(255, 0, 0);
                key.text(`${evaluateMathQuestion(q[index], "").correctAnswer}`, 20,(p * 5) +  a + 20 + ((index - (pageCount * questionsPerPage)) * sp));
                key.setTextColor(0, 0, 0);
            }

            

            // Add a space between questions
            
            if (index > 0 && (index + 1) % questionsPerPage === 0 && index + 1 !== q.length) {
                doc.addPage();
                key.addPage();
                pageCount += 1;
                p = 0;
            }
                
        }

        doc.save(subtitleString.replace(" ", "_"));
        key.save((subtitleString + " KEY").replace(" ", "_"));
        
        

        

        setLoadingPdfs(false);

        }} style={{backgroundColor:'#9000B3', justifyContent:'center', margin:'auto'}} isLoading={loadingPdfs}>Download PDF</Button>
            </Tab>}
        </Tabs>
      
    </div>
      
    </div>
  </>

  const reviewQuestionsUi = <>
    {testRecordsLoading && <Spinner color="secondary" style={{width:'34rem', display:'flex', justifyContent:'center', margin:'auto'}}/>}
    {!testRecordsLoading && <Listbox style={{width:'34rem', display:'flex', justifyContent:'center', margin:'auto'}} emptyContent={<p style={{textAlign:'center'}}>No tests to review, start answering questions and come back to see your previous tests here!</p>}>    
        {testRecords.map((v, i) => {
            return (
            <ListboxItem key={v._id} onClick={() => {setReviewQuestion(0); setSelectedRecord(testRecords[i]); setSelectedRecordQuestions(recordQuestions[i]);
            }}>
                <Card style={{width:'30rem', display:'flex', justifyContent:'center', margin:'auto'}}>
                    <CardHeader style={{display:'block'}}>
                        <div style={{display:'flex'}}>
                            <p style={{fontSize:24, fontWeight:600, marginBottom:'0.7rem', marginRight:'0.7rem'}}>{new Date(v.dateCreated).getMonth() + 1} / {new Date(v.dateCreated).getDate()} {grabCompetitiveIdentifier(v.categories[0]).replace("SocialScience", "Social Science")} Test</p>
                            {getCategoryIcon(v.categories[0])}
                        </div>
                        <p>Scored {v.correctAmt}/{v.answered}</p>
                    </CardHeader>
                    <CardBody>
                        {v.sections && <p>Sections:</p>}
                        {
                            v.sections && v.sections.map((v: any) => {
                                return <p>{v}</p>;
                            })
                        }
                        {(v.liveCorrections || v.competitiveMode || v.pageNumbers) &&
                        <>
                        <p>Modifiers:</p>
                        {v.liveCorrections && <p>Live Corrections</p>}
                        {v.pageNumbers && <p>Page Numbers Visible</p>}
                        {v.competitiveMode && <p>Competitive Mode</p>}
                        </>
                        
                        }
                        
                    </CardBody>
                </Card>
        </ListboxItem>
            )
        })}
    </Listbox>}
    {!testRecordsLoading && testRecords.length > 0 &&
    <>
    <ButtonGroup size="sm" style={{justifyContent:'center', margin: 'auto', marginTop:'1rem', width:'100%', marginBottom:'1rem'}}>
    {<Button aria-label="previous-user-list-page" isDisabled={testRecordsLoading || (testRecordsPage - 1 < 0)} onClick={async () => {
      const a = (testRecordsPage as number) - 1;
      setTestRecordsPage(a);
      await loadTestRecords(a);    
    }}>Prev.</Button>}
    {<Button aria-label="next-user-list-page" isDisabled={testRecordsLoading} onClick={async () => {

      
      
       const a = Math.min((testRecordsPage as number) + 1, (testRecordsManifestAmount / 3) - 1);
       setTestRecordsPage(a);
       await loadTestRecords(a);
      
      }}>Next</Button>}
  </ButtonGroup>
  
    </>}
  </>

    useEffect(() => {
        loadTestRecords(0);
        loadCooldownInfo();
    }, []);
    
    return ( 
        <>
        {setupActive && 
        <Tabs aria-label="Options" style={{display:"flex", justifyContent:"center"}}>
        <Tab key="answerQuestions" title="Answer Questions">
            {setupUi}
        
        </Tab>
        {<Tab onClick={() => {if (!testRecordsLoading) {
            loadTestRecords(testRecordsPage)
        }}} key="review" title="Review My Tests">
            <div style={{display:'block'}}>
            
            {selectedRecord && selectedRecordQuestions !== undefined && selectedRecordQuestions.length > 0 && 
            <>
            <Card className="mt-4 pb-3" style={{width:'60rem', justifyContent:'center', margin:'auto'}} >
              <CardHeader className="flex justify-center items-center mb-2 h-3/6">
                <div className="flex justify-center items-center mb-1 h-3/6">
                  <p style={{justifyContent:'center', margin:'auto', fontWeight:600}}>Review Questions</p>
                </div>
              </CardHeader>
              <Divider style={{width:'90%', justifyContent:'center', margin:'auto'}}/>
              <CardBody>
              {selectedRecordQuestions.length > 1 && <Slider
              style={{marginLeft:"auto", marginRight:"auto", justifyContent:"center", display:"flex"}}
              size="sm"
              step={1}
              color="foreground"
              
              showSteps={true}
              value={reviewQuestion + 1}
              onChange={(e) => {setReviewQuestion(e as number - 1)}}
              maxValue={selectedRecord ? (selectedRecord as any).answered : 0}
              minValue={1}
              defaultValue={1}
              startContent={<Button onClick={() => {setReviewQuestion(reviewQuestion - 1 >= 0 ? reviewQuestion - 1 : reviewQuestion)}} isIconOnly><LeftArrowIcon/></Button>}
              endContent={<Button onClick={() => {setReviewQuestion(reviewQuestion + 1 < (selectedRecord ? (selectedRecord as any).answered : 0) ? reviewQuestion + 1 : reviewQuestion)}} isIconOnly><RightArrowIcon/></Button>}
              className="max-w-md" />}
        
              
              
              
                {selectedRecordQuestions[reviewQuestion] === null ?
                <div className="flex w-full flex-col">
                        <p style={{textAlign:'center'}}>This question has been deleted or otherwise no longer exists likely due to innacuracy or low quality. It has been replaced, apologies for any inconvenience as the question pool of hugo acdec improves.</p>
                            <Tab key="questionLabel" title="Question" isDisabled={true}>
                          
                          </Tab>
                          <Tab isDisabled={true} key="pageAndSection" title="Page & Section">
                            
                          </Tab>
                          <Tab isDisabled={true} key="justification" title="Justification">

                          </Tab>
                </div>
                :
              
              <div className="flex w-full flex-col">
                      <Tabs aria-label="Options" style={{marginLeft:"auto", marginRight:"auto"}}>
                          <Tab key="questionLabel" title="Question">
                              <Card style={{justifyContent:"center", width:"40rem", marginLeft:"auto", marginRight:"auto"}}>
                                      <CardBody >
                                      <p className="font-bold text-inherit text-balance" style={{textWrap:"pretty", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}>{reviewQuestion + 1}. {selectedRecordQuestions[reviewQuestion].question}</p>
                                      </CardBody>
                                  </Card>
                          
                          </Tab>
                          {<Tab isDisabled={selectedRecordQuestions[reviewQuestion].pageNumberStart < 1 && selectedRecordQuestions[reviewQuestion].section === 1} key="pageAndSection" title="Page & Section">
                              <Card style={{justifyContent:"center", width:"19rem", height:"auto", marginLeft:"auto", marginRight:"auto"}}>
                                      <CardBody>
                                          <p style={{justifyContent:"center", marginLeft:"auto", marginRight:"auto", fontWeight:550, fontSize:27, marginTop:"auto", marginBottom:"0.6rem"}}>Section {selectedRecordQuestions[reviewQuestion].section}. Page {(selectedRecordQuestions[reviewQuestion].pageNumberEnd == selectedRecordQuestions[reviewQuestion].pageNumberStart) ? selectedRecordQuestions[reviewQuestion].pageNumberStart?.toString(): selectedRecordQuestions[reviewQuestion].pageNumberStart?.toString() + " - " + selectedRecordQuestions[reviewQuestion].pageNumberEnd?.toString() }</p>
                                      </CardBody>
                                  </Card>
                          </Tab>}
                          {<Tab isDisabled={!selectedRecordQuestions[reviewQuestion].justification} key="justification" title="Justification">
                              <Card style={{justifyContent:"center", width:"40rem", height:"auto", marginLeft:"auto", marginRight:"auto"}}>
                                      <CardBody>
                                        <p className="font-bold text-inherit text-balance" style={{textWrap:"pretty", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}>{selectedRecordQuestions[reviewQuestion].justification}</p>
                                      </CardBody>
                                  </Card>
                          </Tab>}
                          
                      </Tabs>
        
        
        
                      </div>}

                      {
                        selectedRecordQuestions[reviewQuestion] === null ?
                        <>
                        </>
                        :

                      selectedRecord.categories[0] === "math" ?
                       (evaluateMathQuestion(selectedRecordQuestions[reviewQuestion], selectedRecord.freeAnswers[reviewQuestion]).correct)?  
                       <div className="flex justify-center items-center w-1/3 h-3/6" style={{marginBottom:"auto", width:'40%', justifyContent:'center', textAlign:'center', margin:'auto'}}>
                       <Input label="My Answer (Correct)" style={{color:'#5BDD68'}} isReadOnly value={selectedRecord.freeAnswers[reviewQuestion]}/>
                       </div>
                        
                            :
                            <Tabs style={{justifyContent:'center', margin:'auto'}}>
                                <Tab title="Correct Answer">
                                <div className="flex justify-center items-center w-1/3 h-3/6" style={{marginBottom:"auto", width:'40%', justifyContent:'center', textAlign:'center', margin:'auto'}}>
                                    <Input label="Correct Answer" style={{color:'#5BDD68'}} isReadOnly value={evaluateMathQuestion(selectedRecordQuestions[reviewQuestion], selectedRecord.freeAnswers[reviewQuestion]).correctAnswer}/>
                                </div>
                                </Tab>
                                <Tab title="My Answer (Incorrect)">
                                <div className="flex justify-center items-center w-1/3 h-3/6" style={{marginBottom:"auto", width:'40%', justifyContent:'center', textAlign:'center', margin:'auto'}}>
                                    <Input label="My Answer (Incorrect)" isReadOnly value={selectedRecord.freeAnswers[reviewQuestion]}/>
                                </div>
                                </Tab>
                            </Tabs>
                            


                      :
                      
                      selectedRecordQuestions[reviewQuestion].correctAnswer !== selectedRecord.answers[reviewQuestion] ?  
                      
                      <Tabs style={{justifyContent:'center', margin:'auto'}}>
                            <Tab title="Correct Answer">
                            <div className="flex justify-center items-center h-3/6" style={{marginBottom:"auto"}}>
                            <RadioGroup  id="radioGroup" value={selectedRecordQuestions[reviewQuestion].correctAnswer.toString()}>
                                <Radio value="0" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 0 ? "success" : "default"}  >{selectedRecordQuestions[reviewQuestion].answerChoiceA}</Radio>
                                <Radio value="1" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 1 ? "success" : "default"} >{selectedRecordQuestions[reviewQuestion].answerChoiceB}</Radio>
                                <Radio value="2" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 2 ? "success" : "default"}  >{selectedRecordQuestions[reviewQuestion].answerChoiceC}</Radio>
                                <Radio value="3" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 3 ? "success" : "default"} >{selectedRecordQuestions[reviewQuestion].answerChoiceD}</Radio>
                                <Radio value="4" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 4 ? "success" : "default"} >{selectedRecordQuestions[reviewQuestion].answerChoiceE}</Radio>
                            </RadioGroup>
                            </div>
                            </Tab>
                            <Tab title="My Answer (Incorrect)">
                            <div className="flex justify-center items-center h-3/6" style={{marginBottom:"auto"}}>
                            <RadioGroup  id="radioGroup" value={(selectedRecord.answers && selectedRecord.answers.length > 0) ? selectedRecord.answers[reviewQuestion].toString() : "-1" }>
                                <Radio value="0" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 0 ? "success" : "default"}  >{selectedRecordQuestions[reviewQuestion].answerChoiceA}</Radio>
                                <Radio value="1" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 1 ? "success" : "default"} >{selectedRecordQuestions[reviewQuestion].answerChoiceB}</Radio>
                                <Radio value="2" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 2 ? "success" : "default"}  >{selectedRecordQuestions[reviewQuestion].answerChoiceC}</Radio>
                                <Radio value="3" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 3 ? "success" : "default"} >{selectedRecordQuestions[reviewQuestion].answerChoiceD}</Radio>
                                <Radio value="4" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 4 ? "success" : "default"} >{selectedRecordQuestions[reviewQuestion].answerChoiceE}</Radio>
                            </RadioGroup>
                            </div>
                            </Tab>
                        </Tabs>
                        :
                        <div className="flex justify-center items-center h-3/6" style={{marginBottom:"auto"}}>
                        <RadioGroup  id="radioGroup" value={selectedRecordQuestions[reviewQuestion].correctAnswer.toString()}>
                            <Radio value="0" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 0 ? "success" : "default"}  >{selectedRecordQuestions[reviewQuestion].answerChoiceA}</Radio>
                            <Radio value="1" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 1 ? "success" : "default"} >{selectedRecordQuestions[reviewQuestion].answerChoiceB}</Radio>
                            <Radio value="2" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 2 ? "success" : "default"}  >{selectedRecordQuestions[reviewQuestion].answerChoiceC}</Radio>
                            <Radio value="3" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 3 ? "success" : "default"} >{selectedRecordQuestions[reviewQuestion].answerChoiceD}</Radio>
                            <Radio value="4" color={selectedRecordQuestions[reviewQuestion].correctAnswer == 4 ? "success" : "default"} >{selectedRecordQuestions[reviewQuestion].answerChoiceE}</Radio>
                        </RadioGroup>
                        </div>
                        }

                      </CardBody>
                      </Card>
                    </>
                }
            </div>
            {testRecordsLoading && <Spinner style={{justifyContent:'center', margin:'auto'}} color="secondary"/>}
            {!testRecordsLoading && reviewQuestionsUi}
        </Tab>}
        <Tab key="practice-tests" title="Practice Tests">
            <div style={{display:'block', justifyContent:'center', margin:'auto', width:'25%', textAlign:'center'}}>
                <p className="mb-4" style={{color:'white'}}>Put codes here provided by your organizer to take pre-generated practice tests</p>
                <Input className="mb-3" errorMessage={practiceTestError} defaultValue="" isRequired={true} isInvalid={(practiceTestError !== "")} endContent={<PasswordIcon style={{color:'white'}}/>} type="text" maxLength={5} label="Practice Test Code" placeholder="Enter the practice test code from your Organization Administrator" required onChange={(e) => {setPracticeTestCode(e.target.value.trim())}} />
                <Button color="success" style={{color:'white', justifySelf:'center', margin:'auto'}} isDisabled={practiceTestCode.length !== 5} onClick={async () => {
                    try { 
                        if (categories[category] === "" || categories[category] === undefined || category === -1){
                            setCategoryError("Please select a category");
                            return;
                        }

                        if(questionCount < 1 || questionCount > 50 || questionCount === undefined){
                            setQuestionCountError("Question count must be at least 1 and not greater than 50");
                            return
                        }else{
                            setQuestionCountError("");
                        }

                        const r = await checkPracticeTestCodeValid({
                            code: practiceTestCode
                        })

                        console.log(r);
                        

                        if (r.practiceTestValid === true){
                            if (r.canTakeTest === true){
                                setPracticeTestCodeFinal(practiceTestCode);
                        
                                setSetupActive(false);
                            }else{
                                setPracticeTestError("You can only take this test once");
                            }
                        }else{
                            setPracticeTestError("Invalid test code");
                        }

                        
                    } catch (error:any) {
                        setPracticeTestError(error.message);
                    }
                    
                }}>Start</Button>
            </div>
        </Tab>
        
    </Tabs>
    }
        {!setupActive && questionUi}
        </>
     );
}
 
export default QuizzesLoggedInView;