import { useEffect, useState } from "react";
import {CardBody, CardHeader, Popover, Spinner, Card, Divider, Avatar, Chip, PopoverContent, PopoverTrigger, Button, SelectItem, Select, CheckboxGroup, Input, Checkbox, Tabs, Tab, Skeleton, CardFooter} from "@nextui-org/react";
import { Question as QuestionModel } from '../models/question';
import Question from "./Question";
import * as UserApi from "../network/user_api";
import { User } from "../models/user";
import DownArrowIcon from "./Icons/DownArrowIcon";
import SocialScienceIcon from "./Icons/SocialScienceIcon";
import TopCategoryIcon from "./Icons/TopCategoryIcon";
import UpArrowIcon from "./Icons/UpArrowIcon";
import ScienceIcon from "./Icons/ScienceIcon";
import LiteratureIcon from "./Icons/LiteratureIcon";
import { getCategoryIcon } from "../util/categoryIconHelper";

interface SubjectivesProps {
  user : User,
}

const SubjectiveProps = ({user} : SubjectivesProps) => {
    const [text, setText] = useState<any[]>([]);

    useEffect(() => {
        let q = [];
        let u = `- Hello, World!
            - I like cats
                - Sub bullet point`

                for (let i = 0; i < u.length; i++) {
                    q[i] = u.charAt(i);
                
                }

                setText(q);
    }, []);
    

    return (
        <div style={{width:'60%', backgroundColor:'white', borderRadius:'10px', fontSize:14, justifyContent:'center', margin:'auto', marginLeft:'23%', textWrap:'pretty', textOverflow:'clip', padding:'20px'}}>
            
        </div>
    );
}
 
export default SubjectiveProps;