/* eslint-disable eqeqeq */
import styles from "../styles/Question.module.css"
import { Avatar, Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Link, Listbox, ListboxItem, Navbar, NavbarBrand, NavbarContent, NavbarItem, Popover, PopoverContent, PopoverTrigger, Progress, Radio, RadioGroup, Tab, Tabs, Textarea, radioGroup } from "@nextui-org/react";
import { Question as QuestionModel } from "../models/question";
import { useEffect, useState } from "react";
import { checkQuestion, reportQuestion, submitAnswer } from "../network/user_api";

interface QuestionProps {
    question: QuestionModel;
    liveCorrections: Boolean;
    currentQuestionCount: Number;
    answeredSoFar: Number;
    showPageNum: Boolean;
    fullQuestionAmount: Number,
    competitiveMode: Boolean,
    isMathQuestion: Boolean,
    onAnsOneClick: () => void;
    onAnsTwoClick: () => void;
    onAnsThreeClick: () => void;
    onAnsFourClick: () => void;
    onAnsFiveClick: () => void;
    onSubmitClick: (correct:boolean) => void;
    onNextQuestionClick: (freeAnswer?:string) => void;
}

const Question = ({question, fullQuestionAmount, competitiveMode, answeredSoFar, onAnsFiveClick, onAnsFourClick, onAnsOneClick, onAnsThreeClick, onAnsTwoClick, onSubmitClick, onNextQuestionClick, liveCorrections, currentQuestionCount, showPageNum, isMathQuestion}: QuestionProps) => {
    const [ans, setAns] = useState<Number>(-1);
    const [correctAns, setCorrectAns] = useState<Number>(-1);
    const [correctFreeAns, setCorrectFreeAns] = useState("");
    const [notInvalid, setNotInvalid] = useState<Number>(-1);

    const [justification, setJustification] = useState("");

    const [isCountingDown, setIsCountingDown] = useState(false);

    const [timeLeft, setTimeLeft] = useState<number>(isMathQuestion ? 60 : 30);
    const [timerLocked, setTimerLocked] = useState(false);

    const [grabbingResponse, setGrabbingResponse] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [resetTimer, setResetTimer] = useState(false);
    const [reportedQuestion, setReportedQuestion] = useState(false);

    const [reportExplanation, setReportExplanation] = useState("");

    const [mathInputValue, setMathInputValue] = useState("");
    const [showFormattingInstructions, setShowFormattingInstructions] = useState(false);

    const answerArr = ["a", "b", "c", "d", "e"];

    async function countDown() {
        let s = timeLeft;

        if (resetTimer){
            s = isMathQuestion ? 60 : 30;
            setResetTimer(false);
        }

        setIsCountingDown(true);
        await new Promise(f => setTimeout(f, 1000));

        setTimeLeft(s - 1 );
        
        if (timeLeft <= 0){
            setResetTimer(true);
            try {
                submitAnswer({question: question.question, category: question.category ?? "UNK", answer: ans as number, freeAnswer: isMathQuestion ? mathInputValue : ""});
                
            } catch (error) {
                
            }

            setTimeLeft(isMathQuestion ? 60 : 30);
            setTimerLocked(false);
        }
        setIsCountingDown(false);
    }

    useEffect(() => {
        
        if (competitiveMode){
            if (!isCountingDown && !timerLocked){
                countDown();
            }
        }
      });

    return (

        <>
        <div className="flex w-full flex-col">
                    <Progress
                        style={{right:'40px', position:'fixed', width:'10rem'}}
                        aria-label="Questions Left:"
                        size="sm"
                        color="secondary"
                        maxValue={fullQuestionAmount as number}
                        value={answeredSoFar as number}
                        label="Questions Left"
                        showValueLabel={true}
                        valueLabel={currentQuestionCount + ""}
                        className="max-w-md"
                        />
                    {competitiveMode && <Progress
                        style={{right:'40px', position:'fixed', top:'150px', width:'10rem'}}
                        aria-label="Seconds Left"
                        size="sm"
                        color="secondary"
                        maxValue={30}
                        value={timeLeft as number}
                        label="Seconds Left:"
                        showValueLabel={true}
                        valueLabel={timeLeft + ""}
                        className="max-w-md"
                        />}
                        <Popover placement="bottom">
                            <PopoverTrigger>
                                <Button
                                style={{right:'40px', position:'fixed', top:'200px', width:'10rem'}}
                                color="danger"
                                variant="flat"
                                isDisabled={reportedQuestion}
                                >
                                    Report Question
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent style={{paddingBottom:'10px'}}>
                                <Listbox
                                    disabledKeys={reportedQuestion  ? ["innacurate-correct-answer", "innacurate-page-number-or-section", "innacurate-justification", "bad-wording"] : []}
                                    aria-label="Actions"
                                >
                                    {liveCorrections && <ListboxItem onClick={async () => {
                                        
                                        setReportedQuestion(true);
                                        

                                        await reportQuestion({
                                            question: question.question,
                                            answerChoiceA: question.answerChoiceA,
                                            answerChoiceB: question.answerChoiceB,
                                            pageNumberEnd: question.pageNumberEnd,
                                            pageNumberStart: question.pageNumberStart,
                                            section: question.section,
                                            reason: 0,
                                            explanation: reportExplanation
                                        });
                                    }} style={{color:'white'}} color="danger" key="innacurate-correct-answer">Innacurate Correct Answer</ListboxItem>}
                                    {showPageNum && <ListboxItem onClick={async () => {
                                        setReportedQuestion(true);

                                        await reportQuestion({
                                            question: question.question,
                                            answerChoiceA: question.answerChoiceA,
                                            answerChoiceB: question.answerChoiceB,
                                            pageNumberEnd: question.pageNumberEnd,
                                            pageNumberStart: question.pageNumberStart,
                                            section: question.section,
                                            reason: 1,
                                            explanation: reportExplanation
                                        });
                                    }} style={{color:'white'}} color="danger" key="innacurate-page-number-or-section">Innacurate Page Number Or Section</ListboxItem>}
                                    {liveCorrections && <ListboxItem onClick={async () => {
                                        setReportedQuestion(true);

                                        await reportQuestion({
                                            question: question.question,
                                            answerChoiceA: question.answerChoiceA,
                                            answerChoiceB: question.answerChoiceB,
                                            pageNumberEnd: question.pageNumberEnd,
                                            pageNumberStart: question.pageNumberStart,
                                            section: question.section,
                                            reason: 2,
                                            explanation: reportExplanation
                                        });

                                    }} style={{color:'white'}} color="danger" key="innacurate-justification">Innacurate Justification</ListboxItem>}
                                    <ListboxItem onClick={async () => {
                                        setReportedQuestion(true);
                                        await reportQuestion({
                                            question: question.question,
                                            answerChoiceA: question.answerChoiceA,
                                            answerChoiceB: question.answerChoiceB,
                                            pageNumberEnd: question.pageNumberEnd,
                                            pageNumberStart: question.pageNumberStart,
                                            section: question.section,
                                            reason: 3,
                                            explanation: reportExplanation
                                        });
                                    }} style={{color:'white'}} color="danger" key="bad-wording">Bad Or Confusing Wording</ListboxItem>
                                </Listbox>
                                <Textarea isDisabled={reportedQuestion} maxLength={600} onValueChange={setReportExplanation} placeholder="Explain what's wrong with this question (this speeds up the process)"/>
                            </PopoverContent>
                        </Popover>
        <Tabs aria-label="Options" style={{marginLeft:"auto", marginRight:"auto"}}>
            <Tab key="questionLabel" title="Question">
                <Card style={{justifyContent:"center", width:isMathQuestion ? "50%" : "40rem", marginLeft:"auto", marginRight:"auto"}}>
                        <CardBody >
                            <p className="font-bold text-inherit text-balance" style={{textWrap:"pretty", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}>{question.question}</p>
                        </CardBody>
                    </Card>
            
            </Tab>
            { showPageNum && <Tab key="page" title="Section & Page">
            <Card style={{justifyContent:"center", width:"23rem", height:"auto", marginLeft:"auto", marginRight:"auto"}}>
                        <CardBody>
                            <p style={{justifyContent:"center", marginLeft:"auto", marginRight:"auto", fontWeight:550, fontSize:27, marginTop:"auto", marginBottom:"0.6rem"}}>Section {question.section}. Page {(question.pageNumberEnd == question.pageNumberStart) ? question.pageNumberStart?.toString(): question.pageNumberStart?.toString() + " - " + question.pageNumberEnd?.toString() }</p>
                        </CardBody>
                    </Card>
            </Tab>}
            {
                liveCorrections && justification != "" && <Tab key="Justification" title="Justification">
                    <Card style={{justifyContent:"center", width:"40rem", height:"auto", marginLeft:"auto", marginRight:"auto"}}>
                        <CardBody>
                            <p className="font-bold text-inherit text-balance" style={{textWrap:"pretty", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}>{justification}</p>
                        </CardBody>
                    </Card>
                </Tab>
            }
            
        </Tabs>



        </div>

        
        {!isMathQuestion ? <div className="flex justify-center items-center h-3/6" style={{marginBottom:"auto"}}> <RadioGroup id="radioGroup" value={ correctAns != -1 && liveCorrections ? (answerArr[parseInt(correctAns.toString())]) : ans != -1 ? answerArr[parseInt(ans.toString())] : ""}>
            <Radio value="a" color={(correctAns != -1 && liveCorrections && correctAns == 0) ? (correctAns == ans ? "success" : "danger") : "default"} onChange={() => {onAnsOneClick(); if (correctAns == -1) setAns(0)}} >A. {question.answerChoiceA.trim()}</Radio>
            <Radio value="b" color={(correctAns != -1 && liveCorrections && correctAns == 1) ? (correctAns == ans ? "success" : "danger") : "default"} onChange={() => {onAnsTwoClick(); if (correctAns == -1) setAns(1)}} >B. {question.answerChoiceB.trim()}</Radio>
            <Radio value="c" color={(correctAns != -1 && liveCorrections && correctAns == 2) ? (correctAns == ans ? "success" : "danger") : "default"} onChange={() => {onAnsThreeClick(); if (correctAns == -1) setAns(2)}} >C. {question.answerChoiceC.trim()}</Radio>
            <Radio value="d" color={(correctAns != -1 && liveCorrections && correctAns == 3) ? (correctAns == ans ? "success" : "danger") : "default"} onChange={() => {onAnsFourClick(); if (correctAns == -1) setAns(3)}} >D. {question.answerChoiceD.trim()}</Radio>
            {question.answerChoiceE != "" && question.answerChoiceE != undefined && <Radio value="e" color={(correctAns != -1 && liveCorrections && correctAns == 4) ? (correctAns == ans ? "success" : "danger") : "default"} onChange={() => {onAnsFiveClick(); if (correctAns == -1) setAns(4)}} >E. {question.answerChoiceE.trim()}</Radio>}
        
        </RadioGroup>
        </div> : 
        <>
        <div style={{display:'flex', width:'10%', flexWrap:'wrap', justifyContent:'center', textAlign:'center', margin:'auto'}}>
            <Input value={mathInputValue} readOnly={submitted} style={{color:liveCorrections && submitted && !grabbingResponse && correctFreeAns == "" ? '#5BDD68' : 'white'}} size="md" labelPlacement="inside" onValueChange={setMathInputValue} isInvalid={liveCorrections && submitted && !grabbingResponse && correctFreeAns != ""} label={liveCorrections && submitted && !grabbingResponse && correctFreeAns != "" ? "Correct Answer: " + correctFreeAns : "Enter Answer"}/>
            {/*<Button style={{marginTop:'6px'}} onClick={() => {
                setShowFormattingInstructions(!showFormattingInstructions);
            }}>How To Format Answer</Button>*/}

            
        </div>
        {showFormattingInstructions && <Card style={{width:'20%', height:'100px', marginTop:'20px', textAlign:'center', justifyContent:'center', margin:'auto'}}>
        <CardBody>
            <p>Your answer will automatically be mathematically evaluated if the question is asking for a number, for example: 2 * 6 * 2 will be automatically evaluated</p>
        </CardBody>
    </Card>}
    </>
        }
        

        <div className="flex justify-center items-center h-3/6 gap-5" style={{marginTop:"12rem", marginLeft:"auto", marginRight:"auto"}}>
        <Button isDisabled={submitted || (isMathQuestion && mathInputValue == "") || (!isMathQuestion && ans == -1)} isLoading={grabbingResponse} color="default" onPress={async () => {
                
                setTimerLocked(true)

                if (competitiveMode){
                    setResetTimer(true);
                    setGrabbingResponse(true);

                    await submitAnswer({question: question.question, category: question.category ?? "UNK", answer: ans as number, freeAnswer: isMathQuestion ? mathInputValue : ""});
                    
                    setAns(-1);
                    setNotInvalid(-1);
                    setCorrectAns(-1);
                     

                    setTimeLeft(isMathQuestion ? 60 : 30);
                    setTimerLocked(false);
                    setGrabbingResponse(false);
                    setSubmitted(false);
                    setReportedQuestion(false);
                    onNextQuestionClick();
                } else {
                    if (liveCorrections){
                        setGrabbingResponse(true);
                        const r = await checkQuestion({question: question.question, category: question.category ?? "UNK", answer: ans as number, freeAnswer: isMathQuestion ? mathInputValue : ""});
                        onSubmitClick(r.isCorrect); 
                        

                        if (isMathQuestion && !r.isCorrect){
                            setCorrectFreeAns(r.correctAnswer)
                        }else{
                            setCorrectAns(r.correctAnswer);
                        }
                        
                        setJustification(r.justification);
                        setGrabbingResponse(false);
                    }else{
                        setGrabbingResponse(true);
                        submitAnswer({question: question.question, category: question.category ?? "UNK", answer: ans as number, freeAnswer: isMathQuestion ? mathInputValue : ""});
                        onSubmitClick(false);
                        setCorrectAns(1);
                        setGrabbingResponse(false);
                    }
    
                    
                    
    
                    if (liveCorrections){
                        if (ans != correctAns){
                            setNotInvalid(correctAns);
                        }
                    }
                }
                
                if (!competitiveMode){
                    setSubmitted(true);
                }

                }}>
            Submit
        </Button>  
       {(correctAns != -1 || correctFreeAns != "") && <Button color="primary" variant="bordered" onPress={() => {
                onNextQuestionClick(isMathQuestion ? mathInputValue : undefined);

                setSubmitted(false);
                setNotInvalid(-1);
                setCorrectAns(-1);
                setAns(-1);
                setJustification("");

                setMathInputValue("");
                setCorrectFreeAns("");
                
                setTimeLeft(isMathQuestion ? 60 : 30);
                setTimerLocked(false);
                setReportedQuestion(false);
                setReportExplanation("");
            }}>
            Continue
        </Button> }
        </div>
    </>
        

        
        
      );
};

/*
<>
            <p style={{padding:"0rem", marginLeft:"57rem", color:"#E0FBFC", fontFamily:"GothamMedium", marginTop:"0.2rem"}}>{currentQuestionCount + " Question(s) Remaining"} </p>
            <div>
                <h1 className={styles.questionHeadText}>
                    {question.question} 
                </h1>
                
                {question.pageNumberStart != 0 && showPageNum && <h2 className={styles.questionHeadText}>
                    Page {(question.pageNumberEnd == question.pageNumberStart) ? question.pageNumberStart?.toString(): question.pageNumberStart?.toString() + " - " + question.pageNumberEnd?.toString() } - Social Sci.
                </h2>
                }
                
            </div>
            

            <Container>
                <Row xs={1} md={2} lg={3} className="g-4">
                    <Col className={styles.questionColumn}>
                        <Card className={(liveCorrections) ? (correctAns == -1) ? (ans === 0 ? styles.questionCardChosen : styles.questionCard) : (correctAns == 0 ? styles.questionCardCorrect : ans == 0 ? styles.questionCardIncorrect : styles.questionCard) : (ans === 0 ? styles.questionCardChosen : styles.questionCard)} onClick={() => {onAnsOneClick(); if (correctAns == -1) setAns(0)}} id="card0">
                            <Card.Body >
                                <Card.Title>A</Card.Title>
                                
                                    <Card.Text className={styles.questionText}>
                                    {question.answerChoiceA}
                                    </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                

                    <Col className={styles.questionColumn}>
                        <Card className={(liveCorrections) ? (correctAns == -1) ? (ans === 1 ? styles.questionCardChosen : styles.questionCard) : (correctAns == 1 ? styles.questionCardCorrect : ans == 1 ? styles.questionCardIncorrect : styles.questionCard) : (ans === 1 ? styles.questionCardChosen : styles.questionCard)} onClick={() => {onAnsTwoClick(); if (correctAns == -1) setAns(1)}} id="card1">
                            <Card.Body>
                                <Card.Title>B</Card.Title>
                                <Card.Text className={styles.questionText}>
                                    {question.answerChoiceB}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    

                    <Col className={styles.questionColumn}>
                        <Card className={(liveCorrections) ? (correctAns == -1) ? (ans === 2 ? styles.questionCardChosen : styles.questionCard) : (correctAns == 2 ? styles.questionCardCorrect : ans == 2 ? styles.questionCardIncorrect : styles.questionCard) : (ans === 2 ? styles.questionCardChosen : styles.questionCard)} onClick={() => {onAnsThreeClick(); if (correctAns == -1) setAns(2)}} id="card2">
                            <Card.Body>
                                <Card.Title>C</Card.Title>
                                <Card.Text className={styles.questionText}>
                                {question.answerChoiceC}
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className={(liveCorrections) ? (correctAns == -1) ? (ans === 4 ? styles.questionCardChosen : styles.questionCard) : (correctAns == 4 ? styles.questionCardCorrect : ans == 4 ? styles.questionCardIncorrect : styles.questionCard) : (ans === 4 ? styles.questionCardChosen : styles.questionCard)} style={{marginTop:"2rem"}} onClick={() => {onAnsFourClick(); if (correctAns == -1) setAns(4)}} id="card4">
                            <Card.Body>
                                <Card.Title>E</Card.Title>
                                <Card.Text className={styles.questionText}>
                                {question.answerChoiceE}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    

                    <Col className={styles.questionColumn}>
                        <Card className={(liveCorrections) ? (correctAns == -1) ? (ans === 3 ? styles.questionCardChosen : styles.questionCard) : (correctAns == 3 ? styles.questionCardCorrect : ans == 3 ? styles.questionCardIncorrect : styles.questionCard) : (ans === 3 ? styles.questionCardChosen : styles.questionCard)} onClick={() => {onAnsOneClick(); if (correctAns == -1) setAns(3)}} id="card3">
                            <Card.Body>
                                <Card.Title>D</Card.Title>
                                <Card.Text className={styles.questionText}>
                                {question.answerChoiceD}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    
                    <Col className={styles.questionColumn}>
                        
                    </Col>
                </Row>
            </Container>

            <Button className={styles.questionSubmitButton} onClick={() => {
                console.log(ans);
                console.log(question.correctAnswer === ans);
                
                
                
                onSubmitClick(ans === question.correctAnswer); setCorrectAns(question.correctAnswer)}}>
                Submit Answer
            </Button>

            {correctAns != -1 && <Button className={styles.questionSubmitButton} style={{marginLeft:"6rem"}} onClick={() => {
                onNextQuestionClick();

                setCorrectAns(-1);
                setAns(-1);
                
            }}>
                Next Question 
            </Button>}
        </>
*/

export default Question;