import { SetStateAction, useEffect, useState } from "react";
import { Pagination, avatar, Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Link, Listbox, ListboxItem, Navbar, NavbarBrand, NavbarContent, NavbarItem, Radio, RadioGroup, Spinner, Tab, Tabs, User as UiUser, Tooltip, ButtonGroup } from "@nextui-org/react";
import { User } from "../models/user";
import * as UserApi from "../network/user_api"
import { set } from "react-hook-form";
import GemLeagueIcon from "./Icons/GemLeagueIcon";
import InteractiveProfile from "./InteractiveProfile";
import TopTenIcon from "./Icons/TopTenIcon";

interface LeaderboardLoggedInViewProps {
    loggedInUser: User
}

const LeaderboardLoggedInView = ({loggedInUser}: LeaderboardLoggedInViewProps) => {

    const [leaderboardLoading, setLeaderboardLoading] = useState(true);
    const [leaderboardUsers, setLeaderboardUsers] = useState<any[]>([]);
    const [leaderboardPage, setLeaderboardPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [leaderboardUserAmount, setLeaderboardUserAmount] = useState(0);

    async function loadUsers(page:number){
        try {
            setLeaderboardLoading(true);
  
            const r = await UserApi.getLeaderboard({page:page});

            setLeaderboardUsers(r.users);
            setPageCount(r.pages);

            const f = await UserApi.getLeaderboardManifest();
            setLeaderboardUserAmount(f.amount);

        } catch (error) {
          console.log(error);
          alert(error);
        } finally {
            setLeaderboardLoading(false);
        }
        
      }
        
    useEffect(() => {
        loadUsers(0);
    }, []);

    async function loadNextPage(page: number) {
        setLeaderboardPage(page);
        await loadUsers(page);
    }
  
    async function loadPreviousPage(page: number) {
        setLeaderboardPage(page);
        await loadUsers(page);
    }
    
    
    /*
    const leaderboardUi = <> <h2>Leaderboard</h2> <CardGroup>
    <Card border="warning" style={{ width: '26rem', height:"19rem", marginTop: "5rem", backgroundColor: "#373E40", color: "#E0FBFC" }}>
    <Card.Body style={{backgroundColor:"#373E40"}}>
        <Card.Title style={{fontFamily:"Baron"}}>1st Place</Card.Title>
        <Card.Subtitle style={{fontSize:20, fontFamily:"Baron"}}>
        {!leaderboardLoading && leaderboardUsers[0].username}
        </Card.Subtitle>
        <Card.Text style={{marginTop:50, fontFamily:"GothamMedium"}}>
        {!leaderboardLoading && sumCorrect[0]} Questions Correct
        </Card.Text>
        <Card.Text style={{marginTop:10, fontFamily:"GothamMedium"}}>
        Correct/Incorrect Ratio: {(sumCorrect[0]/sumAnswered[0]).toString().substring(0,4)}
        </Card.Text>
        <Card.Text style={{marginTop:10, fontFamily:"GothamMedium"}}>
            Best Category: {highestCategories[0]}
        </Card.Text>
        <Card.Text style={{marginTop:10, fontFamily:"GothamMedium"}}>
            Worst Category: {lowestCategories[0]}
        </Card.Text>
    </Card.Body>
    </Card>
    <Card border="info" style={{ width: '26rem', height:"19rem", marginTop: "5rem", backgroundColor: "#373E40", color: "#E0FBFC" }}>
    <Card.Body style={{backgroundColor:"#373E40"}}>
        <Card.Title style={{fontFamily:"Baron"}}>2nd Place</Card.Title>
        <Card.Subtitle style={{fontSize:20, fontFamily:"Baron"}}>
        {!leaderboardLoading && leaderboardUsers[1].username}
        </Card.Subtitle>
        <Card.Text style={{marginTop:50, fontFamily:"GothamMedium"}}>
        {!leaderboardLoading && sumCorrect[1]} Questions Correct
        </Card.Text>
        <Card.Text style={{marginTop:10, fontFamily:"GothamMedium"}}>
        Correct/Incorrect Ratio: {(sumCorrect[1]/sumAnswered[1]).toString().substring(0,4)}
        </Card.Text>
        <Card.Text style={{marginTop:10, fontFamily:"GothamMedium"}}>
            Best Category: {highestCategories[1]}
        </Card.Text>
        <Card.Text style={{marginTop:10, fontFamily:"GothamMedium"}}>
            Worst Category: {lowestCategories[1]}
        </Card.Text>
    </Card.Body>
    </Card>
    <Card border="dark" style={{ width: '26rem', height:"19rem", marginTop: "5rem", backgroundColor: "#373E40", color: "#E0FBFC" }}>
    <Card.Body style={{backgroundColor:"#373E40"}}>
        <Card.Title style={{fontFamily:"Baron"}}>3rd Place</Card.Title>
        <Card.Subtitle style={{fontSize:20, fontFamily:"Baron"}}>
        {!leaderboardLoading && leaderboardUsers[2].username}
        </Card.Subtitle>
        <Card.Text style={{marginTop:50, fontFamily:"GothamMedium"}}>
        {!leaderboardLoading && sumCorrect[2]} Questions Correct
        </Card.Text>
        <Card.Text style={{marginTop:10, fontFamily:"GothamMedium"}}>
        Correct/Incorrect Ratio: {(sumCorrect[2]/sumAnswered[2]).toString().substring(0,4)}
        </Card.Text>
        <Card.Text style={{marginTop:10, fontFamily:"GothamMedium"}}>
            Best Category: {highestCategories[2]}
        </Card.Text>
        <Card.Text style={{marginTop:10, fontFamily:"GothamMedium"}}>
            Worst Category: {lowestCategories[2]}
        </Card.Text>
    </Card.Body>
    </Card>
</CardGroup></>


// !!!!!!!!!!!!!!!


<ListboxItem
                key="first"
                endContent={1}
                startContent={<GemLeagueIcon/>}
                style={{height:'5rem'}}
            >
                <InteractiveProfile content={<UiUser
                                name="Hayden Karp"
                                description="Academic Weapon"
                                avatarProps={{
                                    src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                                }} />} friendIndicatorPosition={undefined} withStats={true} contextContent={undefined} popoverPosition={"right"} isSelf={false} user={undefined}/>
            </ListboxItem>
            <ListboxItem
                key="first"
                endContent={2}
                startContent={<GemLeagueIcon/>}
                style={{height:'5rem'}}
            >
                <UiUser 
                name="Anonymous"
                
                />
            </ListboxItem>
            <ListboxItem
                key="first"
                endContent={3}
                startContent={<GemLeagueIcon/>}
                style={{height:'5rem'}}
            >
                <InteractiveProfile content={<UiUser
                                name="Hayden Karp"
                                description="Academic Weapon"
                                avatarProps={{
                                    src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                                }} />} friendIndicatorPosition={undefined} withStats={true} contextContent={undefined} popoverPosition={"right"} isSelf={false} user={undefined}/>
            </ListboxItem>

            <ListboxItem
                key="first"
                endContent={4}
                startContent={<TopTenIcon/>}
                style={{height:'5rem'}}
            >
                <InteractiveProfile content={<UiUser
                                name="Hayden Karp"
                                description="Academic Weapon"
                                avatarProps={{
                                    src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                                }} />} friendIndicatorPosition={undefined} withStats={true} contextContent={undefined} popoverPosition={"right"} isSelf={false} user={undefined}/>
            </ListboxItem>

            <ListboxItem
                key="first"
                endContent={5}
                startContent={<TopTenIcon/>}
                style={{height:'5rem'}}
            >
                <InteractiveProfile content={<UiUser
                                name="Hayden Karp"
                                description="Academic Weapon"
                                avatarProps={{
                                    src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                                }} />} friendIndicatorPosition={undefined} withStats={true} contextContent={undefined} popoverPosition={"right"} isSelf={false} user={undefined}/>
            </ListboxItem>

            <ListboxItem
                key="first"
                endContent={6}
                startContent={<TopTenIcon/>}
                style={{height:'5rem'}}
            >
                <InteractiveProfile content={<UiUser
                                name="Hayden Karp"
                                description="Academic Weapon"
                                avatarProps={{
                                    src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                                }} />} friendIndicatorPosition={undefined} withStats={true} contextContent={undefined} popoverPosition={"right"} isSelf={false} user={undefined}/>
            </ListboxItem>

            <ListboxItem
                key="first"
                endContent={7}
                startContent={<TopTenIcon/>}
                style={{height:'5rem'}}
            >
                <InteractiveProfile content={<UiUser
                                name="Hayden Karp"
                                description="Academic Weapon"
                                avatarProps={{
                                    src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                                }} />} friendIndicatorPosition={undefined} withStats={true} contextContent={undefined} popoverPosition={"right"} isSelf={false} user={undefined}/>
            </ListboxItem>
            <ListboxItem
                key="first"
                endContent={8}
                startContent={<TopTenIcon/>}
                style={{height:'5rem'}}
            >
                <InteractiveProfile content={<UiUser
                                name="Hayden Karp"
                                description="Academic Weapon"
                                avatarProps={{
                                    src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                                }} />} friendIndicatorPosition={undefined} withStats={true} contextContent={undefined} popoverPosition={"right"} isSelf={false} user={undefined}/>
            </ListboxItem>
            <ListboxItem
                key=""
                endContent={9}
                startContent={<TopTenIcon/>}
                style={{height:'5rem'}}
            >
                <InteractiveProfile content={<UiUser
                                name="Hayden Karp"
                                description="Academic Weapon"
                                avatarProps={{
                                    src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                                }} />} friendIndicatorPosition={undefined} withStats={true} contextContent={undefined} popoverPosition={"right"} isSelf={false} user={undefined}/>
            </ListboxItem>




*/

    return ( 
        <>
        

        {leaderboardLoading && <Spinner color="secondary" style={{marginLeft:"auto", marginRight:"auto", justifyContent:"center", display:"flex"}}/>}
        { !leaderboardLoading && <>
        <div className="flex-col justify-center">
            <Listbox
            aria-label="User Menu"
            style={{width: '25%', minWidth:'25%', justifyContent:'center', margin:'auto'}}
            className="p-0 gap-0 divide-y divide-default-300/50 dark:divide-default-100/80 bg-content1 max-w-[300px] overflow-visible shadow-small rounded-medium"
            itemClasses={{
                base: "px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
            }}
            >

            
                {leaderboardUsers.length > 0 ? leaderboardUsers.map((u) => {
                        return (
                        <ListboxItem
                            key={u._id}
                            endContent={<p style={{fontWeight:600}}>{u.leaderboardRank + (leaderboardPage * 8) + 1}</p>}
                            startContent={(u.leaderboardRank + (leaderboardPage * 8) ) < 3 ? <GemLeagueIcon /> : u.leaderboardRank  + (leaderboardPage * 8) < 10 ? <TopTenIcon/> : <></>}
                            style={{ height: '5rem' }}
                        >
                        {u.username !== "Anonymous" ? <InteractiveProfile
                          loggedInUser={loggedInUser}
                          content={
                            <div>
                            {
                              u.profilePicture !== "" && <img src={u.profilePicture} style={{width: '40px', height: '40px', position:'absolute', zIndex:1, borderRadius:'50px'}} alt=""/>
                            }
                            <UiUser
                            
                              name={u.username}
                              description={u.status}
                              avatarProps={{
                                alt: u.username,
                                showFallback: u.profilePicture === "",
                                ignoreFallback: false,
                                getInitials: (name:string) => {
                                  return name.split(" ")[0][0];
                                },
                              }}
                            />
                            </div>
                          }
                          friendIndicatorPosition={
                            loggedInUser && loggedInUser?.friends.includes(u._id)
                              ? "top-right"
                              : undefined
                          }
                          withStats={true}
                          contextContent={undefined}
                          popoverPosition={"right"}
                          isSelf={false}
                          user={u}
                        />
                        :
                       
                        <div>
                            {
                              u.profilePicture !== "" && <img src={u.profilePicture} style={{width: '40px', height: '40px', position:'fixed', zIndex:1, borderRadius:'50px'}} alt=""/>
                            }
                             <Tooltip style={{color:'white'}} delay={400} content="This user has set their leaderboard rank to be private">
                            <UiUser
                            
                              name={u.username}
                              description={u.status}
                              avatarProps={{
                                alt: u.username,
                                showFallback: u.profilePicture === "",
                                ignoreFallback: false,
                                getInitials: (name:string) => {
                                  return name.split(" ")[0][0];
                                },
                              }}
                            />
                            </Tooltip>
                            </div>
                            }
                        </ListboxItem>
                        );
                    }
                ) 
                :
                    <ListboxItem key={"none"}>
                        <p>No users yet!</p>
                    </ListboxItem>
                }
                
                
                
            
            </Listbox>
            
                {
                    leaderboardUserAmount && !leaderboardLoading ?
                    <div
                    style={{width: '50%', minWidth:'25%', justifyContent:'center', margin:'auto', marginTop:'1rem'}}
                    >
                    <div style={{justifyContent:'center', margin:'auto', width:'100%'}} key={""}>
                    <ButtonGroup size="sm" style={{justifyContent:'center', margin: 'auto', textAlign:'center', width:'100%'}}>
                        {leaderboardUsers.length > 0 && leaderboardPage > 0 && <Button aria-label="previous-user-list-page" onClick={(e) => {
                            setLeaderboardUsers([]);
                            loadPreviousPage(leaderboardPage - 1);
                        }}>Prev.</Button>}
                        {leaderboardPage < pageCount && <Button aria-label="next-user-list-page" onClick={(e) => {
                            setLeaderboardUsers([]);
                            loadNextPage(leaderboardPage + 1);
                        }}>Next</Button>}
                    </ButtonGroup>
                    </div>
                    </div>
                    :
                    <></>
                    
                }
            
            
        </div>
        
        
            
            
        
        </>}
        </>
        
     );
}
 
export default LeaderboardLoggedInView;