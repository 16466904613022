import { PopoverTrigger, PopoverContent, Avatar, Divider, Chip, Popover, Badge, Card, CardHeader, CardBody, CardFooter, Button, User as UiUser, Tooltip  } from '@nextui-org/react';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import ArtIcon from './Icons/ArtIcon';
import BadgeIcon from './Icons/BadgeIcon';
import EconIcon from './Icons/EconIcon';
import FriendIndicatorIcon from './Icons/FriendIndicatorIcon';
import LeaderboardRankIcon from './Icons/LeaderboardRankIcon';
import LiteratureIcon from './Icons/LiteratureIcon';
import MathIcon from './Icons/MathIcon';
import MusicIcon from './Icons/MusicIcon';
import ScienceIcon from './Icons/ScienceIcon';
import SocialScienceIcon from './Icons/SocialScienceIcon';
import ProfileViewStatsIcon from './Icons/ProfileViewStatsIcon';
import { User } from '../models/user';
import { getCategoryChip, getCategoryIcon, grabCompetitiveIdentifier } from '../util/categoryIconHelper';
import { getProfile, removeFriend, sendFriendRequest } from '../network/user_api';
import DustIcon from './Icons/DustIcon';
import { VarsityIcon } from './Icons/VarsityIcon';
import { ScholasticIcon } from './Icons/ScholasticIcon';
import { HonorsIcon } from './Icons/HonorsIcon';
import { CoachIcon } from './Icons/CoachIcon';
import SparklesIcon from './Icons/SparklesIcon';

interface InteractiveProfileProps {
    loggedInUser: User | null,
    user: User,
    content : ReactNode,
    contextContent: ReactNode | undefined,
    friendIndicatorPosition : "top-left" | "top-right" | "bottom-right" | "bottom-left" | undefined,
    popoverPosition: "top" | "bottom" | "left" | "right" | undefined,
    withStats: boolean,
    isSelf: boolean,
    org?: any
}

const InteractiveProfile = (props: InteractiveProfileProps) => {
  const [profUser, setProfUser] = useState<any>(props.user);
  
  
  const categories = ["socialScience", "science", "math", "literature", "econ", "art", "music"];

  async function updateUser() {
    const r = await getProfile({username:props.user.username, self:props.isSelf});
    setProfUser(r);
  }

  useEffect(() => {
    updateUser();
  }, []);

    return (
    <Popover placement={props.popoverPosition}>
              <Badge
                    isOneChar
                    hidden={props.friendIndicatorPosition === undefined}
                    content={<FriendIndicatorIcon />}
                    showOutline={false}
                    color="success"
                    shape="circle"
                    placement={props.friendIndicatorPosition}
                  >
                <PopoverTrigger onClick={updateUser}>
                  
                    {props.content}
                  
                </PopoverTrigger>
                </Badge>

                <PopoverContent>
                <Card className="max-w-[400px]">
                  <CardHeader className="flex gap-3">
                    {profUser.profilePicture !== "" && <img src={profUser.profilePicture} style={{width: '40px', height: '40px', position:'fixed', zIndex:1, borderRadius:'50px'}} alt=""/>}
                    <Avatar 
                      alt={profUser.username}
                      showFallback={true}
                      ignoreFallback={false}
                      getInitials={(name) => {
                        return name.split(" ")[0][0];
                      }}
                    />
                    <div className="flex flex-col">
                      <p className="text-md">{profUser.username}</p>
                      <p className="text-small text-default-500">{profUser.status}</p>
                    </div>
                    {getCategoryIcon(categories[profUser.focusedCategory as number])}
                    {!profUser.isLeaderboardAnonymous && profUser.leaderboardRank < 3 ? 
                    profUser.leaderboardRank === 0 ? <SparklesIcon color='#FCB128'/> 
                    :
                    profUser.leaderboardRank === 1 ? <SparklesIcon color='#9DB4C0'/>
                    :
                    <SparklesIcon color='#CC925F'/>
                    :
                    <></>
                    
                    }
                  </CardHeader>
                  <Divider/>
                  <CardBody>
                    {
                      profUser.bio && profUser.bio.trim() !== "" ?
                      <>
                      <p style={{marginBottom:'1rem', width:'16rem'}}>{profUser.bio}</p>
                      
                    
                      <Divider className="mb-2"/>
                      </>
                      :
                      <p style={{marginBottom:'1rem', width:'16rem', color:'gray', textAlign:'center'}}><i>{profUser.username} does not have a bio</i></p>
                    }
                    <div className="flex" style={{justifyContent: 'center',}}>
                      <BadgeIcon className="mb-3 mr-2"/> 
                      <p style={{fontWeight:'600'}}>Earned Badges</p>
                    </div>
                    <div style={{width:'16rem', textAlign: (profUser.badges.length < 2 ? 'center' : 'inherit'), paddingRight:(profUser.badges.length < 2 ? '1rem' : '0.5rem'), paddingLeft:(profUser.badges.length < 2 ? '1rem' : '0.5rem'), overflow:'auto', display:'-ms-flexbox', marginBottom:'0.1rem'}}>
                      {profUser.badges.length === 0 && <div style={{display:'flex', marginBottom:'0.4rem'}}><p style={{marginBottom:'1rem', width:'16rem', color:'gray', textAlign:'center'}}><i>Nothing to see here (yet!)</i></p> <DustIcon style={{marginLeft:'0.3rem', width:'1.5rem', height:'1.5rem'}}/></div>}
                      {profUser.badges.map((badge:any) => {
                        if (getCategoryChip(badge) === undefined){
                          switch (badge) {
                            case "varsity":
                              return (<Chip startContent={<VarsityIcon/>} style={{color:'white', backgroundColor: '#175676', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
                                Varsity
                              </Chip>)
                            case "scholastic":
                              return (<Chip startContent={<ScholasticIcon/>} style={{color:'white', backgroundColor: '#175676', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
                                Scholastic
                              </Chip>)
                            case "honors":
                              return (<Chip startContent={<HonorsIcon/>} style={{color:'white', backgroundColor: '#175676', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
                                Honors
                              </Chip>)
                            case "coach":
                              return (<Chip startContent={<CoachIcon/>} style={{color:'white', backgroundColor: '#549F93', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
                                Coach
                              </Chip>)
                            default:
                              return (<Chip style={{color:'white', backgroundColor: '#175676', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
                                {badge}
                              </Chip>)
                              
                          }
                          
                        }else{
                          return getCategoryChip(badge);
                        }
                      })}

                      
                    
                    </div>
                    {props.withStats && profUser && (profUser.bestCategory || profUser.bestCategoryPrivate) && <> 

                        <Divider className="mb-2"/>
                    <div className="flex" style={{justifyContent: 'center'}}>
                      <ProfileViewStatsIcon className="mb-3 mr-2"/> 
                      <p style={{fontWeight:'600'}}>Stats</p>
                    </div>
                    <div style={{width:'16rem', overflow:'auto', display:'-ms-flexbox', justifyContent:'center'}}>
                      {profUser && (!props.user.totalQuestionsPrivate || !props.user.correctnessPrivate || !props.user.bestCategoryPrivate || !props.user.worstCategoryPrivate) ?
                      <>
                      {!props.user.totalQuestionsPrivate && <p style={{fontWeight:'600'}}>Total Questions Correct: {profUser.totalQuestionsCorrect.toString()}</p>}
                      {!props.user.correctnessPrivate && !isNaN(profUser.correctness) && <p style={{fontWeight:'600'}}>Questions Correct Percentage: {Math.round(profUser.correctness * 100).toString()}%</p>}
                      {!props.user.bestCategoryPrivate && <p style={{fontWeight:'600'}}>Best Category: {grabCompetitiveIdentifier(profUser.bestCategory).replace("SocialScience", "Social Science")}</p>}
                      {!props.user.worstCategoryPrivate && <p style={{fontWeight:'600'}}>Worst Category: {grabCompetitiveIdentifier(profUser.worstCategory).replace("SocialScience", "Social Science")}</p>}
                      </>
                      :
                      <p style={{fontWeight:'600'}}>All Stats Private</p>
                      }

                      
                    </div>
                    <Divider className="mb-2 mt-3"/>
                    </>}
                    
                    <div className="flex" style={{justifyContent: 'center'}}>
                      <LeaderboardRankIcon className="mb-1 mr-2"/> 
                      <p style={{fontWeight:'600'}}>Leaderboard Rank</p>
                    </div>
                    <div className="flex" style={{justifyContent: 'center'}}>
                      {profUser && 
                        <p style={{fontWeight:'600'}}>{profUser.isLeaderboardAnonymous || isNaN(profUser.leaderboardRank + 1) ? "Private" : (profUser.leaderboardRank + 1).toString()}.</p>
                      }
                      
                    </div>
                  </CardBody>
                  <Divider/>
                  <CardFooter>
                    {props.contextContent !== undefined && props.contextContent}
                    {props.contextContent === undefined && props.loggedInUser && props.loggedInUser.username !== profUser.username && !props.loggedInUser.friends.includes(profUser._id) &&
                    <Button color="success" variant='bordered' style={{color:'white'}} onClick={() => {sendFriendRequest({recipient:profUser.username})}}>
                      Add Friend
                    </Button>}

                    {props.contextContent === undefined && props.loggedInUser && props.loggedInUser.username !== profUser.username && props.loggedInUser.friends.includes(profUser._id) &&
                    <Button color="danger" variant='bordered' style={{color:'white'}} onClick={() => {removeFriend({removeFriendId:profUser._id})}}>
                      Remove Friend
                    </Button>}
                  </CardFooter>
                </Card>
                </PopoverContent>
              </Popover>)
}

export default InteractiveProfile