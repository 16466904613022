import { User } from "../models/user";

import {
  Avatar,
  Badge,
  PopoverContent,
  PopoverTrigger,
  Popover,
  CardFooter,
  CardBody,
  Link,
  Divider,
  Image,
  Card, 
  CardHeader, 
  Button,
  Chip,
  Pagination,
  Skeleton,
  ButtonGroup
} from "@nextui-org/react";
import { User as UiUser } from "@nextui-org/react";
import SidebarBackUnfilledIcon from "./Icons/SidebarBackUnfilledIcon";
import { useEffect, useState } from "react";
import SidebarBackFilledIcon from "./Icons/SidebarBackFilledIcon";
import SidebarExpandFilledIcon from "./Icons/SidebarExpandFilledIcon";
import SidebarExpandUnfilledIcon from "./Icons/SidebarExpandUnfilledIcon";
import FriendIndicatorIcon from "./Icons/FriendIndicatorIcon";
import SocialScienceIcon from "./Icons/SocialScienceIcon";
import BadgeIcon from "./Icons/BadgeIcon";
import ScienceIcon from "./Icons/ScienceIcon";
import MathIcon from "./Icons/MathIcon";
import LiteratureIcon from "./Icons/LiteratureIcon";
import EconIcon from "./Icons/EconIcon";
import LeaderboardRankIcon from "./Icons/LeaderboardRankIcon";
import ArtIcon from "./Icons/ArtIcon";
import MusicIcon from "./Icons/MusicIcon";
import InteractiveProfile from "./InteractiveProfile";
import { getOrganizationInfo, grabPublicUsersInOrganization, grabPublicUsersManifest } from "../network/user_api";
import React from "react";

interface SidebarMenuProps {
  loggedInUser: User | null;
}

const Sidebar = ({ loggedInUser }: SidebarMenuProps) => {
  const [backHover, setBackHover] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [userList, setUserList] = useState<User[]>([]);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [orgInfo, setOrgInfo] = useState<any>();
  const [userListPage, setUserListPage] = useState<number>(0)
  const [pageCount, setPageCount] = useState(0);
  const [usersFullyLoaded, setUsersFullyLoaded] = useState(false);


  async function populateUserList(pageNumber: number) {
      
      setUsersFullyLoaded(false);
      setUsersLoaded(false);
      setUserList([]);

      if (loggedInUser?.organization !== "NULLL"){
        const h = await getOrganizationInfo({orgCode:loggedInUser?.organization ?? "NULLL"});
        setOrgInfo(h);
      }


      if (loggedInUser?.organization !== "NULLL" && orgInfo && !orgInfo.socialFeaturesEnabled){
        setUserList([]);
        setUsersLoaded(true);
        setUsersFullyLoaded(true);
        return;
      }
      
      const a: any = await grabPublicUsersManifest({orgCode: loggedInUser?.organization ?? "", page: pageNumber});


      setUserList(a.users);
      setPageCount(a.pageAmount);
      setUsersLoaded(true);

      const f : any = await grabPublicUsersInOrganization({clientOfOriginUsername:loggedInUser?.username ?? "", orgCode:loggedInUser?.organization ?? "NULLL", page: pageNumber});
      setUsersFullyLoaded(true);

      if (f && (Math.max(pageNumber, userListPage) - Math.min(userListPage, pageNumber) < 2)){
        setUserList(f);
      }

      
  }

  useEffect(() => {
    populateUserList(0);
}, []);

  return (
    <>
      {!hidden && (
        <div className="sidenav">
          <div className="corner" />
          <>

          {!usersLoaded && 
          <>
          <a>
              <div className="max-w-[100px] flex items-center gap-1" style={{width:'130px'}}>
              <div>
                <Skeleton className="flex rounded-full w-12 h-12"/>
              </div>  
              <div className="w-full flex flex-col gap-2">
                <Skeleton className="h-3 w-3/5 rounded-lg" style={{borderRadius:'10px'}}/>
                <Skeleton className="h-3 w-4/5 rounded-lg" style={{borderRadius:'10px'}}/>
              </div>
            </div>
            </a>
            <a>
              <div className="max-w-[100px] flex items-center gap-1" style={{width:'130px'}}>
              <div>
                <Skeleton className="flex rounded-full w-12 h-12"/>
              </div>  
              <div className="w-full flex flex-col gap-2">
                <Skeleton className="h-3 w-3/5 rounded-lg" style={{borderRadius:'10px'}}/>
                <Skeleton className="h-3 w-4/5 rounded-lg" style={{borderRadius:'10px'}}/>
              </div>
            </div>
            </a>
            <a>
              <div className="max-w-[100px] flex items-center gap-1" style={{width:'130px'}}>
              <div>
                <Skeleton className="flex rounded-full w-12 h-12"/>
              </div>  
              <div className="w-full flex flex-col gap-2">
                <Skeleton className="h-3 w-3/5 rounded-lg" style={{borderRadius:'10px'}}/>
                <Skeleton className="h-3 w-4/5 rounded-lg" style={{borderRadius:'10px'}}/>
              </div>
            </div>
            </a>
            <a>
              <div className="max-w-[100px] flex items-center gap-1" style={{width:'130px'}}>
              <div>
                <Skeleton className="flex rounded-full w-12 h-12"/>
              </div>  
              <div className="w-full flex flex-col gap-2">
                <Skeleton className="h-3 w-3/5 rounded-lg" style={{borderRadius:'10px'}}/>
                <Skeleton className="h-3 w-4/5 rounded-lg" style={{borderRadius:'10px'}}/>
              </div>
            </div>
            </a>

            <a>
          <div className="max-w-[100px] flex items-center gap-1" style={{width:'130px'}}>
          <div>
            <Skeleton className="flex rounded-full w-12 h-12"/>
          </div>  
          <div className="w-full flex flex-col gap-2">
            <Skeleton className="h-3 w-3/5 rounded-lg" style={{borderRadius:'10px'}}/>
            <Skeleton className="h-3 w-4/5 rounded-lg" style={{borderRadius:'10px'}}/>
          </div>
        </div>
        </a>

        <a>
          <div className="max-w-[100px] flex items-center gap-1" style={{width:'130px'}}>
          <div>
            <Skeleton className="flex rounded-full w-12 h-12"/>
          </div>  
          <div className="w-full flex flex-col gap-2">
            <Skeleton className="h-3 w-3/5 rounded-lg" style={{borderRadius:'10px'}}/>
            <Skeleton className="h-3 w-4/5 rounded-lg" style={{borderRadius:'10px'}}/>
          </div>
        </div>
        </a>

        <a>
          <div className="max-w-[100px] flex items-center gap-1" style={{width:'130px'}}>
          <div>
            <Skeleton className="flex rounded-full w-12 h-12"/>
          </div>  
          <div className="w-full flex flex-col gap-2">
            <Skeleton className="h-3 w-3/5 rounded-lg" style={{borderRadius:'10px'}}/>
            <Skeleton className="h-3 w-4/5 rounded-lg" style={{borderRadius:'10px'}}/>
          </div>
        </div>
        </a>

        <a>
          <div className="max-w-[100px] flex items-center gap-1" style={{width:'130px'}}>
          <div>
            <Skeleton className="flex rounded-full w-12 h-12"/>
          </div>  
          <div className="w-full flex flex-col gap-2">
            <Skeleton className="h-3 w-3/5 rounded-lg" style={{borderRadius:'10px'}}/>
            <Skeleton className="h-3 w-4/5 rounded-lg" style={{borderRadius:'10px'}}/>
          </div>
        </div>
        </a>

        <a>
          <div className="max-w-[100px] flex items-center gap-1" style={{width:'130px'}}>
          <div>
            <Skeleton className="flex rounded-full w-12 h-12"/>
          </div>  
          <div className="w-full flex flex-col gap-2">
            <Skeleton className="h-3 w-3/5 rounded-lg" style={{borderRadius:'10px'}}/>
            <Skeleton className="h-3 w-4/5 rounded-lg" style={{borderRadius:'10px'}}/>
          </div>
        </div>
        </a>

        <a>
          <div className="max-w-[100px] flex items-center gap-1" style={{width:'130px'}}>
          <div>
            <Skeleton className="flex rounded-full w-12 h-12"/>
          </div>  
          <div className="w-full flex flex-col gap-2">
            <Skeleton className="h-3 w-3/5 rounded-lg" style={{borderRadius:'10px'}}/>
            <Skeleton className="h-3 w-4/5 rounded-lg" style={{borderRadius:'10px'}}/>
          </div>
        </div>
        </a>

        <a>
          <div className="max-w-[100px] flex items-center gap-1" style={{width:'130px'}}>
          <div>
            <Skeleton className="flex rounded-full w-12 h-12"/>
          </div>  
          <div className="w-full flex flex-col gap-2">
            <Skeleton className="h-3 w-3/5 rounded-lg" style={{borderRadius:'10px'}}/>
            <Skeleton className="h-3 w-4/5 rounded-lg" style={{borderRadius:'10px'}}/>
          </div>
        </div>
        </a>

          </>
          
          }

        
      
          {orgInfo && orgInfo.socialFeaturesEnabled && userList.map((u) => {
                    return (
                      <a>
                        
                        <InteractiveProfile
                          loggedInUser={loggedInUser}
                          content={
                            <div>
                            {
                              u.profilePicture !== "" && u.profilePicture !== undefined && <img src={u.profilePicture} style={{width: '40px', height: '40px', position:'absolute', zIndex:1, borderRadius:'50px'}} alt=""/>
                            }
                            <UiUser
                            
                              name={u.username.length > 10 ? u.username.substring(0, 7) + ".." : u.username}
                              description={u.status}
                              avatarProps={{
                                alt: u.username,
                                showFallback: u.profilePicture === "",
                                ignoreFallback: false,
                                getInitials: (name) => {
                                  return name.split(" ")[0][0];
                                },
                              }}
                            />
                            </div>
                          }
                          friendIndicatorPosition={
                            loggedInUser && loggedInUser?.friends.includes(u._id)
                              ? "top-right"
                              : undefined
                          }
                          withStats={true}
                          contextContent={undefined}
                          popoverPosition={"right"}
                          isSelf={false}
                          user={u}
                        />
                      </a>
                    )
                  })
                }

                {orgInfo && !orgInfo.socialFeaturesEnabled && 
                  <a>
                  <p style={{color:'white', fontSize:12}}><span style={{fontSize:17}}>Social Features Disabled</span>,</p>
                  <p style={{color:'#787878', fontSize:13}}>ask your organization admin to enable them in the organization management menu</p>
                  </a>
                }
            

                <Divider style={{width:'90%', justifyContent:'center', margin:'auto'}}/>

                {<ButtonGroup size="sm" style={{justifyContent:'center', margin: 'auto', marginTop:'1rem', width:'100%', marginBottom:'1rem'}}>
                  {<Button aria-label="previous-user-list-page" isDisabled={!usersFullyLoaded || !usersLoaded || (userListPage - 1 < 0)} onClick={async () => {
                    const a = (userListPage as number) - 1;
                    setUserListPage(a);
                    await populateUserList(a);    
                  }}>Prev.</Button>}
                  {<Button aria-label="next-user-list-page" isDisabled={!usersFullyLoaded || !usersLoaded} onClick={async () => {

                    
                    
                     const a = Math.min((userListPage as number) + 1, pageCount - 1);
                     setUserListPage(a);
                     await populateUserList(a);
                    
                    }}>Next</Button>}
                </ButtonGroup>}

            <div
              className="sidebarBack"
              onMouseEnter={() => {
                setBackHover(true);
              }}
              onMouseLeave={() => {
                setBackHover(false);
              }}
              style={{ cursor: "pointer" }}
              onClick={() => {

                setHidden(true);
              }}
            >
              
              
              {backHover ? (
                <SidebarBackFilledIcon />
              ) : (
                <SidebarBackUnfilledIcon />
              )}
            </div>
          </>
        </div>
      )}

      {hidden && (
        <div
          className="sidebarBackAlt"
          onMouseEnter={() => {
            setBackHover(true);
          }}
          onMouseLeave={() => {
            setBackHover(false);
          }}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setHidden(false);
          }}
        >
          {backHover ? (
            <SidebarExpandFilledIcon />
          ) : (
            <SidebarExpandUnfilledIcon />
          )}
        </div>
      )}
    </>
  );
};

export default Sidebar;
