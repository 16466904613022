import { useEffect, useState } from 'react';
//import './App.css';
import LoginModal from './components/LoginModal';
import QuestionPageLoggedInView from './components/QuestionPageLoggedInView';
import SignUpModal from './components/SignUpModal';
import TrainingNavBar from './components/TrainingNavBar';
import { User } from './models/user';
import * as UserApi from "./network/user_api"
import QuestionPageLoggedOutView from './components/QuestionPagedLoggedOutView';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import QuestionPage from './pages/QuestionPage';
import NotFoundPage from './pages/NotFoundPage';
import DashboardPage from './pages/DashboardPage';
import QuizzesMenu from './components/QuizzesMenu';
import HomePage from './pages/HomePage';
import Leaderboardpage from './pages/LeaderboardPage';
import NewPage from './pages/NewPage';
import backgroundImg from "./bg-dark.png";

import Sidebar from './components/Sidebar';
import SubjectivesPage from './pages/SubjectivesPage';
import ReportsPage from './pages/ReportsPage';
import SuperGuides from './pages/SuperGuides';

function App() {
  // const [get, set]
  const [loggedInUser, setLoggedInUser] = useState<User|null>(null);

  const [loadingUser, setLoadingUser] = useState(true);

  const [signUpVisible, setSignUpVisibile] = useState(false);
  const [loginVisibile, setLoginVisibile] = useState(false);

  const [orgInfo, setOrgInfo] = useState<any>()

  
  useEffect(() => {
    async function fetchLoggedInUser() {
      try {
        setLoadingUser(true);
        const user = await UserApi.getLoggedInUser();
        setLoggedInUser(user);  
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingUser(false);
      }
    }

    fetchLoggedInUser();
  }, []);
  
  

  return (
    <BrowserRouter>
        <div className="App">

        {loggedInUser && !(window.location.pathname === "/") && loggedInUser.organization != "NULLL" && <Sidebar
        loggedInUser={loggedInUser}
        />}
          
          <TrainingNavBar 
          loggedInUser={loggedInUser}
          loadingUser={loadingUser}
          onLoginClicked={() => {setLoginVisibile(true)}} 
          onSignUpClicked={() => {setSignUpVisibile(true)}} 
          onLogoutSuccessful={() => {setLoggedInUser(null)}} 
          />

            <div className='flex-center'>
            <Routes>
              
              <Route 
              path="/quizzes"
              element={<QuizzesMenu loggedInUser={loggedInUser}/>}
              />

              <Route 
              path="/"
              element={<HomePage onSignupClicked={() => { setSignUpVisibile(true); } } isLoggedIn={loggedInUser !== null && loggedInUser !== undefined}/>}
              />

              <Route 
              path="/leaderboard"
              element={<Leaderboardpage loggedInUser={loggedInUser}/>}
              />

               {/*
              <Route 
              path="/question"
              element={<QuestionPage loggedInUser={loggedInUser}/>}
              />
              */}
              

              <Route 
              path="/dashboard"
              element={<DashboardPage loggedInUser={loggedInUser}/>}
              />

              <Route 
              path="/subjectives"
              element={<SubjectivesPage loggedInUser={loggedInUser}/>}
              />

              <Route 
              path="/super-guides"
              element={<SuperGuides loggedInUser={loggedInUser}/>}
              />

              <Route 
              path="/dev/reports"
              element={<ReportsPage loggedInUser={loggedInUser}/>}
              />

              <Route
              path="/*"
              element={<NotFoundPage/>}
              />

            </Routes>
            </div>
            

          { signUpVisible &&
            <SignUpModal 
          onDismiss={() => { setSignUpVisibile(false); } }
          onSignUpSuccessful={(user) => { 
            setLoggedInUser(user); 
            setSignUpVisibile(false); 
            window.location.href ="/dashboard"
          } } organizationSetup={false}            />
          }

          { loginVisibile &&
            <LoginModal 
              onDismiss={() => {setLoginVisibile(false)}}
              onLoginSuccessful={(user) => {
                setLoggedInUser(user); 
                setLoginVisibile(false);
                window.location.href ="/dashboard"
              }}
            />
          }
          
        </div>
      
      
      </BrowserRouter>
      
  );

  /*
  <BrowserRouter>
        <div className="App">
        
          <TrainingNavBar 
          loggedInUser={loggedInUser}
          onLoginClicked={() => {setLoginVisibile(true)}} 
          onSignUpClicked={() => {setSignUpVisibile(true)}} 
          onLogoutSuccessful={() => {setLoggedInUser(null)}} 
          />

          <Container>
            <Routes>
              <Route 
              path="/quizzes"
              element={<QuizzesMenu loggedInUser={loggedInUser}/>}
              />

              <Route 
              path="/"
              element={<HomePage/>}
              />

              <Route 
              path="/leaderboard"
              element={<Leaderboardpage/>}
              />

              {/*
              <Route 
              path="/question"
              element={<QuestionPage loggedInUser={loggedInUser}/>}
              />
              //}
              

              <Route 
              path="/dashboard"
              element={<DashboardPage loggedInUser={loggedInUser}/>}
              />

              <Route
              path="/*"
              element={<NotFoundPage/>}
              />

            </Routes>
          </Container>

          { signUpVisible &&
            <SignUpModal 
              onDismiss={() => {setSignUpVisibile(false)}}
              onSignUpSuccessful={(user) => {setLoggedInUser(user); setSignUpVisibile(false);}}
            />
          }

          { loginVisibile &&
            <LoginModal 
              onDismiss={() => {setLoginVisibile(false)}}
              onLoginSuccessful={(user) => {setLoggedInUser(user); setLoginVisibile(false);}}
            />
          }
        
        </div>
      </BrowserRouter>
    */
}

export default App;
