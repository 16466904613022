
import { User } from "../models/user";
import NavbarBlank from "./NavbarBlank";
import NavBarLoggedInView from "./NavBarLoggedInView";
import NavBarLoggedOutView from "./NavBarLoggedOutView";
import { Button, Link } from "@nextui-org/react";

interface NavBarProps {
    loggedInUser: User | null,
    loadingUser: boolean,
    onSignUpClicked: () => void,
    onLoginClicked: () => void,
    onLogoutSuccessful: () => void,
}

const NavBar = ({loggedInUser, loadingUser, onSignUpClicked, onLoginClicked, onLogoutSuccessful} : NavBarProps) => {
    return ( 
        <>
        {!loadingUser ? loggedInUser 
            ? <NavBarLoggedInView
                user={loggedInUser}
                onLogoutSuccessful={onLogoutSuccessful}
            
            /> : 
            <NavBarLoggedOutView
                onSignupClicked={onSignUpClicked}
                onLoginClicked={onLoginClicked}
            />
            :
            <NavbarBlank/>
        }
        
        </>

     );
}
 
export default NavBar;