import { ModalHeader, Modal, Divider, ModalFooter, ModalContent, ModalBody, Calendar, Spinner, DateValue, Slider } from "@nextui-org/react";
import { User } from "../models/user";
import { useEffect, useState } from "react";
import { getOrganizationEvents } from "../network/user_api";

interface ManageOrganizationModalProps {
    loggedInUser : User;
    org : any;
    onDismiss: () => void;
}

const ViewOrganizationModal = ({loggedInUser, org, onDismiss} : ManageOrganizationModalProps) => {
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState<DateValue>();
    const [events, setEvents] = useState<any[]>([]);
    const [selectedEventIndex, setSelectedEventIndex] = useState(0);

    async function grabOrganizationEvents() {
      try {
          setLoading(true);

          const f = await getOrganizationEvents();
          setEvents(f.events);
          
      } catch (error) {
        
      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      grabOrganizationEvents()
    }, [])

    return (
        <Modal isOpen={true} onClose={onDismiss} style={{minHeight:'10rem'}}>
            <ModalContent>
              {() => (
                !loading ?
                <>
                  <ModalHeader className="flex flex-col gap-1" style={{color:"white"}}>{org.name}
                  <p style={{color:'gray', fontSize:15}}>{org.sender}</p>
                  <p style={{color:'gray', fontSize:15}}>Created {new Date(org.dateCreated).getMonth() + "/" + new Date(org.dateCreated).getDate() + "/" + new Date(org.dateCreated).getFullYear().toString().substring(2)}</p>
                  </ModalHeader>
    
                  <Divider style={{width:'90%', margin:'auto', justifyContent:'center'}}/>
                  
                  <ModalBody className="flex flex-col gap-1 mb-2 mt-1">
                    <Calendar color="foreground" value={selectedDate} onChange={setSelectedDate}/>
                  </ModalBody>
    
                  <Divider style={{width:'90%', margin:'auto', justifyContent:'center'}}/>

                  <Slider
                  style={{width: '80%', justifyContent:'center', margin:'auto'}}
                  maxValue={events.length - 1}
                  showSteps={false}
                  color="secondary"
                  size="sm"
                  onChange={
                    (v:any) => {
                      setSelectedEventIndex(v);
                      if (selectedEventIndex < events.length) {
                        const w = new Date(events[selectedEventIndex].dateTime);
                        if (selectedDate) {
                          setSelectedDate(selectedDate.set({day: w.getDay(), month: w.getMonth(), year: w.getFullYear()}))
                        }
                      }
                      
                    }
                  }
                  />

                  {selectedEventIndex < events.length && events[selectedEventIndex] && 
                  <div style={{margin:'auto', textAlign:'left'}}>
                    <p style={{fontWeight:600, fontSize:24, color:'white'}}>{events[selectedEventIndex].eventName}</p>
                    <p style={{color:'white', fontSize:18}}><i>{new Date(events[selectedEventIndex].dateTime).toDateString()}</i> - {new Date(events[selectedEventIndex].dateTime).getHours() + ":" + (new Date(events[selectedEventIndex].dateTime).getMinutes() < 10 ? "0" : "") + new Date(events[selectedEventIndex].dateTime).getMinutes()}</p>
                    <p style={{fontSize:14, color:'white'}}>{events[selectedEventIndex].eventDescription}</p>
                  </div>}
    
                  <ModalFooter className="flex flex-col">
                  <div style={{maxHeight:'200px', overflow:'auto'}}>
                    {
                      // TODO FFS clean this shit up
                    selectedDate && events.filter((v) => {return new Date(v.dateTime).getUTCDate() === selectedDate.toDate("UTC").getUTCDate() && new Date(v.dateTime).getUTCMonth() === selectedDate.toDate("UTC").getUTCMonth() && new Date(v.dateTime).getUTCFullYear() === selectedDate.toDate("UTC").getUTCFullYear()}).map((v) => {
                      return (
                        <>
                          <p style={{color:'white', fontSize:20, fontWeight:600}}>{v.eventName} - {new Date(v.dateTime).toLocaleTimeString()}</p>
                          <p style={{color:'white', fontSize:13}}>{v.eventDescription}</p> 
                          <Divider className="mt-2 mb-2"/>
                      </>
                    )
                    })}
                    </div>
                    
                  </ModalFooter>
                </>
                :
                <>
                  <Spinner color="secondary"/>
                </>
              )}
            </ModalContent>
          </Modal>);
}

export default ViewOrganizationModal;