import SubjectivesLoggedInView from "../components/SubjectivesLoggedInView";
import QuestionPageLoggedOutView from "../components/QuestionPagedLoggedOutView";
import { User } from "../models/user";

interface QuestionPageProps {
    loggedInUser: User | null
}

const QuestionPage = (props : QuestionPageProps) => {
    return (       
        <>
        {props.loggedInUser ? 
            <div style={{marginTop:'5rem'}}>
          <SubjectivesLoggedInView 
                    user={props.loggedInUser}           ></SubjectivesLoggedInView> 
                    </div>: <QuestionPageLoggedOutView></QuestionPageLoggedOutView>
        }
          
        </> );
}
 
export default QuestionPage;