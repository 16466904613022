import {User} from "../models/user";

async function fetchData(input: RequestInfo, init?: RequestInit) {
    // console.log("Fetching Request: ", input, init);
    
    const response = await fetch(input, init);

    if (response.ok){
        return response;
    }else{
        const errorBody = await response.json();
        const errorMessage = errorBody.error;
        throw new Error(errorMessage);
    }
}

export async function getLoggedInUser(): Promise<User> {
    const response = await fetchData("/api/users", {method:"GET"});
    return response.json();
}

export interface UserStatsProps {
    username: string,
}

export async function getCompetitiveCooldown() {
    const response = await fetchData("/api/users/competitiveCooldown", 
        {
            method:"GET", 
        });
    
        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
}

export async function getUserStats(props : UserStatsProps): Promise<User> {
    
    
    const response = await fetchData("/api/users/userStats", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },

        body: JSON.stringify(props),
    });

    return await response.json();
}

export async function getTopUsers(): Promise<User[]> {
    const response = await fetchData("/api/users/top", {method:"GET"});

    return response.json();
}

export async function getSocialSettings(): Promise<User> {
    const response = await fetchData("/api/users/settings", {method:"GET"});

    return response.json();
}

interface GrabDashboardStatsBody {
    timeFrame: Number // 0 = Month, 1 = Week, 2 = Day
    category: Number // 0 = All, 1 = Art, 2 = Music, 3 = Math, 4 = Social Science, 5 = Science, 6 = Economics, 7 = Literature
    type: Number // 0 = Answered, 1 = Correct
}

export async function getDashboardInfo(props: GrabDashboardStatsBody): Promise<any> {
    const response = await fetchData("/api/users/dashboardStats", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },

        body: JSON.stringify(props),
    });

    return response.json();
}


interface RemoveFriendBody {
    removeFriendId: String
}

export async function removeFriend(props: RemoveFriendBody){
    const response = await fetchData("/api/users/removeFriend", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },

        body: JSON.stringify(props),
    });

    return response.json();
}

interface Org_IsValidPracticeTestCodeBody {
    code: String
}

export async function checkPracticeTestCodeValid(props: Org_IsValidPracticeTestCodeBody) {
    const response = await fetchData("/api/organizations/checkPracticeTestValid", 
        {
            method:"POST",
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });
    
        try {
            return response.json();
        } catch (error) {
            throw error;
        }
}

interface SendFriendRequestBody {
    recipient: String
}

export async function sendFriendRequest(props: SendFriendRequestBody) {
    const response = await fetchData("/api/users/sendFriendRequest", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },

        body: JSON.stringify(props),
    });

    return response.json();
}

interface CreateNewOrganizationBody {
    organizationName: string,
    ownerId: string
}

export async function createNewOrganization(props: CreateNewOrganizationBody) {
    const response = await fetchData("/api/organizations/create", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },

        body: JSON.stringify(props),
    });

    return response.json();
}

interface ChangeUserOrganizationBody {
    newOrganization: String
}

export async function changeUserOrganization(props: ChangeUserOrganizationBody) {
    const response = await fetchData("/api/users/changeOrganization", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },

        body: JSON.stringify(props),
    });

    return response.json();
}

export interface SetStatProps {
    username: string,
    stat: string,
    newValue: number
}

export interface SocialSettingsRequestBody {
    isLeaderboardAnonymous: boolean,
    bestCategoryPrivate: boolean,
    worstCategoryPrivate: boolean,
    totalQuestionsPrivate: boolean,
    correctnessPrivate: boolean,
    status: string,
    bio: string,
    focusedCategory: number,
    profilePicture: string,
    organization: string
}

export async function setSocialSettings(props : SocialSettingsRequestBody) {
    const response = await fetchData("/api/users/settings", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },

        body: JSON.stringify(props),
    });

    return response.json();
}

export async function getUserNotifications(){
    const response = await fetchData("/api/users/notifications", {method:"GET"});

    return response.json();
}

export async function getAllUserNotifications(){
    const response = await fetchData("/api/users/allNotifications", {method:"GET"});

    return response.json();
}


interface GetProfileBody {
    username?: String,
    userId?: String,
    self: boolean
}

export async function getProfile(props: GetProfileBody){
    const response = await fetchData("/api/users/getProfile", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },

        body: JSON.stringify(props),
    });

    if (props.self){
        const f = await response.json();
        f.worstCategoryPrivate = false;
        f.bestCategoryPrivate = false;
        f.totalQuestionsPrivate = false;
        f.correctnessPrivate = false;
        f.isLeaderboardAnonymous = false;
        return f;
    }

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface GenerateTestBody {
    competitiveMode: boolean;
    categories: String[],
    hasPageNumbers: boolean,
    questionCount: number,
    liveCorrections: boolean,
    practiceTestCode: String,
    sections: Number[],
    returnAsPdf: boolean
}


export async function generateTest(props : GenerateTestBody) {
    const response = await fetchData("/api/questions/test", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },

        body: JSON.stringify(props),
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
    
    
}

interface CheckQuestionBody {
    question: string,
    category: string,
    answer: number,
    freeAnswer: string
}


export async function checkQuestion(props : CheckQuestionBody) {    
    const response = await fetchData("/api/questions/test/check", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },

        body: JSON.stringify(props),
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

export async function submitAnswer(props : CheckQuestionBody) {
    try {
        const response = await fetchData("/api/questions/test/submit", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },

            body: JSON.stringify(props),
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error:any) {
        return;
    }
    
}

interface GetQuestionReportsBody {
    page: number
}

export async function getQuestionReports(props : GetQuestionReportsBody) {
    try {
        const response = await fetchData("/api/questions/reports", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },

            body: JSON.stringify(props),
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error:any) {
        return;
    }
    
}

interface Org_GrabPracticeTestResponses {
    code: String
}

export async function grabPracticeTestResponses(props: Org_GrabPracticeTestResponses) {
    const response = await fetchData(`/api/organizations/grabPracticeTestResponses`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props) 
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

export async function completeTest() {
    const response = await fetchData("/api/questions/test/complete", 
    {
        method:"GET", 
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

export async function grabEssayPrompts() {
    const response = await fetchData("/api/questions/essayPrompts", 
    {
        method:"GET", 
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

export async function grabInterviewQuestion() {
    const response = await fetchData("/api/questions/interviewQuestion", 
    {
        method:"GET", 
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

export async function grabImpromptuSpeechPrompt() {
    const response = await fetchData("/api/questions/impromptuTopics", 
    {
        method:"GET", 
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface NotificationReadBody {
    notificationId: String,
    response?: boolean
}

export async function markNotificationRead(props: NotificationReadBody) {
    const response = await fetchData(`/api/users/markNotificationRead`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props) 
    });

    return response;
}

interface GetInfoBody {
    orgCode: String
}

export async function getOrganizationInfo(props: GetInfoBody){
    
    const response = await fetchData("/api/organizations/info", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface CreateOrgEventRequestBody {
    dateTime: Date,
    eventName: String,
    eventDescription: String,
    users: String[]
}

export async function orgManagement_createOrgEvent(props: CreateOrgEventRequestBody){
    try {
        const response = await fetchData("/api/organizations/createEvent", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
    
}

interface GetLeaderboardBody {
    page: Number
}

export async function getLeaderboard(props: GetLeaderboardBody){    
    const response = await fetchData("/api/organizations/getLeaderboard", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

export async function getLeaderboardManifest(){    
    const response = await fetchData("/api/organizations/leaderboardManifest", 
    {
        method:"GET", 
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

export async function grabTestHistoryManifest() {
    try {
        const response = await fetchData("/api/users/testRecordHistoryManifest", 
        {
            method:"GET",
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

interface GetTestRecordHistoryBody {
    page: number
}

export async function grabTestHistory(props: GetTestRecordHistoryBody) {
    try {
        const response = await fetchData("/api/users/testHistory", 
        {
            method:"POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

interface GetPublicUsersManifest {
    orgCode: String,
    page: Number
}

export async function grabPublicUsersManifest(props: GetPublicUsersManifest){
    
    const response = await fetchData("/api/users/publicUsersManifestOf", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface CreatePracticeTestBody {
    competitiveMode: boolean;
    categories: String[],
    hasPageNumbers: boolean,
    questionCount: number,
    liveCorrections: boolean,
    oneResponsePerUser: boolean,
    sections: Number[],
    reviewable: boolean
}

export async function createPracticeTest(props: CreatePracticeTestBody){
    
    const response = await fetchData("/api/organizations/createPracticeTest", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface Org_AssignGpaCategory {
    editUserId: String,
    gpaCategory: String
}

export async function assignGpaCategory(props: Org_AssignGpaCategory){
    
    const response = await fetchData("/api/organizations/assignGpaCategory", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface ReportQuestionBody {
    question: String,
    pageNumberStart: Number,
    pageNumberEnd: Number,
    section: Number,
    answerChoiceA: String,
    answerChoiceB: String,
    reason: Number,
    explanation: String
}

export async function reportQuestion(props: ReportQuestionBody){
    
    const response = await fetchData("/api/questions/reportQuestion", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface UpdateQuestionAndReportBody {
    reportId: String,
    questionId: String,
    question: String,
    answerChoiceA: String,
    answerChoiceB: String,
    answerChoiceC: String,
    answerChoiceD: String,
    answerChoiceE: String,
    justification: String,
    pageNumberStart: Number,
    pageNumberEnd: Number,
    section: Number
}

export async function updateQuestionAndReport(props: UpdateQuestionAndReportBody){
    
    const response = await fetchData("/api/questions/updateAndDeleteReport", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface ThrowOutReportBody {
    reportId: String,
}

export async function throwOutReport(props: ThrowOutReportBody){
    
    const response = await fetchData("/api/questions/throwOutReport", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}


interface RegeneratePracticeTestQuestionBody {
    code: String,
    questionIndex: number
}


export async function regenerateQuestionAtIndex(props: RegeneratePracticeTestQuestionBody){
    
    const response = await fetchData("/api/organizations/editPracticeTestQuestion", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface DeletePracticeTestBody {
    code: String
}

export async function deletePracticeTest(props: DeletePracticeTestBody){
    
    const response = await fetchData("/api/organizations/deletePracticeTest", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

export async function getPracticeTests(){
    const response = await fetchData("/api/organizations/getPracticeTests", 
    {
        method:"GET"
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}


interface GetPublicUsers {
    orgCode: String,
    clientOfOriginUsername: String,
    page: Number
}

export async function grabPublicUsersInOrganization(props: GetPublicUsers){
    const response = await fetchData("/api/users/publicUsersOf", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface Org_SendPrivateMessageBody {
    recipientIds: String[],
    message: String
}

export async function orgManagement_sendPrivateMessage(props: Org_SendPrivateMessageBody) {
    const response = await fetchData("/api/organizations/sendPrivateGroupMessage", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface Org_AddAdminBody {
    newAdminUserId: String
}

export async function orgManagement_addAdmin(props: Org_AddAdminBody) {
    const response = await fetchData("/api/organizations/addAdmin", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface Org_RemoveAdminBody {
    newAdminUserId: String
}

export async function orgManagement_removeAdmin(props: Org_RemoveAdminBody) {
    const response = await fetchData("/api/organizations/removeAdmin", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    return response.json();
}

interface Org_SetSettingsBody {
    socialSettingsEnabled: Boolean,
    leaderboardEnabled: Boolean,
    requestToJoinEnabled: Boolean,
    leaderboardRankHidden: Boolean
}

export async function orgManagement_setSettings(props: Org_SetSettingsBody) {
    const response = await fetchData("/api/organizations/setSettings", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface GetStatsForUsers {
    users: any[],
    timeFrame: Number, // 0 = Month, 1 = Week, 2 = Day
    category: Number, // 0 = All, 1 = Art, 2 = Music, 3 = Math, 4 = Social Science, 5 = Science, 6 = Economics, 7 = Literature
    type: Number // 0 = Answered, 1 = Correct, 2 = Average
}

export async function orgManagement_getStats(props: GetStatsForUsers) {
    const response = await fetchData("/api/organizations/getStats", 
    {
        method:"POST", 
        headers : {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(props)
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

interface Org_KickUserBody {
    kickUserId: String,
    notify: boolean
}

export async function orgManagement_kickUser(props: Org_KickUserBody) {
    try {
        const response = await fetchData("/api/organizations/kickUser", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
    
}

interface Org_BanUserBody {
    banUserId: String,
    notify: boolean
}

export async function orgManagement_banUser(props: Org_BanUserBody) {
    try {
        const response = await fetchData("/api/organizations/banUser", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function orgManagement_unbanUser(props: Org_GeneralActionBody) {
    try {
        const response = await fetchData("/api/organizations/unbanUser", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function orgManagement_acceptJoinRequest(props: Org_GeneralActionBody) {
    try {
        const response = await fetchData("/api/organizations/acceptJoinRequest", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function orgManagement_dismissJoinRequest(props: Org_GeneralActionBody) {
    try {
        const response = await fetchData("/api/organizations/dismissJoinRequest", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

interface Org_GeneralActionBody {
    targetUserId: String,
    notify: boolean
}

export async function orgManagement_removeProfilePicture(props: Org_GeneralActionBody) {
    try {
        const response = await fetchData("/api/organizations/removeProfilePicture", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function orgManagement_removeStatus(props: Org_GeneralActionBody) {
    try {
        const response = await fetchData("/api/organizations/removeStatus", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function orgManagement_removeBio(props: Org_GeneralActionBody) {
    try {
        const response = await fetchData("/api/organizations/removeBio", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function orgManagement_getBannedUsers() {
    try {
        const response = await fetchData("/api/organizations/getBannedUsers", 
        {
            method:"GET", 
        });

        return response.json();
        
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function orgManagement_getJoinRequests() {
    const response = await fetchData("/api/organizations/joinRequests", 
    {
        method:"GET", 
    });

    try {
        return response.json();
    } catch (error) {
        return response.text();
    }
}

export async function getOrganizationEvents() {
    try {
        const response = await fetchData("/api/organizations/organizationEvents", 
        {
            method:"GET"
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

interface UpdateOrgEventRequestBody {
    eventId: String,
    dateTime: Date,
    eventName: String,
    eventDescription: String,
    users: String[]
}

export async function orgManagement_updateOrgEvent(props: UpdateOrgEventRequestBody){
    try {
        const response = await fetchData("/api/organizations/updateEvent", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

interface GetOrgTestRecordHistoryBody {
    page: number,
    userId: string
}

export async function orgManagement_getUserTestRecordHistory(props: GetOrgTestRecordHistoryBody){
    try {
        const response = await fetchData("/api/organizations/getUserTestRecordHistory", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

interface DeleteOrgEventRequestBody {
    eventId: String,
}

export async function orgManagement_deleteOrgEvent(props: DeleteOrgEventRequestBody){
    try {
        const response = await fetchData("/api/organizations/deleteOrgEvent", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export interface VerifyAccountBody {
    email: string,
    username?: string,
    verificationCode: string,
}

export async function verifyAccount(props: VerifyAccountBody) {
    try {
        const response = await fetchData("/api/users/verifyAccount", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export interface ResendVerificationEmailBody {
    email: string,
    username?: string
}

export async function resendVerificationEmail(props: ResendVerificationEmailBody) {
    try {
        const response = await fetchData("/api/users/resendVerificationEmail", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

interface ChangePasswordBody {
    newPassword: string,
}

export async function changePassword(props: ChangePasswordBody) {
    try {
        const response = await fetchData("/api/users/changePassword", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

interface ForgotPasswordBody {
    email: string,
    username: string
}

export async function forgotPassword(props: ForgotPasswordBody) {
    try {
        const response = await fetchData("/api/users/forgotPassword", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props)
        });

        try {
            return response.json();
        } catch (error) {
            return response.text();
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export interface SignUpCredentials {
    username: string,
    email: string,
    password: string,
    organization: string,
    newOrganizationName?: string
}

export async function signup(credentials : SignUpCredentials): Promise<User> {
    try {
        const response = await fetchData("/api/users/signup", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },

            body: JSON.stringify(credentials),
        });

        return response.json();
    } catch (error:any) {
        throw new Error(error.message);
    }
}

export interface LogInCredentials {
    username: string,
    password: string
}

export async function login(credentials : LogInCredentials): Promise<User> {
    try {
        const response = await fetchData("/api/users/login", 
        {
            method:"POST", 
            headers : {
                "Content-Type": "application/json"
            },

            body: JSON.stringify(credentials),
        });

        return await response.json();
    } catch (error:any) {
        throw new Error(error.message);
    }
    
}

export async function logout() {
    await fetchData("/api/users/logout", {method:"POST"});
}