import { Divider, Modal, ModalBody, ModalContent, ModalHeader, Button, ModalFooter } from "@nextui-org/react"
import { useState } from "react";
import MailIcon from "./Icons/MailIcon";

interface NotificationProps {
    notification: any,
    onDismiss: () => void;
    onMarkRead: () => void;
}

const NotificationModal = ({notification, onDismiss, onMarkRead} : NotificationProps) => {
    return (
    <Modal isOpen={true} onClose={onDismiss}>
        <ModalContent>
          {() => (
            <>
              <ModalHeader className="flex flex-col gap-1" style={{color:"white"}}>{notification.title}
              <p style={{color:'gray', fontSize:15}}>{notification.sender}</p>
              <p style={{color:'gray', fontSize:15}}>Sent on {new Date(notification.dateCreated).getMonth() + "/" + new Date(notification.dateCreated).getDate() + "/" + new Date(notification.dateCreated).getFullYear().toString().substring(2)}</p>
              </ModalHeader>

              <Divider style={{width:'90%', margin:'auto', justifyContent:'center'}}/>
              
              <ModalBody className="flex flex-col gap-1 mb-2 mt-1">
                <p style={{color:'white'}}>{notification.message}</p>
              </ModalBody>

              <Divider style={{width:'90%', margin:'auto', justifyContent:'center'}}/>

              <ModalFooter className="flex flex-col">
                <Button onClick={onMarkRead} startContent={<MailIcon/>}>
                    Mark as read
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>);
}

export default NotificationModal;