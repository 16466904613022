import LeaderboardLoggedInView from "../components/LeaderboardLoggedInView";
import QuestionPageLoggedOutView from "../components/QuestionPagedLoggedOutView";
import { User } from "../models/user";

interface leaderboardProps {
    loggedInUser: User | null
}

const Leaderboardpage = ({loggedInUser}: leaderboardProps) => {
    return (
        <>
        {loggedInUser ? 
            <div style={{marginTop:'7rem'}}>
                <LeaderboardLoggedInView loggedInUser={loggedInUser}/>    
            </div>
            
            :
            <QuestionPageLoggedOutView/>
            }
        </>
    );
}
 
export default Leaderboardpage;