import { Avatar, Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, Radio, RadioGroup, Spinner, Tab, Tabs } from "@nextui-org/react";
import { User } from "../models/user";
import * as UserApi from "../network/user_api"
import { HugoLogo } from "./HugoLogo";


const NavBarLoggedOutView = () => {
    return ( 
        <>
            <Navbar style={{backgroundColor:"#101010", marginBottom:"1rem"}}>
                    <NavbarBrand>
                    <HugoLogo />
                    </NavbarBrand>
                    <NavbarContent className="sm:flex gap-4" justify="center" style={{marginLeft:"4.5rem"}}>
                        <NavbarItem>
                        
                        </NavbarItem>
                    </NavbarContent>
                    <NavbarContent justify="end">
                        <NavbarItem className="lg:flex">
                            <Spinner/>
                        </NavbarItem>
                    </NavbarContent>
                </Navbar>

        </>

     );
}
 
export default NavBarLoggedOutView;